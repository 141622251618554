/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { AccessType } from '@shared/model/access-type.model';
import { CurrentUser } from '@shared/model/current-user.model';
import { AuthService } from '@simpology/authentication';
import { permissionId } from 'src/app/shared/access/permission-ids';
import { Permission } from '../access/permission';
import { PostLoginService } from './post-login.service';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  constructor(private authService: AuthService, private postLoginService: PostLoginService) {}
  private Read = 1;
  private Write = 2;
  private Yes = 4;

  public getUserId(): number {
    return this.authService.id;
  }

  public getCurrentUser(): CurrentUser {
    return this.postLoginService.getCurrentUser();
  }

  public canReadAll(permissionId: number): boolean {
    const perm = this.findPermission(permissionId);
    return perm != null && (perm.all & (this.Read | this.Write)) !== 0;
  }

  public canRead(permissionId: number): boolean {
    const perm = this.findPermission(permissionId);
    return (
      perm != null &&
      ((perm.all & (this.Read | this.Write)) !== 0 ||
        (perm.team & (this.Read | this.Write)) !== 0 ||
        (perm.own & (this.Read | this.Write)) !== 0)
    );
  }

  public canWriteAll(permissionId: number): boolean {
    const perm = this.findPermission(permissionId);
    return perm != null && (perm.all & this.Write) !== 0;
  }

  public canWrite(permissionId: number): boolean {
    const perm = this.findPermission(permissionId);
    return (
      perm != null && ((perm.all & this.Write) !== 0 || (perm.team & this.Write) !== 0 || (perm.own & this.Write) !== 0)
    );
  }

  public canDoAny(permissionIds: number[]): boolean {
    let canAccess = false;
    permissionIds.forEach((permissionId) => {
      const perm = this.findPermission(permissionId);
      canAccess = canAccess || (perm != null && (perm.all & this.Yes) !== 0);
    });

    return canAccess;
  }

  public canAccessMenu(permissionIds: number[]): boolean {
    let canAccess = false;
    permissionIds.forEach((permissionId) => {
      const perm = this.findPermission(permissionId);
      canAccess = canAccess || this.canRead(permissionId) || this.canDoAny([permissionId]);
    });

    return canAccess;
  }

  public canReadTiered(permissionId: number, targetPersonIds: number[], targetEntityIds: number[]): boolean {
    return this.canAccessTiered(this.Read, permissionId, targetPersonIds, targetEntityIds);
  }

  public canWriteTiered(permissionId: number, targetPersonIds: number[], targetEntityIds: number[]): boolean {
    return this.canAccessTiered(this.Write, permissionId, targetPersonIds, targetEntityIds);
  }

  public canDoTiered(permissionId: number, targetPersonIds: number[], targetEntityIds: number[]): boolean {
    return this.canAccessTiered(this.Yes, permissionId, targetPersonIds, targetEntityIds);
  }

  public canReadOwnDataOnly(permissionId: number): boolean {
    return this.getAccessType(this.Read, permissionId) === AccessType.Own;
  }

  public canWriteOwnDataOnly(permissionId: number): boolean {
    return this.getAccessType(this.Write, permissionId) === AccessType.Own;
  }

  public canUseOwnDataOnly(permissionId: number): boolean {
    return this.getAccessType(this.Yes, permissionId) === AccessType.Own;
  }

  public canAccess(permissionId: number): boolean {
    return this.getAccessType(this.Yes, permissionId) !== AccessType.None;
  }

  public getUserReadAccessType(permissionId: number): AccessType {
    return this.getAccessType(this.Read, permissionId);
  }

  private canAccessTiered(
    access: number,
    permissionId: number,
    targetPersonIds: number[],
    targetEntityIds: number[]
  ): boolean {
    const accessType = this.getAccessType(access, permissionId);

    if (accessType === AccessType.All) {
      return true;
    }
    const currentUser = this.postLoginService.getCurrentUser();

    if (
      accessType === AccessType.Team &&
      this.crossCheckEntityId(targetEntityIds, currentUser, permissionId)
    ) {
      return true;
    }
    if (
      accessType === AccessType.Own &&
      this.crossCheckEntityId(targetEntityIds, currentUser, permissionId) &&
      (targetPersonIds.length === 0 || targetPersonIds.includes(currentUser.peopleId))
    ) {
      return true;
    }

    return false;
  }

  private crossCheckEntityId(targetEntityIds: number[], currentUser: CurrentUser, permissionId: number) {
    return targetEntityIds.length === 0 ||
      targetEntityIds.includes(currentUser.entityId) ||
      this.crossCheckEntityAccessibility(targetEntityIds, currentUser.accessibleEntityIds, permissionId);
  }

  private crossCheckEntityAccessibility(entityIds: number[], accessibleEntityIds: number[], permission: number): boolean {
    if (permission !== permissionId.ApplicationManagement) return false;
    return entityIds.some((id) => accessibleEntityIds?.includes(id));
  }

  private getAccessType(access: number, permissionId: number): AccessType {
    const perm = this.findPermission(permissionId);
    if (!perm) {
      return AccessType.None;
    }
    if (this.hasAllAccess(perm, access)) {
      return AccessType.All;
    }
    if (this.hasTeamAccess(perm, access)) {
      return AccessType.Team;
    }
    if (this.hasOwnAccess(perm, access)) {
      return AccessType.Own;
    }
    return AccessType.None;
  }

  private hasAllAccess(permission: Permission, access: number): boolean {
    return this.hasSomeAccess(permission.all, access);
  }

  private hasTeamAccess(permission: Permission, access: number): boolean {
    return this.hasSomeAccess(permission.team, access);
  }

  private hasOwnAccess(permission: Permission, access: number): boolean {
    return this.hasSomeAccess(permission.own, access);
  }

  private hasSomeAccess(granted: number, access: number): boolean {
    const accessToCheck = granted === this.Write ? this.Write | this.Read : granted;
    return (access & accessToCheck) !== 0;
  }

  private findPermission(permissionId: number): Permission {
    return this.authService.permissions.find((perm: Permission) => perm.id === permissionId);
  }
}
