import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PersonType } from 'src/app/shared/model/client-enum.model';
import { PagedResult } from 'src/app/shared/model/page-filter.model';
import { People } from './people.model';
import { PeopleState, PeopleStore } from './people.store';

@Injectable({ providedIn: 'root' })
export class PeopleQuery extends QueryEntity<PeopleState, People> {
  constructor(protected store: PeopleStore) {
    super(store);
  }

  public listByType(typeEnum: PersonType): People[] {
    if (Object.values(PersonType).includes(typeEnum)) {
      return this.getAll().filter((p) => p.typeEnum === typeEnum);
    }
    return this.getAll();
  }

  public listAccredited(): People[] {
    return this.getAll().filter((p) => p.isAccredited === true);
  }

  public listPeopleByEntity(entityId: number, pageIndex: number, pageSize: number): PagedResult<People> {
    const filteredPeople = this.getAll({
      filterBy: (people: People): boolean => people.entityId === entityId,
    });
    const pagedResult: PagedResult<People> = {
      count: filteredPeople.length,
      records: filteredPeople.slice((pageIndex - 1) * pageSize, pageIndex * pageSize),
    };
    return pagedResult;
  }

  public listStaffsByEntity(
    entityId: number,
    pageIndex: number,
    pageSize: number,
    checkInMultiEntities = false
  ): PagedResult<People> {
    let filterExpression = (people: People): boolean =>
      people.entityId === entityId && people.typeEnum !== PersonType.Consumer;

    if (checkInMultiEntities) {
      filterExpression = (people: People): boolean =>
        (people.entityId === entityId || (people.entityIds || []).some((id) => id === entityId)) &&
        people.typeEnum !== PersonType.Consumer;
    }

    const filteredPeople = this.getAll({ filterBy: filterExpression });
    const pagedResult: PagedResult<People> = {
      count: filteredPeople.length,
      records: filteredPeople.slice((pageIndex - 1) * pageSize, pageIndex * pageSize),
    };
    return pagedResult;
  }

  public getByUser(userId: number): People {
    return this.getAll({
      limitTo: 1,
      filterBy: (people: People): boolean => people.userId === userId,
    })[0];
  }
}
