import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@simpology/authentication';
import { tap, map, switchMap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    return this.authService.getAuthorizationHeaderValue().pipe(
      switchMap((token) => {
        if (token && !request.headers.get('Authorization')) {
          request = request.clone({
            setHeaders: {
              Authorization: `${token}`,
            },
          });
        }
        return next.handle(request);
      })
    );
  }
}
