import { Injectable } from '@angular/core';
import {
  StoreConfig,
  EntityState,
  ActiveState,
  EntityStore,
} from '@datorama/akita';
import { OrganisationEntity } from './organisation-entity.model';

export interface OrganisationEntityState
  extends EntityState<OrganisationEntity>,
    ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organisationEntity', resettable: true })
export class OrganisationEntityStore extends EntityStore<
  OrganisationEntityState,
  OrganisationEntity
> {
  constructor() {
    super({});
  }
}
