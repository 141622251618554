import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';

import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ChannelFinalisation } from '../model/channel-finalisation.model';
import { ChannelRegistration, PersonChannel } from '../model/channel-registration.model';

@Injectable({ providedIn: 'root' })
export class ChannelApiService extends BaseApiService<ChannelRegistration> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('Channel');
  }

  public getCurrentPersonChannel(): Observable<PersonChannel> {
    return this.getCustom('CurrentPersonChannel');
  }

  public register(data: ChannelRegistration): Observable<any> {
    return this.post('', data);
  }

  public finalise(data: ChannelFinalisation): Observable<any> {
    return this.postCustom('Finalise', data);
  }

  public checkRegistrationCode(channelId: string, code: string, key: string): Observable<boolean> {
    return this.getCustom(`CheckRegistrationCode?channelId=${channelId}&code=${code}&key=${key}`);
  }
}
