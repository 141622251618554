<app-header></app-header>
<div
  class="sticky-top horizontal-menu menu-collapsed d-none d-md-block"
  [ngClass]="{ 'menu-collapsed': collapseHorizontalMenu }"
>
  <app-horizontal-navigation
    [menuItems]="menuItems"
  ></app-horizontal-navigation>
</div>
<div
  class="d-block d-md-none vertical-overlay-menu"
  [ngClass]="{
    'menu-open sidenav-overlay': showVerticalMenu,
    'menu-hide': !showVerticalMenu
  }"
>
  <app-vertical-navigation [menuItems]="menuItems"></app-vertical-navigation>
</div>
