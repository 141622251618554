import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';

import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { PeopleEmailConfirmation } from 'src/app/organisation/model/confirm-email-change.model';
import { PeopleChangeEmail } from 'src/app/organisation/model/email-change.model';
import { PeopleFinalisation } from 'src/app/organisation/model/people-finalisation.model';
import { People, PersonShort } from 'src/app/organisation/store/people/people.model';
import { UploadFileDetail } from '../../model/upload-file-detail.model';

@Injectable({ providedIn: 'root' })
export class PeopleApiService extends BaseApiService<People> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('Person');
  }

  public save(people: People, avatar: UploadFileDetail | null): Observable<People> {
    if (people.id > 0) {
      return this.updatePeople(people, avatar);
    } else {
      return this.createPeople(people, avatar);
    }
  }

  public finalise(data: PeopleFinalisation): Observable<any> {
    return this.postCustom('Finalise', data);
  }

  public getPersonByRegistrationKey(key: string): Observable<People> {
    return this.getCustom(`GetPersonByRegistrationKey?key=${key}`);
  }

  public changeEmail(data: PeopleChangeEmail): Observable<any> {
    return this.postCustom('changeEmail', data);
  }

  public confirmEmailChange(data: PeopleEmailConfirmation): Observable<any> {
    return this.postCustom('ConfirmEmailChange', data);
  }

  public cancelEmailChange(data: PeopleChangeEmail): Observable<any> {
    return this.postCustom('cancelEmailChange', data);
  }

  public updateFavourite(id: number, isFavourite: boolean): Observable<void> {
    const data = { id, isFavourite };
    return this.patch('UpdateFavourite', data);
  }

  public refreshApplicants(): Observable<People[]> {
    return this.getAllCustom('Applicants');
  }

  public getPersonListShort(): Observable<PersonShort[]> {
    return this.getAllCustom('names');
  }

  private createPeople(people: People, avatar: UploadFileDetail | null): Observable<People> {
    if (avatar) {
      const formData: FormData = this.getFormData(avatar, people);
      return this.post('CreateAndRegisterWithAvatar', formData as any);
    } else {
      return this.post('CreateAndRegister', people);
    }
  }

  private updatePeople(people: People, avatar: UploadFileDetail | null): Observable<People> {
    if (avatar) {
      const formData: FormData = this.getFormData(avatar, people);
      return this.put('SaveWithAvatar', formData as any);
    } else {
      return this.put('', people);
    }
  }

  private getFormData(imageDetail: UploadFileDetail, people: People): FormData {
    const formData: FormData = new FormData();
    formData.append('avatarFormFile', imageDetail.uploadedFile, imageDetail.uploadedFile.name);

    formData.append('jsonModelData', JSON.stringify(people));
    return formData;
  }
}
