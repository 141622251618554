import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultUrl } from '@shared/model/default-url.model';
import { LandingPageUrl } from '@shared/model/landing-page-url.model';
import { PersonTypeHomePage } from '@shared/model/person-type-home-page.model';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';

@Injectable({ providedIn: 'root' })
export class PersonTypeHomePageApiService extends BaseApiService<PersonTypeHomePage> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('PersonTypeHomePage');
  }

  public getLandingPageList(): Observable<LandingPageUrl[]> {
    return this.getCustom('PageList');
  }

  public getPersonTypeHomePageList(): Observable<PersonTypeHomePage[]> {
    return this.getAll();
  }

  public savePersonTypeHomePageList(homePageList: PersonTypeHomePage[]): Observable<void> {
    return this.postList('', homePageList);
  }

  public deletePersonTypeHomePageEntry(personEnum: number): Observable<void> {
    return this.delete(`${personEnum}`);
  }

  public getDefaultLandingPage(): Observable<DefaultUrl> {
    return this.getCustom('GetDefaultUrl');
  }
}
