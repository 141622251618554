import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProfileApiService } from '../../api/profile-api.service';
import { AccountProfile } from './account-profile.model';
import { AccountProfileQuery } from './account-profile.query';
import { AccountProfileStore } from './account-profile.store';

@Injectable({ providedIn: 'root' })
export class AccountProfileService {
  constructor(
    private store: AccountProfileStore,
    private query: AccountProfileQuery,
    private profileApiService: ProfileApiService
  ) {}

  public refresh(): Observable<AccountProfile> {
    return this.profileApiService.getProfile().pipe(tap((profile) => this.store.update({ ...profile })));
  }

  public getFromCache(): Observable<AccountProfile> {
    const cachedData = this.query.getValue();
    if (!cachedData || !cachedData.id) {
      return this.refresh();
    } else {
      return of(cachedData);
    }
  }

  public update(profile: AccountProfile): Observable<number> {
    return this.profileApiService.updateProfile(profile).pipe(
      tap(() => {
        this.store.update(profile);
      })
    );
  }
}
