import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as zxcvbn from '../../../assets/scripts/zxcvbn.js';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
  @Input() public password: string;
  @Output() public passwordScoreChanged = new EventEmitter<number>();
  public percentage = 0;
  public strengthText = '';
  public type = '';
  constructor() {}

  public ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.strengthText = '';
    this.type = '';
    this.percentage = 0;
    this.calculateScore();
  }

  private calculateScore(): void {
    if (this.password.length > 0) {
      const result = zxcvbn(this.password);
      this.percentage = ((+result.score + 1) / 5) * 100;
      switch (result.score) {
        case 0:
          this.strengthText = 'Awful';
          this.type = 'dark';
          break;
        case 1:
          this.strengthText = 'Weak';
          this.type = 'danger';
          break;
        case 2:
          this.strengthText = 'Moderate';
          this.type = 'warning';
          break;
        case 3:
          this.strengthText = 'Strong';
          this.type = 'info';
          break;
        case 4:
          this.strengthText = 'Perfect';
          this.type = 'success';
          break;
        default:
          this.strengthText = '';
          this.type = '';
          break;
      }
      this.passwordScoreChanged.emit(result.score);
    }
  }
}
