import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  private UserIdKey = 'simp:userId';
  private RedirectUrlKey = 'simp:redirectUrl';
  private ChannelIdKey = 'simp:channelId';

  public resetLogInUser(): void {
    sessionStorage.removeItem(this.ChannelIdKey);
    sessionStorage.removeItem(this.UserIdKey);
    sessionStorage.removeItem(this.RedirectUrlKey);
  }

  public setLogInUser(channelId: number, userId: number, redirectUrl: string): void {
    sessionStorage.setItem(this.ChannelIdKey, channelId.toString());
    sessionStorage.setItem(this.UserIdKey, userId.toString());
    sessionStorage.setItem(this.RedirectUrlKey, redirectUrl);
  }

  public checkUserValid(channelId: number, userId: number): boolean {
    const currentChannelId = sessionStorage.getItem(this.ChannelIdKey);
    const currentUserId = sessionStorage.getItem(this.UserIdKey);
    if (!currentUserId) {
      return true;
    }

    if (+currentUserId !== userId || +currentChannelId !== channelId) {
      return false;
    }

    return true;
  }

  public getRedirectUrl(): string {
    return sessionStorage.getItem(this.RedirectUrlKey);
  }
}
