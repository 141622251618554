import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomValidatorFn } from 'src/app/helper/custom.validator';
import { ChannelApiService } from 'src/app/organisation/api/channel-api.service';
import { ChannelRegistration } from 'src/app/organisation/model/channel-registration.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public registerForm: UntypedFormGroup;
  public loading = false;
  public submitted = false;
  public errorMessage = '';
  public successMessage = '';
  public registered = false;

  private score = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private channelApiService: ChannelApiService,
    private toastr: ToastrService
  ) {}

  public ngOnInit(): void {
    this.registerForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        companyName: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
        terms: [false, Validators.requiredTrue],
        passwordStrength: [0, [Validators.min(2)]],
      },
      {
        validator: [CustomValidatorFn.MustMatch('password', 'confirmPassword')],
      }
    );
  }

  get f(): any {
    return this.registerForm.controls;
  }

  public onScoreChanged(score: number): void {
    this.score = score;
  }

  public onRegister(): void {
    this.registerForm.controls.passwordStrength.setValue(this.score);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    const channelRegistration: ChannelRegistration = {
      companyName: this.f.companyName.value,
      email: this.f.email.value,
      primaryContactFirstName: this.f.firstName.value,
      primaryContactLastName: this.f.lastName.value,
      password: this.f.password.value,
    };

    this.channelApiService.register(channelRegistration).subscribe(
      (_) => {
        this.registered = true;
      },
      (error) =>
        this.toastr.error(`Cannot register ${error} `, 'Error', {
          enableHtml: true,
        })
    );
  }
}
