<app-navigation *ngIf="authenticated()"></app-navigation>

<ng-template #versionContent>
  <table>
    <tr>
      <td>Version</td>
      <td>master.0.0.313</td>
    </tr>
    <tr>
      <td>Server</td>
      <td>{{ serverVersion }}</td>
    </tr>
    <tr>
      <td>Lib</td>
      <td>{{ libVersion }}</td>
    </tr>
  </table>
</ng-template>

<footer id="footer" class="footer fixed-bottom footer-dark navbar-border navbar-shadow">
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
    <span class="float-md-left d-block d-md-inline-block copyright"
      >Powered by
      <a class="grey darken-2" href="https://www.simpology.com.au" target="_blank"
        ><b><span class="simpology"> Simpology Pty Ltd</span></b></a
      >
      &copy; <span [ngbTooltip]="versionContent" placement="top">{{copyright}}</span></span
    ><span class="float-md-right d-none d-lg-block"> </span>
  </p>
</footer>

