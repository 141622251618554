<div
  class="main-menu menu-fixed menu-dark menu-accordion menu-shadow"
  data-scroll-to-active="true"
>
  <div
    id="main-menu-content"
    class="main-menu-content ps-container ps-theme-light"
    fxFlex="auto"
  >
    <ul
      class="navigation navigation-main"
      id="main-menu-navigation"
      data-menu="menu-navigation"
    >
      <!-- Menu -->
      <li
        *ngFor="let child of menuItems"
        class=""
        [ngClass]="{
          'has-sub': child.submenuItems,
          open: child.isOpen && child.submenuItems,
          'nav-item': child.title,
          'navigation-header': child.section,
          active: child.isSelected && !child.submenuItems,
          'menu-collapsed-open': child.isSelected && child.submenuItems
        }"
      >
        <!-- Section -->
        <span class="menu-title" *ngIf="child.section">{{
          child.section
        }}</span>
        <i
          class="mr-2"
          *ngIf="child.section"
          [ngClass]="child.icon"
          data-toggle="tooltip"
          data-placement="right"
          data-original-title="Support"
        ></i>

        <!-- Root Menu -->
        <a
          *ngIf="child.title && !child.submenuItems && !child.isExternalLink"
          routerLink="{{ child.page !== '' ? child.page : url }}"
          (click)="toggleMenu($event, child, false)"
        >
          <i class="mr-2" [ngClass]="child.icon"></i>
          <span class="menu-title" data-i18n="">{{ child.title }}</span>
          <!--No badges for now-->
          <!-- <span
            *ngIf="child.badge"
            class="badge badge-pill float-right"
            [ngClass]="{
              'badge-info mr-2': child.badge.type == 'badge-info',
              'badge-danger': child.badge.type == 'badge-danger'
            }"
          >
            {{ child.badge.value }}
          </span> -->
        </a>
        <a
          *ngIf="child.title && !child.submenuItems && child.isExternalLink"
          [href]="child.page"
          target="_blank"
          (click)="toggleMenu($event, child, false)"
        >
          <i class="mr-2" [ngClass]="child.icon"></i>
          <span class="menu-title" data-i18n="">{{ child.title }}</span>
          <!--Removed badge for now-->
        </a>

        <!-- with Submenu -->
        <a
          *ngIf="child.title && child.submenuItems"
          routerLink="{{ child.page !== '' ? child.page : url }}"
          (click)="toggleMenu($event, child, false)"
        >
          <i class="mr-2" [ngClass]="child.icon"></i>
          <span class="menu-title" data-i18n="">{{ child.title }}</span>
          <!--Removed badge for now-->
        </a>
        <ul
          *ngIf="child.submenuItems"
          class="menu-content"
          [@popOverState]="child.isOpen"
        >
          <!-- SubmenuItems -->
          <li
            *ngFor="let subchild of child.submenuItems"
            class="isShown"
            [ngClass]="{
              'has-sub': subchild.submenuItems,
              active: subchild.isSelected && !subchild.submenuItems,
              open: subchild.isOpen && subchild.submenuItems
            }"
          >
            <a
              class="menu-item"
              *ngIf="!subchild.submenuItems && !subchild.isExternalLink"
              (click)="toggleMenu($event, subchild, true)"
              routerLink="{{ subchild.page !== '' ? subchild.page : url }}"
              >{{ subchild.title }}</a
            >
            <a
              class="menu-item"
              *ngIf="subchild.submenuItems && !subchild.isExternalLink"
              (click)="toggleMenu($event, subchild, true)"
              routerLink="{{ subchild.page !== '' ? subchild.page : url }}"
              >{{ subchild.title }}</a
            >
            <a
              *ngIf="subchild.isExternalLink"
              [href]="subchild.page"
              target="_blank"
              (click)="toggleMenu($event, child, true)"
            >
              {{ subchild.title }}
            </a>
            <ul *ngIf="subchild.submenuItems" class="menu-content">
              <li
                *ngFor="let subchild1 of subchild.submenuItems"
                [ngClass]="{
                  active: subchild1.isSelected && !subchild1.submenuItems
                }"
              >
                <a
                  class="menu-item"
                  *ngIf="!subchild1.isExternalLink"
                  routerLink="{{ subchild1.page }}"
                  (click)="toggleMenu($event, subchild1, true)"
                  >{{ subchild1.title }}</a
                >
                <a
                  *ngIf="subchild1.isExternalLink"
                  [href]="subchild1.page"
                  target="_blank"
                  (click)="toggleMenu($event, subchild1, true)"
                >
                  {{ subchild1.title }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
