import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MetadataService } from '@shared/service/metadata.service';
import { PostLoginService } from '@shared/service/post-login.service';
import { IntercomService } from '@simpology/authentication';
import { filter, startWith, Subject, take, takeUntil } from 'rxjs';
import { AccountProfile } from './account/store/profile/account-profile.model';
import { AccountProfileQuery } from './account/store/profile/account-profile.query';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  public title = 'SimpologyAdmin';

  private profile: AccountProfile;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private metadataService: MetadataService,
    private postLoginService: PostLoginService,
    private intercomService: IntercomService,
    private accountProfileQuery: AccountProfileQuery
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.profile && this.profile.enableIntercom) {
          this.intercomService.updateIntercom();
        }
      }
    });
  }
  ngAfterViewInit(): void {
    this.accountProfileQuery
      .select()
      .pipe(
        startWith(undefined),
        filter((profile) => !!profile),
        take(2)
      )
      .subscribe((profile: AccountProfile) => {
        this.profile = profile;
        if (this.profile.enableIntercom) {
          this.intercomService.injectIntercomScript();
        }
      });

    this.postLoginService.loginCompleted$.pipe().subscribe((user) => {
      if (user) {
        this.metadataService.loadInitialSetup();
        if (this.profile && this.profile.enableIntercom) {
          this.intercomService.bootIntercom();
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
