import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { Version } from '../model/version.model';

@Injectable({ providedIn: 'root' })
export class VersionApiService extends BaseApiService<Version> {
  public constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('Version');
  }

  public getVersion(): Observable<Version[]> {
    return this.getAll();
  }
}
