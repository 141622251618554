import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MenuItem } from '../navigation.model';
import { Router } from '@angular/router';
import { NavigationService } from '../navigation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html',
  styleUrls: ['./vertical-navigation.component.scss'],
  animations: [
    trigger('popOverState', [
      state(
        'show',
        style({
          opacity: '1',
        })
      ),
      state(
        'hide',
        style({
          opacity: '0',
          height: '*',
        })
      ),
      transition('show => hide', animate('200ms ease-in-out')),
      transition('hide => show', animate('200ms ease-in-out')),
    ]),
  ],
})
export class VerticalNavigationComponent implements OnInit, OnDestroy {
  @Input() public menuItems: MenuItem[];
  private verticalMenuToggleSubscription: Subscription;
  private resetSelectedMenuSubscription: Subscription;

  constructor(
    protected router: Router,
    private navigationService: NavigationService
  ) {}

  public ngOnInit(): void {
    this.verticalMenuToggleSubscription = this.navigationService.verticalMenuToggler$.subscribe(
      (value: boolean) => {
        if (value) {
          this.navigationService.setSelectedMenuItemInNavbar(
            this.menuItems,
            this.router.url
          );
        }
      }
    );
    this.resetSelectedMenuSubscription = this.navigationService.resetSelectedMenuByRoute$.subscribe(
      _ => {
        this.navigationService.setSelectedMenuItemInNavbar(
          this.menuItems,
          this.router.url
        );
      }
    );
  }

  public ngOnDestroy(): void {
    this.verticalMenuToggleSubscription.unsubscribe();
    this.resetSelectedMenuSubscription.unsubscribe();
  }

  get url(): string {
    return this.router.url;
  }

  public toggleMenu(
    event: any,
    child: MenuItem,
    isSubmenuOfSubmenu: boolean
  ): void {
    this.navigationService.toggleMenu(
      child,
      isSubmenuOfSubmenu,
      this.menuItems
    );
    if (child.page) {
      this.navigationService.toggleVerticalMenu();
    }
  }
}
