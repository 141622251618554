import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonTypeHomePageApiService } from '@shared/service/api/person-type-homepage-api.service';
import { UserAccessService } from '@shared/service/user-access-service';
import { AuthService } from '@simpology/authentication';
import { forkJoin } from 'rxjs';
import { AccountProfileService } from 'src/app/account/store/profile/account-profile.service';
import { OrganisationEntityService } from 'src/app/organisation/store/entity/organisation-entity.service';
import { ENV_CONFIG } from 'src/env-config';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  public error: boolean;
  private defaultLandingPage = '/home/default';
  private defaultToDashboard = '/home/dashboard';

  constructor(
    private authService: AuthService,
    private userAccessService: UserAccessService,
    private router: Router,
    private route: ActivatedRoute,
    private organisationEntityService: OrganisationEntityService,
    private profileService: AccountProfileService,
    private personTypeHomePageApiService: PersonTypeHomePageApiService
  ) {}

  public async ngOnInit(): Promise<void> {
    // check for error
    if (this.route.snapshot.fragment.indexOf('error') >= 0) {
      this.error = true;
      return;
    }

    await this.authService.completeAuthentication();

    const isSameUser = this.userAccessService.checkUserValid(this.authService.channelId, this.authService.id);
    if (!isSameUser || this.authService.permissions.length === 0 || this.authService.permissions[0].id === 0) {
      const redirectUrl = this.userAccessService.getRedirectUrl();
      if (redirectUrl) {
        this.authService.signout({
          extraQueryParams: {
            logOutRedirectUri: redirectUrl,
          },
        });
      } else {
        this.authService.signout();
      }

      return;
    }
    this.userAccessService.resetLogInUser();

    if (this.authService.returnUrl === this.defaultLandingPage) {
      forkJoin([
        this.profileService.getFromCache(),
        this.personTypeHomePageApiService.getDefaultLandingPage(),
      ]).subscribe((results) => {
        const setting = results[0];
        const defaultPage = results[1];

        switch (defaultPage?.name) {
          case 'Omni':
            this.authService.returnUrl = this.defaultToDashboard;
            const omniUrl = `${ENV_CONFIG.omniUrl}?returnUrl=${defaultPage.url}`;
            window.open(omniUrl, '_blank');
            break;
          case 'Loanapp':
            this.authService.returnUrl = this.defaultToDashboard;
            const loanappUrl = setting.loanappUrl ?? ENV_CONFIG.loanappUrl;
            const loanappRedirectUrl = `${loanappUrl}?returnUrl=${defaultPage.url}`;
            window.open(loanappRedirectUrl, '_blank');
            break;
          case 'Prequalification':
            this.authService.returnUrl = this.defaultToDashboard;
            if (setting.prequalificationUrl) {
              const preQualUrl = `${setting.prequalificationUrl}/home/${this.authService.id}`;
              window.open(preQualUrl, '_blank');
            }
            break;
          default:
            this.authService.returnUrl = defaultPage?.url ?? this.defaultToDashboard;
        }
        this.router.navigate([this.authService.returnUrl]);
      });
    } else {
      if (this.authService.returnUrl.indexOf('?') > 0) {
        const pathParams = this.authService.returnUrl.split('?');
        const path = pathParams[0];
        const params = pathParams[1].split('&');
        const dict = {};
        params.forEach((p) => {
          const keyValue = p.split('=');
          dict[keyValue[0]] = keyValue[1];
        });
        this.router.navigate([path], { queryParams: dict });
      } else {
        this.router.navigate([this.authService.returnUrl]);
      }
    }

    // Get all entities after login
    // TODO: @bages get and store current logged on person -see authService.id for userid
    this.organisationEntityService.getAll().subscribe();
  }
}
