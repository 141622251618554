/* eslint-disable max-len */

/* eslint-disable @typescript-eslint/quotes */

export interface EnumMapper {
  id: string;
  enumType: any;
  mapping: Map<number, string>;
}

export enum ConfirmationDialogHeaderType {
  Success = 1,
  Warning = 2,
  Danger = 3,
}

export enum Access {
  None = 0,
  Read = 1,
  Write = 2,
  Yes = 4,
}

export enum AvailabilityStatus {
  online = 1,
  Busy = 2,
  Away = 3,
}

export enum LoanSecurityType {
  Current = 1,
  New = 2,
}

export enum LoanApplicationParticipantType {
  AutoSelect = 6,
  Applicant1 = 1,
  Applicant2 = 2,
  Broker = 3,
  Accountant = 4,
  Lawyer = 5,
}

export enum RequirementCondition {
  All = 1,
  Any = 2,
  AtLeast = 3,
}

export const RequirementConditionLabel = new Map<number, string>([
  [RequirementCondition.All, 'All'],
  [RequirementCondition.Any, 'Any'],
  [RequirementCondition.AtLeast, 'At Least'],
]);

export enum ProvideDocPersonType {
  PrimaryApplicant = 16,
  CoApplicant = 17,
  AllApplicants = 18,
  Administrator = 1,
  Broker = 2,
  TeamLead = 3,
  CreditAssessor = 4,
  LoanOfficer = 6,
  MobileLender = 7,
  Manager = 8,
  SeniorAssessor = 9,
  Lawyer = 10,
  Accountant = 11,
  Advisor = 12,
  Assessor = 13,
  Valuer = 14,
  ProductManager = 15,
  SalesAssessmentOfficer = 1016,
  PropertyDeveloper = 1017,
  MortgageManager = 1018,
  Settlements = 1019,
  BusinessDevelopmentManager = 1020,
  BrokerAdministration = 1021,
  ProductTeam = 1022,
  ProjectTeam = 1023,
  DistributionTeam = 1024,
  MortgageServicesTeamLeader = 1025,
  MortgageServicesTeamMember = 1026,
  QualityAssuranceTeamMember = 1027,
  MortgageInsurer = 1029,
  PackagingOfficer = 1030,
  ReadOnly = 1031,
  Translator = 1032,
  RetentionOfficer = 1033,
  BrokerSupportOfficer = 1034,
  SupportStaff = 1035,
  BrokerageManager = 1036,
  LoanSpecialist = 1037,
  SalesSupportOfficer = 1038,
  UnknownEntity = 1039,
  Solicitor = 1040,
  CreditTeamLead = 1041,
  Assistant = 1042,
  LoanWriterAdminAssistant = 1043,
  Supervisor = 1044,
  SeniorManager = 1045,
  InternalLoanWriter = 1046,
  FraudManager = 1047,
  Conveyencer = 1048,
  ServiceabilityManager = 1049,
  PASAASManager = 1050,
  SystemManager = 1051,
  UserManagement = 1052,
  Franchise = 1053,
  BusinessManager = 1054,
  AccreditedAdministrator = 1055,
  FranchiseAdministrator = 1056,
  LoanWriter = 1057,
  Partner = 1058,
  LMISetupAdmin = 1059,
  TechSupport = 1060,
  FranchiseOwner = 1061,
  MlManagement = 1062,
  AdminAssistant = 1063,
  BusinessAdministrator = 1064,
  BusinessAdministratorFirstParty = 1065,
  BusinessAdministratorThirdParty = 1066,
  SubmittingOfficer = 1067,
  LendingSpecialist = 1068,
  RegionalManager = 1069,
}

export const ProvideDocPersonTypeLabel = new Map<number, string>([
  [ProvideDocPersonType.Administrator, 'Administrator'],
  [ProvideDocPersonType.Broker, 'Broker'],
  [ProvideDocPersonType.TeamLead, 'Team Lead'],
  [ProvideDocPersonType.CreditAssessor, 'Credit Assessor'],
  [ProvideDocPersonType.LoanOfficer, 'Loan Officer'],
  [ProvideDocPersonType.MobileLender, 'Mobile Lender'],
  [ProvideDocPersonType.Manager, 'Manager'],
  [ProvideDocPersonType.SeniorAssessor, 'Senior Assessor'],
  [ProvideDocPersonType.Lawyer, 'Lawyer'],
  [ProvideDocPersonType.Accountant, 'Accountant'],
  [ProvideDocPersonType.Advisor, 'Advisor'],
  [ProvideDocPersonType.Assessor, 'Assessor'],
  [ProvideDocPersonType.Valuer, 'Valuer'],
  [ProvideDocPersonType.ProductManager, 'Product Manager'],
  [ProvideDocPersonType.PrimaryApplicant, 'Primary Applicant'],
  [ProvideDocPersonType.CoApplicant, 'Co-Applicant'],
  [ProvideDocPersonType.AllApplicants, 'All applicants'],
  [ProvideDocPersonType.SalesAssessmentOfficer, 'Sales Assessment Officer'],
  [ProvideDocPersonType.PropertyDeveloper, 'Property Developer'],
  [ProvideDocPersonType.MortgageManager, 'Mortgage Manager'],
  [ProvideDocPersonType.Settlements, 'Settlements'],
  [ProvideDocPersonType.BusinessDevelopmentManager, 'Business Development Manager'],
  [ProvideDocPersonType.BrokerAdministration, 'Broker Administration'],
  [ProvideDocPersonType.ProductTeam, 'Product Team'],
  [ProvideDocPersonType.ProjectTeam, 'Project Team'],
  [ProvideDocPersonType.DistributionTeam, 'Distribution Team'],
  [ProvideDocPersonType.MortgageServicesTeamLeader, 'Mortgage Services Team Leader'],
  [ProvideDocPersonType.MortgageServicesTeamMember, 'Mortgage Services Team Member'],
  [ProvideDocPersonType.QualityAssuranceTeamMember, 'Quality Assurance Team Member'],
  [ProvideDocPersonType.MortgageInsurer, 'Mortgage Insurer'],
  [ProvideDocPersonType.PackagingOfficer, 'Packaging Officer'],
  [ProvideDocPersonType.ReadOnly, 'Read Only'],
  [ProvideDocPersonType.Translator, 'Translator'],
  [ProvideDocPersonType.RetentionOfficer, 'Retention Officer'],
  [ProvideDocPersonType.BrokerSupportOfficer, 'Broker Support Officer'],
  [ProvideDocPersonType.SupportStaff, 'Support Staff'],
  [ProvideDocPersonType.BrokerageManager, 'Brokerage Manager'],
  [ProvideDocPersonType.LoanSpecialist, 'Loan Specialist'],
  [ProvideDocPersonType.SalesSupportOfficer, 'Sales Support Officer'],
  [ProvideDocPersonType.UnknownEntity, 'Unknown Entity'],
  [ProvideDocPersonType.Solicitor, 'Solicitor'],
  [ProvideDocPersonType.CreditTeamLead, 'Credit Team Lead'],
  [ProvideDocPersonType.Assistant, 'Assistant'],
  [ProvideDocPersonType.LoanWriterAdminAssistant, 'Loan Writer Admin Assistant'],
  [ProvideDocPersonType.Supervisor, 'Supervisor'],
  [ProvideDocPersonType.SeniorManager, 'Senior Manager'],
  [ProvideDocPersonType.InternalLoanWriter, 'Internal Loan Writer'],
  [ProvideDocPersonType.FraudManager, 'Fraud Manager'],
  [ProvideDocPersonType.Conveyencer, 'Conveyencer'],
  [ProvideDocPersonType.ServiceabilityManager, 'Serviceability Manager'],
  [ProvideDocPersonType.PASAASManager, 'PASAAS Manager'],
  [ProvideDocPersonType.SystemManager, 'System Manager'],
  [ProvideDocPersonType.UserManagement, 'User Management'],
  [ProvideDocPersonType.Franchise, 'Franchise'],
  [ProvideDocPersonType.BusinessManager, 'Business Manager'],
  [ProvideDocPersonType.AccreditedAdministrator, 'Accredited Administrator'],
  [ProvideDocPersonType.FranchiseAdministrator, 'Franchise Administrator'],
  [ProvideDocPersonType.LoanWriter, 'Loan Writer'],
  [ProvideDocPersonType.Partner, 'Partner'],
  [ProvideDocPersonType.LMISetupAdmin, 'LMI Setup Admin'],
  [ProvideDocPersonType.SupportStaff, 'Tech Support'],
  [ProvideDocPersonType.FranchiseOwner, 'Franchise Owner'],
  [ProvideDocPersonType.MlManagement, 'Ml Management'],
  [ProvideDocPersonType.AdminAssistant, 'Admin Assistant'],
  [ProvideDocPersonType.BusinessAdministrator, 'Business Administrator'],
  [ProvideDocPersonType.BusinessAdministratorFirstParty, 'Business Administrator - First Party'],
  [ProvideDocPersonType.BusinessAdministratorThirdParty, 'Business Administrator - Third Party'],
  [ProvideDocPersonType.SubmittingOfficer, 'Submitting Officer'],
  [ProvideDocPersonType.LendingSpecialist, 'Lending Specialist'],
  [ProvideDocPersonType.RegionalManager, 'Regional Manager'],
]);

export enum PrintIndividualDocType {
  AllApplicants = 1000,
  AllParticipants = 1001,
  PrimaryApplicants = 1002,
  CoApplicants = 1003,
  LoanWriter = 1004,
  Guarantors = 1005,
}

export const PrintIndividualDocTypeLabel = new Map<number, string>([
  [PrintIndividualDocType.AllApplicants, 'All Applicants'],
  [PrintIndividualDocType.AllParticipants, 'All Participants'],
  [PrintIndividualDocType.PrimaryApplicants, 'Primary Applicants'],
  [PrintIndividualDocType.CoApplicants, 'Co-Applicants'],
  [PrintIndividualDocType.LoanWriter, 'Loan Writer'],
  [PrintIndividualDocType.Guarantors, 'Guarantors'],
]);

export enum UsedInEventsType {
  LoanappDocGeneration = 1000,
  OmniDocGeneration = 1001,
  Other = 1002,
}

export const UsedInEventsTypeLabel = new Map<number, string>([
  [UsedInEventsType.LoanappDocGeneration, 'Loanapp Document Generation'],
  [UsedInEventsType.OmniDocGeneration, 'Omni Document Generation'],
]);

export enum RecipientExtraType {
  CurrentCoordinator = 10000,
  AllBorrowers = 10001,
  AllBorrowersAndGuarantors = 10002,
}

export const RecipientExtraTypeLabel = new Map<number, string>([
  [RecipientExtraType.CurrentCoordinator, 'Current coordinator'],
  [RecipientExtraType.AllBorrowers, 'All borrowers'],
  [RecipientExtraType.AllBorrowersAndGuarantors, 'All borrowers and guarantors'],
]);

export enum IdentityDocumentType {
  AustralianBirthCertificate = 1000,
  AustralianCitizenshipCertificate = 1001,
  AustralianDriversLicence = 1002,
  AustralianPassport = 1003,
  AustralianPhotoFirearmsLicence = 1004,
  AustralianTaxationOfficeCorrespondenceWithTFN = 1005,
  BankStatement = 1006,
  CentrelinkorSocialSecurityCard = 1007,
  CreditCard = 1008,
  DeedPoll = 1009,
  DepartmentofVeteransAffairsCard = 1010,
  DescentCertificate = 1011,
  ElectoralRollAcknowledgement = 1012,
  ElectricityBill = 1013,
  ForeignBirthCertificate = 1014,
  GasBill = 1015,
  GovernmentBenefitsNotice = 1016,
  HealthCareCard = 1017,
  ImmiCard = 1018,
  InternationalPassport = 1019,
  InternetServiceProviderBill = 1020,
  LandlinePhoneBill = 1021,
  MarriageCertificate = 1022,
  MedicareCard = 1023,
  MotorVehicleRegistrationorInsurancePapers = 1024,
  NationalIdentityCard = 1025,
  Other = 1026,
  PensionCard = 1027,
  ProfessionalorTradeAssociationCard = 1028,
  ProofofAgeCard = 1029,
  PropertyInsurancePapers = 1030,
  PropertyLeaseorRentalAgreement = 1031,
  RatesNotice = 1032,
  StateorFederalGovernmentEmployeePhotoIdentityCard = 1033,
  TaxAssessment = 1034,
  TertiaryEducationInstitutionPhotoIdentityCard = 1035,
  VerificationofIdentityDocument = 1036,
  WaterUtilityBill = 1037,
  InternationalDriversLicence = 5001,
}

export const IdentityDocumentTypeLabel = new Map<number, string>([
  [IdentityDocumentType.AustralianBirthCertificate, 'Australian Birth Certificate'],
  [IdentityDocumentType.AustralianCitizenshipCertificate, 'Australian Citizenship Certificate'],
  [IdentityDocumentType.AustralianDriversLicence, 'Australian Drivers Licence'],
  [IdentityDocumentType.AustralianPassport, 'Australian Passport'],
  [IdentityDocumentType.AustralianPhotoFirearmsLicence, 'Australian Photo Firearms Licence'],
  [
    IdentityDocumentType.AustralianTaxationOfficeCorrespondenceWithTFN,
    'Australian Taxation Office Correspondence (with TFN)',
  ],
  [IdentityDocumentType.BankStatement, 'Bank Statement'],
  [IdentityDocumentType.CentrelinkorSocialSecurityCard, 'Centrelink or Social Security Card'],
  [IdentityDocumentType.CreditCard, 'Credit Card'],
  [IdentityDocumentType.DeedPoll, 'Deed Poll'],
  [IdentityDocumentType.DepartmentofVeteransAffairsCard, 'Department of Veterans Affairs Card'],
  [IdentityDocumentType.DescentCertificate, 'Descent Certificate'],
  [IdentityDocumentType.ElectoralRollAcknowledgement, 'Electoral Roll Acknowledgement'],
  [IdentityDocumentType.ElectricityBill, 'Electricity Bill'],
  [IdentityDocumentType.ForeignBirthCertificate, 'Foreign Birth Certificate'],
  [IdentityDocumentType.GasBill, 'Gas Bill'],
  [IdentityDocumentType.GovernmentBenefitsNotice, 'Government Benefits Notice'],
  [IdentityDocumentType.HealthCareCard, 'Health Care Card'],
  [IdentityDocumentType.ImmiCard, 'ImmiCard'],
  [IdentityDocumentType.InternationalPassport, 'International Passport'],
  [IdentityDocumentType.InternetServiceProviderBill, 'Internet Service Provider Bill'],
  [IdentityDocumentType.LandlinePhoneBill, 'Landline Phone Bill'],
  [IdentityDocumentType.MarriageCertificate, 'Marriage Certificate'],
  [IdentityDocumentType.MedicareCard, 'Medicare Card'],
  [IdentityDocumentType.MotorVehicleRegistrationorInsurancePapers, 'Motor Vehicle Registration or Insurance Papers'],
  [IdentityDocumentType.NationalIdentityCard, 'National Identity Card'],
  [IdentityDocumentType.Other, 'Other'],
  [IdentityDocumentType.PensionCard, 'Pension Card'],
  [IdentityDocumentType.ProfessionalorTradeAssociationCard, 'Professional or Trade Association Card'],
  [IdentityDocumentType.ProofofAgeCard, 'Proof of Age Card'],
  [IdentityDocumentType.PropertyInsurancePapers, 'Property Insurance Papers'],
  [IdentityDocumentType.PropertyLeaseorRentalAgreement, 'Property Lease or Rental Agreement'],
  [IdentityDocumentType.RatesNotice, 'Rates Notice'],
  [
    IdentityDocumentType.StateorFederalGovernmentEmployeePhotoIdentityCard,
    'State or Federal Government Employee Photo Identity Card',
  ],
  [IdentityDocumentType.TaxAssessment, 'Tax Assessment'],
  [
    IdentityDocumentType.TertiaryEducationInstitutionPhotoIdentityCard,
    'Tertiary Education Institution Photo Identity Card',
  ],
  [IdentityDocumentType.VerificationofIdentityDocument, 'Verification of Identity Document'],
  [IdentityDocumentType.WaterUtilityBill, 'Water Utility Bill'],
  [IdentityDocumentType.InternationalDriversLicence, 'International Drivers Licence'],
]);

export enum AttachmentType {
  ABNRegistrationCertificate = 5000,
  AcknowledgmentOfIndependentLegalAdvice = 5001,
  ApplicantGuarantorDeclaration = 5107,
  ApplicationCoverSheet = 5108,
  ApplicationForm = 5109,
  ApplicationRecommendations = 5002,
  ApplicationSubmitterNotes = 5110,
  ApplicationSummary = 5111,
  ATONoticeofAssessment = 5003,
  ATOPortalStatement = 5004,
  AustralianBirthCertificate = 1000,
  AustralianCitizenshipCertificate = 1001,
  AustralianDriversLicence = 1002,
  AustralianPassport = 1003,
  AustralianPhotoFirearmsLicence = 1004,
  AustralianTaxationOfficeCorrespondenceWithTFN = 1005,
  BalanceSheet = 5005,
  BankLetter = 5006,
  BankStatement = 1006,
  BASStatement = 5007,
  BirthCertificate = 5008,
  BodyCorporateLevyStatement = 5009,
  BrokerInterviewGuide = 5112,
  BuildersRiskInsurancePolicy = 5010,
  BuildingConsent = 5011,
  BuildingContract = 5012,
  BuildingPlansandSpecifications = 5013,
  BuildingSchedule = 5014,
  CentrelinkorSocialSecurityCard = 1007,
  CertificateofIdentity = 5015,
  CertificateofIncorporation = 5016,
  CertificateofRegistration = 5017,
  CompanyAppendix = 5018,
  CompanyExtract = 5019,
  ContractWorksInsurancePolicy = 5020,
  CouncilApprovedPlans = 5021,
  CourtOrder = 5022,
  CreditCard = 1008,
  CurrencyConversionReceipt = 5023,
  DebitCard = 5024,
  DeedofGift = 5025,
  DeedPoll = 1009,
  DepartmentofVeteransAffairsCard = 1010,
  DescentCertificate = 1011,
  DischargeRequestAuthority = 5026,
  DividendStatement = 5027,
  DunandBradstreetCreditReport = 5028,
  ElectoralRollAcknowledgement = 1012,
  ElectricityBill = 1013,
  ElectronicDisclosuresandSignaturesConsent = 5029,
  ElectronicSignatureCertificateofCompletion = 5030,
  EmergencyTravelDocument = 5031,
  EmployerLetter = 5032,
  EmploymentContract = 5033,
  EquifaxCreditReport = 5034,
  EvidenceofBusinessDebts = 5035,
  EvidenceofCreditIssue = 5036,
  EvidenceofFundsStatement = 5037,
  EvidenceofSalaryCrediting = 5038,
  ExitStrategyDocument = 5039,
  ExtensionDeclaration = 5113,
  FirstHomeOwnersGrantApproval = 5040,
  ForeignBirthCertificate = 1014,
  ForeignCitizenshipCertificate = 5041,
  GasBill = 1015,
  GovernmentBenefitsNotice = 1016,
  GroupCertificate = 5042,
  GuarantorChecklist = 5114,
  GuarantorSelfDeclaration = 5115,
  HealthCareCard = 1017,
  HomeLoanInterviewGuide = 5116,
  HomeOwnersWarrantyCertificate = 5043,
  ImmiCard = 5044,
  InsuranceBill = 5045,
  InternationalDriverLicense = 5046,
  InternationalPassport = 1018,
  InternetAccountSummary = 5047,
  InternetServiceProviderBill = 1019,
  InternetTransactionStatement = 5048,
  InvestmentStatement = 5049,
  LandlinePhoneBill = 1020,
  LendersMortgageInsurancePolicy = 1021,
  LetterFromAccountant = 5050,
  LetterFromBodyCorporateManager = 5051,
  LetterFromCSA = 5052,
  LetterFromGiftProvider = 5053,
  LetterFromRealEstateAgent = 5054,
  LetterFromWorkCover = 5055,
  LicensedBuilderOffer = 5056,
  LMIToolkit = 5117,
  LoDocDeclaration = 5057,
  LoanStatement = 5058,
  LockRateForm = 5118,
  MaintenanceAgreement = 5059,
  ManagedFundStatement = 5060,
  ManagementStatement = 5061,
  MarketRentInformation = 5062,
  MarriageCertificate = 1022,
  MedicareCard = 1023,
  MotorVehicleRegistrationorInsurancePapers = 1024,
  NationalIdentityCard = 1025,
  NRASLeaseAgreement = 5063,
  OfferandAcceptance = 5064,
  Other = 1026,
  PartnershipAgreement = 5065,
  PAYGSummary = 5066,
  PaymentReceipt = 5067,
  Payslip = 5068,
  PensionCard = 1027,
  PowerofAttorney = 5069,
  PrintFormCopyoftheApplication = 5070,
  ProfessionalorTradeAssociationCard = 1028,
  ProfitandLossStatement = 5071,
  ProofofAgeCard = 1029,
  ProofofForeignIncome = 5072,
  ProofofIncomeVariation = 5073,
  ProofofOtherIncome = 5074,
  ProofofPrivatePension = 5075,
  ProofofSalaryCredit = 5076,
  PropertyInsurancePapers = 1030,
  PropertyLeaseorRentalAgreement = 1031,
  PublicLiabilityInsurancePolicy = 5077,
  PurchaseandSalesContract = 5078,
  RatesNotice = 1032,
  RefugeeTravelDocument = 5079,
  RentalAppraisal = 5080,
  RentalStatement = 5081,
  ServiceabilityResultDocument = 5082,
  ServiceabilitySpreadsheet = 5083,
  SettlementStatement = 5084,
  ShareCertificate = 5085,
  ShareholderStatement = 5086,
  SMSFAuditorsReport = 5087,
  SMSFInvestmentStrategy = 5088,
  SolicitorLetter = 5089,
  StateorFederalGovernmentEmployeePhotoIdentityCard = 1033,
  StatementofAdvice = 5090,
  StatementofPosition = 5091,
  StatutoryDeclaration = 5092,
  StoreCardStatement = 5093,
  SuperFundLookupRecord = 5094,
  SuperannuationStatement = 5095,
  TaxAssessment = 1034,
  TaxReturn = 5096,
  TenancyAgreement = 5097,
  TentativeonCompletionValuation = 5098,
  TermDepositStatement = 5099,
  TertiaryEducationInstitutionPhotoIdentityCard = 1035,
  TransferofLandApproval = 5100,
  TrustAppendix = 5101,
  TrustDeed = 5102,
  TrustSchedule = 5103,
  ValuationReport = 5104,
  VerificationofIdentityDocument = 1036,
  Visa = 5105,
  WaterUtilityBill = 1037,
  ZoningCertificate = 5106,
}

export const AttachmentTypeLabel = new Map<number, string>([
  [AttachmentType.ABNRegistrationCertificate, 'ABN Registration Certificate'],
  [AttachmentType.AcknowledgmentOfIndependentLegalAdvice, 'Acknowledgment of Independent Legal Advice'],
  [AttachmentType.ApplicantGuarantorDeclaration, 'Applicant Guarantor Declaration'],
  [AttachmentType.ApplicationCoverSheet, 'Application Cover Sheet'],
  [AttachmentType.ApplicationForm, 'Application Form'],
  [AttachmentType.ApplicationRecommendations, 'Application Recommendations'],
  [AttachmentType.ApplicationSubmitterNotes, 'Application Submitter Notes'],
  [AttachmentType.ApplicationSummary, 'Application Summary'],
  [AttachmentType.ATONoticeofAssessment, 'ATO Notice of Assessment'],
  [AttachmentType.ATOPortalStatement, 'ATO Portal Statement'],
  [AttachmentType.AustralianBirthCertificate, 'Australian Birth Certificate'],
  [AttachmentType.AustralianCitizenshipCertificate, 'Australian Citizenship Certificate'],
  [AttachmentType.AustralianDriversLicence, 'Australian Drivers Licence'],
  [AttachmentType.AustralianPassport, 'Australian Passport'],
  [AttachmentType.AustralianPhotoFirearmsLicence, 'Australian Photo Firearms Licence'],
  [
    AttachmentType.AustralianTaxationOfficeCorrespondenceWithTFN,
    'Australian Taxation Office Correspondence (with TFN)',
  ],
  [AttachmentType.BalanceSheet, 'Balance Sheet'],
  [AttachmentType.BankLetter, 'Bank Letter'],
  [AttachmentType.BankStatement, 'Bank Statement'],
  [AttachmentType.BASStatement, 'BAS Statement'],
  [AttachmentType.BirthCertificate, 'Birth Certificate'],
  [AttachmentType.BodyCorporateLevyStatement, 'Body Corporate Levy Statement'],
  [AttachmentType.BrokerInterviewGuide, 'Broker Interview Guide'],
  [AttachmentType.BuildersRiskInsurancePolicy, 'Builders Risk Insurance Policy'],
  [AttachmentType.BuildingConsent, 'Building Consent'],
  [AttachmentType.BuildingContract, 'Building Contract'],
  [AttachmentType.BuildingPlansandSpecifications, 'Building Plans and Specifications'],
  [AttachmentType.BuildingSchedule, 'Building Schedule'],
  [AttachmentType.CentrelinkorSocialSecurityCard, 'Centrelink or Social Security Card'],
  [AttachmentType.CertificateofIdentity, 'Certificate of Identity'],
  [AttachmentType.CertificateofIncorporation, 'Certificate of Incorporation'],
  [AttachmentType.CertificateofRegistration, 'Certificate of Registration'],
  [AttachmentType.CompanyAppendix, 'Company Appendix'],
  [AttachmentType.CompanyExtract, 'Company Extract'],
  [AttachmentType.ContractWorksInsurancePolicy, 'Contract Works Insurance Policy'],
  [AttachmentType.CouncilApprovedPlans, 'Council Approved Plans'],
  [AttachmentType.CourtOrder, 'Court Order'],
  [AttachmentType.CreditCard, 'Credit Card'],
  [AttachmentType.CurrencyConversionReceipt, 'Currency Conversion Receipt'],
  [AttachmentType.DebitCard, 'Debit Card'],
  [AttachmentType.DeedofGift, 'Deed of Gift'],
  [AttachmentType.DeedPoll, 'Deed Poll'],
  [AttachmentType.DepartmentofVeteransAffairsCard, 'Department of Veterans Affairs Card'],
  [AttachmentType.DescentCertificate, 'Descent Certificate'],
  [AttachmentType.DischargeRequestAuthority, 'Discharge Request Authority'],
  [AttachmentType.DividendStatement, 'Dividend Statement'],
  [AttachmentType.DunandBradstreetCreditReport, 'Dun and Bradstreet Credit Report'],
  [AttachmentType.ElectoralRollAcknowledgement, 'Electoral Roll Acknowledgement'],
  [AttachmentType.ElectricityBill, 'Electricity Bill'],
  [AttachmentType.ElectronicDisclosuresandSignaturesConsent, 'Electronic Disclosures and Signatures Consent'],
  [AttachmentType.ElectronicSignatureCertificateofCompletion, 'Electronic Signature Certificate of Completion'],
  [AttachmentType.EmergencyTravelDocument, 'Emergency Travel Document'],
  [AttachmentType.EmployerLetter, 'Employer Letter'],
  [AttachmentType.EmploymentContract, 'Employment Contract'],
  [AttachmentType.EquifaxCreditReport, 'Equifax Credit Report'],
  [AttachmentType.EvidenceofBusinessDebts, 'Evidence of Business Debts'],
  [AttachmentType.EvidenceofCreditIssue, 'Evidence of Credit Issue'],
  [AttachmentType.EvidenceofFundsStatement, 'Evidence of Funds Statement'],
  [AttachmentType.EvidenceofSalaryCrediting, 'Evidence of Salary Crediting'],
  [AttachmentType.ExitStrategyDocument, 'Exit Strategy Document'],
  [AttachmentType.ExtensionDeclaration, 'Extension Declaration'],
  [AttachmentType.FirstHomeOwnersGrantApproval, 'First Home Owners Grant Approval'],
  [AttachmentType.ForeignBirthCertificate, 'Foreign Birth Certificate'],
  [AttachmentType.ForeignCitizenshipCertificate, 'Foreign Citizenship Certificate'],
  [AttachmentType.GasBill, 'Gas Bill'],
  [AttachmentType.GovernmentBenefitsNotice, 'Government Benefits Notice'],
  [AttachmentType.GroupCertificate, 'Group Certificate'],
  [AttachmentType.GuarantorChecklist, 'Guarantor Checklist'],
  [AttachmentType.GuarantorSelfDeclaration, 'Guarantor Self Declaration'],
  [AttachmentType.HealthCareCard, 'Health Care Card'],
  [AttachmentType.HomeLoanInterviewGuide, 'Home Loan Interview Guide'],
  [AttachmentType.HomeOwnersWarrantyCertificate, 'Home Owners Warranty Certificate'],
  [AttachmentType.ImmiCard, 'ImmiCard'],
  [AttachmentType.InsuranceBill, 'Insurance Bill'],
  [AttachmentType.InternationalDriverLicense, 'International Driver License'],
  [AttachmentType.InternationalPassport, 'International Passport'],
  [AttachmentType.InternetAccountSummary, 'Internet Account Summary'],
  [AttachmentType.InternetServiceProviderBill, 'Internet Service Provider Bill'],
  [AttachmentType.InternetTransactionStatement, 'Internet Transaction Statement'],
  [AttachmentType.InvestmentStatement, 'Investment Statement'],
  [AttachmentType.LandlinePhoneBill, 'Landline Phone Bill'],
  [AttachmentType.LendersMortgageInsurancePolicy, 'Lenders Mortgage Insurance Policy'],
  [AttachmentType.LetterFromAccountant, 'Letter From Accountant'],
  [AttachmentType.LetterFromBodyCorporateManager, 'Letter From Body Corporate Manager'],
  [AttachmentType.LetterFromCSA, 'Letter From CSA'],
  [AttachmentType.LetterFromGiftProvider, 'Letter From Gift Provider'],
  [AttachmentType.LetterFromRealEstateAgent, 'Letter From Real Estate Agent'],
  [AttachmentType.LetterFromWorkCover, 'Letter From WorkCover'],
  [AttachmentType.LicensedBuilderOffer, 'Licensed Builder Offer'],
  [AttachmentType.LMIToolkit, 'LMI Toolkit'],
  [AttachmentType.LoDocDeclaration, 'Lo Doc Declaration'],
  [AttachmentType.LoanStatement, 'Loan Statement'],
  [AttachmentType.LockRateForm, 'Lock Rate Form'],
  [AttachmentType.MaintenanceAgreement, 'Maintenance Agreement'],
  [AttachmentType.ManagedFundStatement, 'Managed Fund Statement'],
  [AttachmentType.ManagementStatement, 'Management Statement'],
  [AttachmentType.MarketRentInformation, 'Market Rent Information'],
  [AttachmentType.MarriageCertificate, 'Marriage Certificate'],
  [AttachmentType.MedicareCard, 'Medicare Card'],
  [AttachmentType.MotorVehicleRegistrationorInsurancePapers, 'Motor Vehicle Registration or Insurance Papers'],
  [AttachmentType.NationalIdentityCard, 'National Identity Card'],
  [AttachmentType.NRASLeaseAgreement, 'NRAS Lease Agreement'],
  [AttachmentType.OfferandAcceptance, 'Offer and Acceptance'],
  [AttachmentType.Other, 'Other'],
  [AttachmentType.PartnershipAgreement, 'Partnership Agreement'],
  [AttachmentType.PAYGSummary, 'PAYG Summary'],
  [AttachmentType.PaymentReceipt, 'Payment Receipt'],
  [AttachmentType.Payslip, 'Payslip'],
  [AttachmentType.PensionCard, 'Pension Card'],
  [AttachmentType.PowerofAttorney, 'Power of Attorney'],
  [AttachmentType.PrintFormCopyoftheApplication, 'Print Form Copy of the Application'],
  [AttachmentType.ProfessionalorTradeAssociationCard, 'Professional or Trade Association Card'],
  [AttachmentType.ProfitandLossStatement, 'Profit and Loss Statement'],
  [AttachmentType.ProofofAgeCard, 'Proof of Age Card'],
  [AttachmentType.ProofofForeignIncome, 'Proof of Foreign Income'],
  [AttachmentType.ProofofIncomeVariation, 'Proof of Income Variation'],
  [AttachmentType.ProofofOtherIncome, 'Proof of Other Income'],
  [AttachmentType.ProofofPrivatePension, 'Proof of Private Pension'],
  [AttachmentType.ProofofSalaryCredit, 'Proof of Salary Credit'],
  [AttachmentType.PropertyInsurancePapers, 'Property Insurance Papers'],
  [AttachmentType.PropertyLeaseorRentalAgreement, 'Property Lease or Rental Agreement'],
  [AttachmentType.PublicLiabilityInsurancePolicy, 'Public Liability Insurance Policy'],
  [AttachmentType.PurchaseandSalesContract, 'Purchase and Sales Contract'],
  [AttachmentType.RatesNotice, 'Rates Notice'],
  [AttachmentType.RefugeeTravelDocument, 'Refugee Travel Document'],
  [AttachmentType.RentalAppraisal, 'Rental Appraisal'],
  [AttachmentType.RentalStatement, 'Rental Statement'],
  [AttachmentType.ServiceabilityResultDocument, 'Serviceability Result Document'],
  [AttachmentType.ServiceabilitySpreadsheet, 'Serviceability Spreadsheet'],
  [AttachmentType.SettlementStatement, 'Settlement Statement'],
  [AttachmentType.ShareCertificate, 'Share Certificate'],
  [AttachmentType.ShareholderStatement, 'Shareholder Statement'],
  [AttachmentType.SMSFAuditorsReport, 'SMSF Auditors Report'],
  [AttachmentType.SMSFInvestmentStrategy, 'SMSF Investment Strategy'],
  [AttachmentType.SolicitorLetter, 'Solicitor Letter'],
  [
    AttachmentType.StateorFederalGovernmentEmployeePhotoIdentityCard,
    'State or Federal Government Employee Photo Identity Card',
  ],
  [AttachmentType.StatementofAdvice, 'Statement of Advice'],
  [AttachmentType.StatementofPosition, 'Statement of Position'],
  [AttachmentType.StatutoryDeclaration, 'Statutory Declaration'],
  [AttachmentType.StoreCardStatement, 'Store Card Statement'],
  [AttachmentType.SuperFundLookupRecord, 'Super Fund Lookup Record'],
  [AttachmentType.SuperannuationStatement, 'Superannuation Statement'],
  [AttachmentType.TaxAssessment, 'Tax Assessment'],
  [AttachmentType.TaxReturn, 'Tax Return'],
  [AttachmentType.TenancyAgreement, 'Tenancy Agreement'],
  [AttachmentType.TentativeonCompletionValuation, 'Tentative on Completion Valuation'],
  [AttachmentType.TermDepositStatement, 'Term Deposit Statement'],
  [AttachmentType.TertiaryEducationInstitutionPhotoIdentityCard, 'Tertiary Education Institution Photo Identity Card'],
  [AttachmentType.TransferofLandApproval, 'Transfer of Land Approval'],
  [AttachmentType.TrustAppendix, 'Trust Appendix'],
  [AttachmentType.TrustDeed, 'Trust Deed'],
  [AttachmentType.TrustSchedule, 'Trust Schedule'],
  [AttachmentType.ValuationReport, 'Valuation Report'],
  [AttachmentType.VerificationofIdentityDocument, 'Verification of Identity Document'],
  [AttachmentType.Visa, 'Visa'],
  [AttachmentType.WaterUtilityBill, 'Water Utility Bill'],
  [AttachmentType.ZoningCertificate, 'Zoning Certificate'],
]);

// FeatureOptionStateCode values should match with FeatureOptionFeatureOptionCode enum for values 13***
export enum FeatureOptionStateCode {
  NSW = 13001,
  Vic = 13002,
  Qld = 13003,
  SA = 13004,
  WA = 13005,
  Tas = 13006,
  ACT = 13007,
  NT = 13008,
}

export const FeatureOptionStateCodeLabel = new Map<number, string>([
  [FeatureOptionStateCode.NSW, 'New South Wales'],
  [FeatureOptionStateCode.Vic, 'Victoria'],
  [FeatureOptionStateCode.Qld, 'Queensland'],
  [FeatureOptionStateCode.SA, 'South Australia'],
  [FeatureOptionStateCode.WA, 'Western Australia'],
  [FeatureOptionStateCode.Tas, 'Tasmania'],
  [FeatureOptionStateCode.ACT, 'Australian Capital Territory'],
  [FeatureOptionStateCode.NT, 'Northern Territory'],
]);

export enum ApplicationParticipantSubType {
  Administrator = 1,
  Broker = 2,
  TeamLead = 3,
  CreditAssessor = 4,
  Consumer = 5,
  LoanOfficer = 6,
  MobileLender = 7,
  Manager = 8,
  SeniorAssessor = 9,
  Lawyer = 10,
  Accountant = 11,
  Advisor = 12,
  Assessor = 13,
  Valuer = 14,
  ProductManager = 15,
  SalesAssessmentOfficer = 16,
  PropertyDeveloper = 17,
  MortgageManager = 18,
  PrimaryApplicant = 101,
  CoApplicant = 102,
  BusinessDevelopmentManager = 20,
  BrokerAdministration = 21,
  ProductTeam = 22,
  ProjectTeam = 23,
  DistributionTeam = 24,
  MortgageServicesTeamLeader = 25,
  MortgageServicesTeamMember = 26,
  QualityAssuranceTeamMember = 27,
  SimpologyAdmin = 28,
  MortgageInsurer = 29,
  PackagingOfficer = 30,
  ReadOnly = 31,
  Translator = 32,
  RetentionOfficer = 33,
  BrokerSupportOfficer = 34,
  SupportStaff = 35,
  BrokerageManager = 36,
  LoanSpecialist = 37,
  SalesSupportOfficer = 38,
  UnknownEntity = 39,
  Solicitor = 40,
  CreditTeamLead = 41,
  Assistant = 42,
  LoanWriterAdminAssistant = 43,
  Supervisor = 44,
  SeniorManager = 45,
  InternalLoanWriter = 46,
  FraudManager = 47,
  Conveyencer = 48,
  ServiceabilityManager = 49,
  PASAASManager = 50,
  SystemManager = 51,
  UserManagement = 52,
  Franchise = 53,
  BusinessManager = 54,
  AccreditedAdministrator = 55,
  FranchiseAdministrator = 56,
  LoanWriter = 57,
  Partner = 58,
  LMISetupAdmin = 59,
  TechSupport = 60,
  FranchiseOwner = 61,
  MLManagement = 62,
  AdminAssistant = 63,
  BusinessAdministrator = 64,
  BusinessAdministratorFirstParty = 65,
  BusinessAdministratorThirdParty = 66,
  SubmittingOfficer = 67,
  LendingSpecialist = 68,
  RegionalManager = 69,
}

export const ApplicationParticipantSubTypeLabel = new Map<number, string>([
  [ApplicationParticipantSubType.Administrator, 'Administrator'],
  [ApplicationParticipantSubType.Broker, 'Broker'],
  [ApplicationParticipantSubType.TeamLead, 'Team Lead'],
  [ApplicationParticipantSubType.CreditAssessor, 'Credit Assessor'],
  [ApplicationParticipantSubType.Consumer, 'Consumer'],
  [ApplicationParticipantSubType.LoanOfficer, 'Loan Officer'],
  [ApplicationParticipantSubType.MobileLender, 'Mobile Lender'],
  [ApplicationParticipantSubType.Manager, 'Manager'],
  [ApplicationParticipantSubType.SeniorAssessor, 'Senior Assessor'],
  [ApplicationParticipantSubType.Lawyer, 'Lawyer'],
  [ApplicationParticipantSubType.Accountant, 'Accountant'],
  [ApplicationParticipantSubType.Advisor, 'Advisor'],
  [ApplicationParticipantSubType.Assessor, 'Assessor'],
  [ApplicationParticipantSubType.Valuer, 'Valuer'],
  [ApplicationParticipantSubType.ProductManager, 'Product Manager'],
  [ApplicationParticipantSubType.SalesAssessmentOfficer, 'Sales Assessment Officer'],
  [ApplicationParticipantSubType.PropertyDeveloper, 'Property Developer'],
  [ApplicationParticipantSubType.MortgageManager, 'Mortgage Manager'],
  [ApplicationParticipantSubType.PrimaryApplicant, 'Primary applicant'],
  [ApplicationParticipantSubType.CoApplicant, 'Co-applicant'],
  [ApplicationParticipantSubType.BusinessDevelopmentManager, 'Business Development Manager'],
  [ApplicationParticipantSubType.BrokerAdministration, 'Broker Administration'],
  [ApplicationParticipantSubType.ProductTeam, 'Product Team'],
  [ApplicationParticipantSubType.ProjectTeam, 'Project Team'],
  [ApplicationParticipantSubType.DistributionTeam, 'Distribution Team'],
  [ApplicationParticipantSubType.MortgageServicesTeamLeader, 'Mortgage Services Team Leader'],
  [ApplicationParticipantSubType.MortgageServicesTeamMember, 'Mortgage Services Team Member'],
  [ApplicationParticipantSubType.QualityAssuranceTeamMember, 'Quality Assurance Team Member'],
  [ApplicationParticipantSubType.SimpologyAdmin, 'Simpology Admin'],
  [ApplicationParticipantSubType.MortgageInsurer, 'Mortgage Insurer'],
  [ApplicationParticipantSubType.PackagingOfficer, 'Packaging Officer'],
  [ApplicationParticipantSubType.ReadOnly, 'Read Only'],
  [ApplicationParticipantSubType.Translator, 'Translator'],
  [ApplicationParticipantSubType.RetentionOfficer, 'Retention Officer'],
  [ApplicationParticipantSubType.BrokerSupportOfficer, 'Broker Support Officer'],
  [ApplicationParticipantSubType.SupportStaff, 'Support Staff'],
  [ApplicationParticipantSubType.BrokerageManager, 'Brokerage Manager'],
  [ApplicationParticipantSubType.LoanSpecialist, 'Loan Specialist'],
  [ApplicationParticipantSubType.SalesSupportOfficer, 'Sales Support Officer'],
  [ApplicationParticipantSubType.UnknownEntity, 'Unknown Entity'],
  [ApplicationParticipantSubType.Solicitor, 'Solicitor'],
  [ApplicationParticipantSubType.CreditTeamLead, 'Credit Team Lead'],
  [ApplicationParticipantSubType.Assistant, 'Assistant'],
  [ApplicationParticipantSubType.LoanWriterAdminAssistant, 'Loan Writer Admin Assistant'],
  [ApplicationParticipantSubType.Supervisor, 'Supervisor'],
  [ApplicationParticipantSubType.SeniorManager, 'Senior Manager'],
  [ApplicationParticipantSubType.InternalLoanWriter, 'Internal Loan Writer'],
  [ApplicationParticipantSubType.FraudManager, 'Fraud Manager'],
  [ApplicationParticipantSubType.Conveyencer, 'Conveyencer'],
  [ApplicationParticipantSubType.ServiceabilityManager, 'Serviceability Manager'],
  [ApplicationParticipantSubType.PASAASManager, 'PASAAS Manager'],
  [ApplicationParticipantSubType.SystemManager, 'System Manager'],
  [ApplicationParticipantSubType.UserManagement, 'User Management'],
  [ApplicationParticipantSubType.Franchise, 'Franchise'],
  [ApplicationParticipantSubType.BusinessManager, 'Business Manager'],
  [ApplicationParticipantSubType.AccreditedAdministrator, 'Accredited Administrator'],
  [ApplicationParticipantSubType.FranchiseAdministrator, 'Franchise Administrator'],
  [ApplicationParticipantSubType.LoanWriter, 'Loan Writer'],
  [ApplicationParticipantSubType.Partner, 'Partner'],
  [ApplicationParticipantSubType.LMISetupAdmin, 'LMI Setup Admin'],
  [ApplicationParticipantSubType.SupportStaff, 'Tech Support'],
  [ApplicationParticipantSubType.FranchiseOwner, 'Franchise Owner'],
  [ApplicationParticipantSubType.MLManagement, 'ML Management'],
  [ApplicationParticipantSubType.AdminAssistant, 'Admin Assistant'],
  [ApplicationParticipantSubType.BusinessAdministrator, 'Business Administrator'],
  [ApplicationParticipantSubType.BusinessAdministratorFirstParty, 'Business Administrator - First Party'],
  [ApplicationParticipantSubType.BusinessAdministratorThirdParty, 'Business Administrator - Third Party'],
  [ApplicationParticipantSubType.SubmittingOfficer, 'Submitting Officer'],
  [ApplicationParticipantSubType.LendingSpecialist, 'Lending Specialist'],
  [ApplicationParticipantSubType.RegionalManager, 'Regional Manager'],
]);

export enum ServiceabilitySubsection {
  Indicator = 1000,
  PAYGIncome = 2000,
  SelfEmployedIncome = 2001,
  NotEmployedIncome = 2002,
  CompanyIncome = 2003,
  TrustIncome = 2004,
  ExistingRentalIncome = 2005,
  ProposedRentalIncome = 2006,
  OtherIncome = 2007,
  ForeignIncome = 2008,
  MortgageLiabilities = 3001,
  OtherLiabilities = 3002,
  RevolvingLiabilities = 3003,
  EstimatedExpenses = 4001,
  HouseholdExpenses = 4002,
  NewLoans = 5001,
}

export const ServiceabilitySubsectionLabel = new Map<number, string>([
  [ServiceabilitySubsection.Indicator, 'Indicator'],
  [ServiceabilitySubsection.PAYGIncome, 'PAYG income'],
  [ServiceabilitySubsection.SelfEmployedIncome, 'Self employed income'],
  [ServiceabilitySubsection.NotEmployedIncome, 'Not employed income'],
  [ServiceabilitySubsection.CompanyIncome, 'Company income'],
  [ServiceabilitySubsection.TrustIncome, 'Trust income'],
  [ServiceabilitySubsection.ExistingRentalIncome, 'Rental income (existing)'],
  [ServiceabilitySubsection.ProposedRentalIncome, 'Rental income (proposed)'],
  [ServiceabilitySubsection.OtherIncome, 'Other income'],
  [ServiceabilitySubsection.ForeignIncome, 'Foreign income'],
  [ServiceabilitySubsection.MortgageLiabilities, 'Mortgage liabilities'],
  [ServiceabilitySubsection.OtherLiabilities, 'Other liabilities'],
  [ServiceabilitySubsection.RevolvingLiabilities, 'Revolving liabilities'],
  [ServiceabilitySubsection.EstimatedExpenses, 'Estimated expenses'],
  [ServiceabilitySubsection.HouseholdExpenses, 'Household expenses'],
  [ServiceabilitySubsection.NewLoans, 'New loans'],
]);

export enum ServiceabilityFeature {
  LVR = 1,
  ApplicationType = 2,
  EmploymentBasis = 3,
  IncomeStatus = 4,
  OnProbation = 5,
  Residency = 6,
  ABSOccupationCode = 7,
  Duration = 8,
  ApplyHEMExpenses = 9,
  RegisteredFor = 10,
  TradingFor = 11,
  EstablishedFor = 12,
  CapitalizedToPropertyValue = 13,
  PropertyUsage = 14,
  NRAS = 15,
  Citizenship = 16,
  State = 17,
  InvestmentCostsCompareToCapitalizedRentalIncome = 18,
  ShortStayIncome = 19,
  IncomeInterval = 20,
  CoupleIncomeInterval = 21,
  LenderLiability = 22,
  ConditionOfEmployment = 23,
  IncomeYears = 24,
  PropertyType = 25,
  SingleUnit = 26,
  Postcode = 27,
  PostSettlementHousingStatus = 28,
  SpecialDisabilityAccommodation = 29,
  NDIS = 30,
  PropertyPurpose = 31,
  HolidayRental = 32,
  PaidInFull = 33,
  HasSplitLoans = 34,
  BusinessStructure = 35,
  GeographicZone = 36,
  RiskZone = 37,
  OriginalTermInterestType = 38,
  ApplicationDocumentType = 39,
}

export const ServiceabilityFeatureLabel = new Map<number, string>([
  [ServiceabilityFeature.LVR, 'LVR'],
  [ServiceabilityFeature.ApplicationType, 'Application type'],
  [ServiceabilityFeature.EmploymentBasis, 'Employment basis'],
  [ServiceabilityFeature.IncomeStatus, 'Income status'],
  [ServiceabilityFeature.OnProbation, 'On probation'],
  [ServiceabilityFeature.Residency, 'Residency'],
  [ServiceabilityFeature.ABSOccupationCode, 'ABS occupation code'],
  [ServiceabilityFeature.Duration, 'Duration (months)'],
  [ServiceabilityFeature.ApplyHEMExpenses, 'Apply HEM expenses'],
  [ServiceabilityFeature.RegisteredFor, 'Registered for (months)'],
  [ServiceabilityFeature.TradingFor, 'Trading for (months)'],
  [ServiceabilityFeature.EstablishedFor, 'Established for (months)'],
  [ServiceabilityFeature.CapitalizedToPropertyValue, 'Capitalized to property value'],
  [ServiceabilityFeature.PropertyUsage, 'Property usage'],
  [ServiceabilityFeature.NRAS, 'NRAS'],
  [ServiceabilityFeature.Citizenship, 'Citizenship'],
  [ServiceabilityFeature.State, 'State'],
  [
    ServiceabilityFeature.InvestmentCostsCompareToCapitalizedRentalIncome,
    'Investment costs compare to capitalized rental income',
  ],
  [ServiceabilityFeature.ShortStayIncome, 'Short stay income'],
  [ServiceabilityFeature.IncomeInterval, 'Income interval'],
  [ServiceabilityFeature.CoupleIncomeInterval, 'Income interval couple'],
  [ServiceabilityFeature.LenderLiability, 'Lender liability'],
  [ServiceabilityFeature.ConditionOfEmployment, 'Condition of employment'],
  [ServiceabilityFeature.IncomeYears, 'Income years'],
  [ServiceabilityFeature.PropertyType, 'Property type'],
  [ServiceabilityFeature.SingleUnit, 'Single unit'],
  [ServiceabilityFeature.Postcode, 'Postcode'],
  [ServiceabilityFeature.PostSettlementHousingStatus, 'Post settlement housing status'],
  [ServiceabilityFeature.SpecialDisabilityAccommodation, 'Special disability accommodation'],
  [ServiceabilityFeature.NDIS, 'NDIS'],
  [ServiceabilityFeature.PropertyPurpose, 'Property purpose'],
  [ServiceabilityFeature.HolidayRental, 'Holiday rental'],
  [ServiceabilityFeature.PaidInFull, 'Paid in full'],
  [ServiceabilityFeature.HasSplitLoans, 'Has split loans'],
  [ServiceabilityFeature.BusinessStructure, 'Business Structure'],
  [ServiceabilityFeature.GeographicZone, 'Location geo zone'],
  [ServiceabilityFeature.RiskZone, 'Location risk zone'],
  [ServiceabilityFeature.OriginalTermInterestType, 'Original term interest type'],
  [ServiceabilityFeature.ApplicationDocumentType, 'Application document type'],
]);

export enum ServiceabilityFeatureOption {
  Preapproval = 2000,
  Loan = 2001,
  LoanVariation = 2002,
  FullTime = 3000,
  PartTime = 3001,
  Contract = 3002,
  Temporary = 3003,
  Casual = 3004,
  CommissionOnly = 3005,
  Seasonal = 3006,
  Primary = 4000,
  Secondary = 4001,
  OnProbationYes = 5000,
  OnProbationNo = 5001,
  TemporarilyInAustralia = 6000,
  PermanentlyInAustralia = 6001,
  NonResident = 6002,
  TemporarilyInNZ = 6003,
  PermanentlyInNZ = 6004,
  ABS111111 = 7000,
  ABS111113 = 7001,
  ABS111115 = 7002,
  ABS111117 = 7003,
  ABS111179 = 7004,
  ABS111211 = 7005,
  ABS119111 = 7006,
  ABS119113 = 7007,
  ABS119211 = 7008,
  ABS119213 = 7009,
  ABS119311 = 7010,
  ABS121111 = 7011,
  ABS121211 = 7012,
  ABS121311 = 7013,
  ABS122111 = 7014,
  ABS122211 = 7015,
  ABS122213 = 7016,
  ABS122311 = 7017,
  ABS122411 = 7018,
  ABS123111 = 7019,
  ABS129111 = 7020,
  ABS129211 = 7021,
  ABS129213 = 7022,
  ABS129311 = 7023,
  ABS129313 = 7024,
  ABS129315 = 7025,
  ABS129379 = 7026,
  ABS129411 = 7027,
  ABS129413 = 7028,
  ABS129415 = 7029,
  ABS129481 = 7030,
  ABS129511 = 7031,
  ABS129611 = 7032,
  ABS129613 = 7033,
  ABS129911 = 7034,
  ABS129913 = 7035,
  ABS129915 = 7036,
  ABS129917 = 7037,
  ABS129919 = 7038,
  ABS129979 = 7039,
  ABS131111 = 7040,
  ABS131211 = 7041,
  ABS131213 = 7042,
  ABS131215 = 7043,
  ABS131217 = 7044,
  ABS131219 = 7045,
  ABS131221 = 7046,
  ABS131223 = 7047,
  ABS131225 = 7048,
  ABS131279 = 7049,
  ABS131311 = 7050,
  ABS131313 = 7051,
  ABS131315 = 7052,
  ABS131317 = 7053,
  ABS131319 = 7054,
  ABS131321 = 7055,
  ABS131379 = 7056,
  ABS131411 = 7057,
  ABS211111 = 7058,
  ABS211211 = 7059,
  ABS211213 = 7060,
  ABS211311 = 7061,
  ABS211313 = 7062,
  ABS211315 = 7063,
  ABS211317 = 7064,
  ABS211319 = 7065,
  ABS211379 = 7066,
  ABS211411 = 7067,
  ABS211413 = 7068,
  ABS211415 = 7069,
  ABS211417 = 7070,
  ABS211419 = 7071,
  ABS211421 = 7072,
  ABS211479 = 7073,
  ABS211511 = 7074,
  ABS211911 = 7075,
  ABS211913 = 7076,
  ABS211915 = 7077,
  ABS211917 = 7078,
  ABS211919 = 7079,
  ABS211979 = 7080,
  ABS212111 = 7081,
  ABS212113 = 7082,
  ABS212211 = 7083,
  ABS212311 = 7084,
  ABS212313 = 7085,
  ABS212411 = 7086,
  ABS212511 = 7087,
  ABS212513 = 7088,
  ABS212611 = 7089,
  ABS212613 = 7090,
  ABS212711 = 7091,
  ABS212713 = 7092,
  ABS212715 = 7093,
  ABS212811 = 7094,
  ABS212813 = 7095,
  ABS212815 = 7096,
  ABS212879 = 7097,
  ABS212911 = 7098,
  ABS212913 = 7099,
  ABS212915 = 7100,
  ABS212917 = 7101,
  ABS212919 = 7102,
  ABS212921 = 7103,
  ABS212979 = 7104,
  ABS221111 = 7105,
  ABS221211 = 7106,
  ABS221213 = 7107,
  ABS221311 = 7108,
  ABS222111 = 7109,
  ABS222113 = 7110,
  ABS222115 = 7111,
  ABS222117 = 7112,
  ABS222211 = 7113,
  ABS222213 = 7114,
  ABS222215 = 7115,
  ABS222279 = 7116,
  ABS223111 = 7117,
  ABS223113 = 7118,
  ABS223115 = 7119,
  ABS223117 = 7120,
  ABS223119 = 7121,
  ABS223121 = 7122,
  ABS223179 = 7123,
  ABS229111 = 7124,
  ABS229113 = 7125,
  ABS229115 = 7126,
  ABS229117 = 7127,
  ABS229211 = 7128,
  ABS229311 = 7129,
  ABS229313 = 7130,
  ABS229315 = 7131,
  ABS229411 = 7132,
  ABS229413 = 7133,
  ABS229415 = 7134,
  ABS229511 = 7135,
  ABS229513 = 7136,
  ABS229911 = 7137,
  ABS229913 = 7138,
  ABS229915 = 7139,
  ABS229917 = 7140,
  ABS229919 = 7141,
  ABS229979 = 7142,
  ABS231111 = 7143,
  ABS231181 = 7144,
  ABS231211 = 7145,
  ABS231213 = 7146,
  ABS231215 = 7147,
  ABS231217 = 7148,
  ABS231219 = 7149,
  ABS231221 = 7150,
  ABS231223 = 7151,
  ABS231225 = 7152,
  ABS231227 = 7153,
  ABS231229 = 7154,
  ABS231231 = 7155,
  ABS231279 = 7156,
  ABS232111 = 7157,
  ABS232211 = 7158,
  ABS232213 = 7159,
  ABS232311 = 7160,
  ABS232411 = 7161,
  ABS232511 = 7162,
  ABS232611 = 7163,
  ABS238111 = 7164,
  ABS238113 = 7165,
  ABS238211 = 7166,
  ABS238213 = 7167,
  ABS238215 = 7168,
  ABS238311 = 7169,
  ABS238411 = 7170,
  ABS238511 = 7171,
  ABS238611 = 7172,
  ABS238711 = 7173,
  ABS238713 = 7174,
  ABS238811 = 7175,
  ABS239111 = 7176,
  ABS239113 = 7177,
  ABS239115 = 7178,
  ABS239117 = 7179,
  ABS239211 = 7180,
  ABS239311 = 7181,
  ABS239411 = 7182,
  ABS239413 = 7183,
  ABS239479 = 7184,
  ABS239911 = 7185,
  ABS239913 = 7186,
  ABS239915 = 7187,
  ABS239979 = 7188,
  ABS241111 = 7189,
  ABS241211 = 7190,
  ABS241311 = 7191,
  ABS241411 = 7192,
  ABS241413 = 7193,
  ABS241415 = 7194,
  ABS241479 = 7195,
  ABS242111 = 7196,
  ABS242113 = 7197,
  ABS242211 = 7198,
  ABS249111 = 7199,
  ABS249113 = 7200,
  ABS249115 = 7201,
  ABS249117 = 7202,
  ABS249179 = 7203,
  ABS249211 = 7204,
  ABS249311 = 7205,
  ABS251111 = 7206,
  ABS251211 = 7207,
  ABS251213 = 7208,
  ABS251311 = 7209,
  ABS251313 = 7210,
  ABS251315 = 7211,
  ABS251317 = 7212,
  ABS251319 = 7213,
  ABS251379 = 7214,
  ABS251411 = 7215,
  ABS251413 = 7216,
  ABS251415 = 7217,
  ABS251479 = 7218,
  ABS251511 = 7219,
  ABS252111 = 7220,
  ABS252113 = 7221,
  ABS252179 = 7222,
  ABS252211 = 7223,
  ABS252311 = 7224,
  ABS252911 = 7225,
  ABS252913 = 7226,
  ABS252915 = 7227,
  ABS252979 = 7228,
  ABS253111 = 7229,
  ABS253113 = 7230,
  ABS253115 = 7231,
  ABS253179 = 7232,
  ABS253211 = 7233,
  ABS253311 = 7234,
  ABS253313 = 7235,
  ABS253315 = 7236,
  ABS253317 = 7237,
  ABS253319 = 7238,
  ABS253411 = 7239,
  ABS253413 = 7240,
  ABS253415 = 7241,
  ABS253417 = 7242,
  ABS253419 = 7243,
  ABS253421 = 7244,
  ABS253479 = 7245,
  ABS253511 = 7246,
  ABS253513 = 7247,
  ABS253515 = 7248,
  ABS253611 = 7249,
  ABS253613 = 7250,
  ABS253615 = 7251,
  ABS253617 = 7252,
  ABS253619 = 7253,
  ABS253621 = 7254,
  ABS253623 = 7255,
  ABS253679 = 7256,
  ABS253711 = 7257,
  ABS253713 = 7258,
  ABS253715 = 7259,
  ABS253717 = 7260,
  ABS253779 = 7261,
  ABS253811 = 7262,
  ABS253813 = 7263,
  ABS253879 = 7264,
  ABS253911 = 7265,
  ABS253913 = 7266,
  ABS254111 = 7267,
  ABS254113 = 7268,
  ABS254115 = 7269,
  ABS254117 = 7270,
  ABS254119 = 7271,
  ABS254179 = 7272,
  ABS254211 = 7273,
  ABS254213 = 7274,
  ABS254215 = 7275,
  ABS254217 = 7276,
  ABS254219 = 7277,
  ABS254279 = 7278,
  ABS254311 = 7279,
  ABS254313 = 7280,
  ABS254911 = 7281,
  ABS254913 = 7282,
  ABS254915 = 7283,
  ABS254917 = 7284,
  ABS254919 = 7285,
  ABS254921 = 7286,
  ABS254979 = 7287,
  ABS311111 = 7288,
  ABS311179 = 7289,
  ABS311211 = 7290,
  ABS311213 = 7291,
  ABS311215 = 7292,
  ABS311217 = 7293,
  ABS311279 = 7294,
  ABS312111 = 7295,
  ABS312113 = 7296,
  ABS312115 = 7297,
  ABS312117 = 7298,
  ABS312119 = 7299,
  ABS312121 = 7300,
  ABS312211 = 7301,
  ABS312213 = 7302,
  ABS312311 = 7303,
  ABS312313 = 7304,
  ABS312411 = 7305,
  ABS312413 = 7306,
  ABS312511 = 7307,
  ABS312513 = 7308,
  ABS312911 = 7309,
  ABS312913 = 7310,
  ABS312915 = 7311,
  ABS312979 = 7312,
  ABS321111 = 7313,
  ABS321113 = 7314,
  ABS321211 = 7315,
  ABS321213 = 7316,
  ABS321215 = 7317,
  ABS321217 = 7318,
  ABS321219 = 7319,
  ABS321221 = 7320,
  ABS321279 = 7321,
  ABS321311 = 7322,
  ABS329111 = 7323,
  ABS329211 = 7324,
  ABS329311 = 7325,
  ABS329313 = 7326,
  ABS329315 = 7327,
  ABS329411 = 7328,
  ABS331111 = 7329,
  ABS332111 = 7330,
  ABS332201 = 7331,
  ABS332211 = 7332,
  ABS332311 = 7333,
  ABS332411 = 7334,
  ABS332511 = 7335,
  ABS332911 = 7336,
  ABS339111 = 7337,
  ABS339113 = 7338,
  ABS339115 = 7339,
  ABS339179 = 7340,
  ABS339211 = 7341,
  ABS339311 = 7342,
  ABS339911 = 7343,
  ABS339913 = 7344,
  ABS339915 = 7345,
  ABS339917 = 7346,
  ABS339919 = 7347,
  ABS339921 = 7348,
  ABS339923 = 7349,
  ABS339925 = 7350,
  ABS339927 = 7351,
  ABS339929 = 7352,
  ABS339979 = 7353,
  ABS341111 = 7354,
  ABS342111 = 7355,
  ABS342113 = 7356,
  ABS342115 = 7357,
  ABS342117 = 7358,
  ABS342119 = 7359,
  ABS349111 = 7360,
  ABS349113 = 7361,
  ABS349211 = 7362,
  ABS349213 = 7363,
  ABS349215 = 7364,
  ABS349311 = 7365,
  ABS349411 = 7366,
  ABS391101 = 7367,
  ABS391111 = 7368,
  ABS399111 = 7369,
  ABS399211 = 7370,
  ABS399311 = 7371,
  ABS399313 = 7372,
  ABS399315 = 7373,
  ABS399317 = 7374,
  ABS399319 = 7375,
  ABS399321 = 7376,
  ABS399323 = 7377,
  ABS399325 = 7378,
  ABS399327 = 7379,
  ABS399329 = 7380,
  ABS399331 = 7381,
  ABS399333 = 7382,
  ABS399335 = 7383,
  ABS399411 = 7384,
  ABS399511 = 7385,
  ABS399611 = 7386,
  ABS399711 = 7387,
  ABS399911 = 7388,
  ABS399913 = 7389,
  ABS399915 = 7390,
  ABS399917 = 7391,
  ABS399919 = 7392,
  ABS399979 = 7393,
  ABS411101 = 7394,
  ABS411111 = 7395,
  ABS411181 = 7396,
  ABS411201 = 7397,
  ABS411211 = 7398,
  ABS411213 = 7399,
  ABS411215 = 7400,
  ABS411281 = 7401,
  ABS411283 = 7402,
  ABS411285 = 7403,
  ABS411301 = 7404,
  ABS411311 = 7405,
  ABS411381 = 7406,
  ABS411401 = 7407,
  ABS411411 = 7408,
  ABS411413 = 7409,
  ABS411415 = 7410,
  ABS411481 = 7411,
  ABS411483 = 7412,
  ABS411485 = 7413,
  ABS411501 = 7414,
  ABS411511 = 7415,
  ABS411513 = 7416,
  ABS411515 = 7417,
  ABS411517 = 7418,
  ABS411519 = 7419,
  ABS411521 = 7420,
  ABS411581 = 7421,
  ABS412101 = 7422,
  ABS412111 = 7423,
  ABS412181 = 7424,
  ABS412201 = 7425,
  ABS412211 = 7426,
  ABS412213 = 7427,
  ABS412215 = 7428,
  ABS412281 = 7429,
  ABS412283 = 7430,
  ABS412301 = 7431,
  ABS412311 = 7432,
  ABS412313 = 7433,
  ABS412381 = 7434,
  ABS412383 = 7435,
  ABS412401 = 7436,
  ABS412411 = 7437,
  ABS412481 = 7438,
  ABS412501 = 7439,
  ABS412511 = 7440,
  ABS412581 = 7441,
  ABS412601 = 7442,
  ABS412611 = 7443,
  ABS412613 = 7444,
  ABS412681 = 7445,
  ABS412683 = 7446,
  ABS421101 = 7447,
  ABS421111 = 7448,
  ABS421181 = 7449,
  ABS421201 = 7450,
  ABS421211 = 7451,
  ABS421281 = 7452,
  ABS421301 = 7453,
  ABS421311 = 7454,
  ABS421381 = 7455,
  ABS421401 = 7456,
  ABS421411 = 7457,
  ABS421481 = 7458,
  ABS421501 = 7459,
  ABS421511 = 7460,
  ABS421581 = 7461,
  ABS421601 = 7462,
  ABS421611 = 7463,
  ABS421681 = 7464,
  ABS431101 = 7465,
  ABS431111 = 7466,
  ABS431113 = 7467,
  ABS431115 = 7468,
  ABS431181 = 7469,
  ABS431183 = 7470,
  ABS431201 = 7471,
  ABS431211 = 7472,
  ABS431281 = 7473,
  ABS431301 = 7474,
  ABS431311 = 7475,
  ABS431313 = 7476,
  ABS431381 = 7477,
  ABS431383 = 7478,
  ABS431401 = 7479,
  ABS431411 = 7480,
  ABS431413 = 7481,
  ABS431481 = 7482,
  ABS431501 = 7483,
  ABS431511 = 7484,
  ABS431513 = 7485,
  ABS431581 = 7486,
  ABS431583 = 7487,
  ABS431601 = 7488,
  ABS431611 = 7489,
  ABS431613 = 7490,
  ABS431681 = 7491,
  ABS431683 = 7492,
  ABS441101 = 7493,
  ABS441111 = 7494,
  ABS441113 = 7495,
  ABS441115 = 7496,
  ABS441181 = 7497,
  ABS441183 = 7498,
  ABS441185 = 7499,
  ABS441201 = 7500,
  ABS441211 = 7501,
  ABS441281 = 7502,
  ABS441301 = 7503,
  ABS441311 = 7504,
  ABS441381 = 7505,
  ABS441401 = 7506,
  ABS441411 = 7507,
  ABS441481 = 7508,
  ABS441501 = 7509,
  ABS441511 = 7510,
  ABS441581 = 7511,
  ABS441601 = 7512,
  ABS441611 = 7513,
  ABS441613 = 7514,
  ABS441681 = 7515,
  ABS441683 = 7516,
  ABS442101 = 7517,
  ABS442111 = 7518,
  ABS442181 = 7519,
  ABS442201 = 7520,
  ABS442211 = 7521,
  ABS442281 = 7522,
  ABS442301 = 7523,
  ABS442311 = 7524,
  ABS442381 = 7525,
  ABS443101 = 7526,
  ABS443111 = 7527,
  ABS443113 = 7528,
  ABS443115 = 7529,
  ABS443117 = 7530,
  ABS443119 = 7531,
  ABS443181 = 7532,
  ABS443183 = 7533,
  ABS443185 = 7534,
  ABS443187 = 7535,
  ABS443189 = 7536,
  ABS451101 = 7537,
  ABS451111 = 7538,
  ABS451113 = 7539,
  ABS451115 = 7540,
  ABS451181 = 7541,
  ABS451183 = 7542,
  ABS451185 = 7543,
  ABS451201 = 7544,
  ABS451211 = 7545,
  ABS451213 = 7546,
  ABS451281 = 7547,
  ABS451283 = 7548,
  ABS451311 = 7549,
  ABS451381 = 7550,
  ABS451911 = 7551,
  ABS451913 = 7552,
  ABS451915 = 7553,
  ABS451981 = 7554,
  ABS451983 = 7555,
  ABS451985 = 7556,
  ABS461111 = 7557,
  ABS461211 = 7558,
  ABS461311 = 7559,
  ABS461313 = 7560,
  ABS461411 = 7561,
  ABS461479 = 7562,
  ABS462111 = 7563,
  ABS462181 = 7564,
  ABS462211 = 7565,
  ABS462281 = 7566,
  ABS462301 = 7567,
  ABS462311 = 7568,
  ABS462313 = 7569,
  ABS462315 = 7570,
  ABS462381 = 7571,
  ABS462383 = 7572,
  ABS462385 = 7573,
  ABS491111 = 7574,
  ABS491181 = 7575,
  ABS491211 = 7576,
  ABS491213 = 7577,
  ABS491281 = 7578,
  ABS491283 = 7579,
  ABS491311 = 7580,
  ABS491381 = 7581,
  ABS491411 = 7582,
  ABS491481 = 7583,
  ABS492111 = 7584,
  ABS492113 = 7585,
  ABS492181 = 7586,
  ABS492183 = 7587,
  ABS492201 = 7588,
  ABS492211 = 7589,
  ABS492281 = 7590,
  ABS492911 = 7591,
  ABS492913 = 7592,
  ABS492979 = 7593,
  ABS492981 = 7594,
  ABS492983 = 7595,
  ABS492999 = 7596,
  ABS493101 = 7597,
  ABS493111 = 7598,
  ABS493181 = 7599,
  ABS494111 = 7600,
  ABS494113 = 7601,
  ABS494115 = 7602,
  ABS494117 = 7603,
  ABS494119 = 7604,
  ABS494179 = 7605,
  ABS494181 = 7606,
  ABS494211 = 7607,
  ABS494279 = 7608,
  ABS494281 = 7609,
  ABS494311 = 7610,
  ABS494313 = 7611,
  ABS494381 = 7612,
  ABS494411 = 7613,
  ABS494413 = 7614,
  ABS494415 = 7615,
  ABS494481 = 7616,
  ABS498111 = 7617,
  ABS498113 = 7618,
  ABS498181 = 7619,
  ABS498183 = 7620,
  ABS498211 = 7621,
  ABS498213 = 7622,
  ABS498281 = 7623,
  ABS498283 = 7624,
  ABS498311 = 7625,
  ABS498313 = 7626,
  ABS498381 = 7627,
  ABS498383 = 7628,
  ABS498411 = 7629,
  ABS498511 = 7630,
  ABS498601 = 7631,
  ABS498611 = 7632,
  ABS498701 = 7633,
  ABS498711 = 7634,
  ABS498713 = 7635,
  ABS498801 = 7636,
  ABS498811 = 7637,
  ABS499111 = 7638,
  ABS499181 = 7639,
  ABS499211 = 7640,
  ABS499213 = 7641,
  ABS499215 = 7642,
  ABS499217 = 7643,
  ABS499219 = 7644,
  ABS499221 = 7645,
  ABS499223 = 7646,
  ABS499225 = 7647,
  ABS499227 = 7648,
  ABS499279 = 7649,
  ABS499911 = 7650,
  ABS499913 = 7651,
  ABS499915 = 7652,
  ABS499917 = 7653,
  ABS499979 = 7654,
  ABS511111 = 7655,
  ABS511113 = 7656,
  ABS591111 = 7657,
  ABS591211 = 7658,
  ABS599111 = 7659,
  ABS599113 = 7660,
  ABS599115 = 7661,
  ABS599211 = 7662,
  ABS599311 = 7663,
  ABS599411 = 7664,
  ABS599413 = 7665,
  ABS599415 = 7666,
  ABS599511 = 7667,
  ABS599611 = 7668,
  ABS599613 = 7669,
  ABS599679 = 7670,
  ABS599911 = 7671,
  ABS599913 = 7672,
  ABS599915 = 7673,
  ABS599917 = 7674,
  ABS611111 = 7675,
  ABS612111 = 7676,
  ABS612113 = 7677,
  ABS613111 = 7678,
  ABS614101 = 7679,
  ABS614111 = 7680,
  ABS614113 = 7681,
  ABS614115 = 7682,
  ABS614211 = 7683,
  ABS614301 = 7684,
  ABS614311 = 7685,
  ABS614401 = 7686,
  ABS614411 = 7687,
  ABS614501 = 7688,
  ABS614511 = 7689,
  ABS614513 = 7690,
  ABS615111 = 7691,
  ABS615201 = 7692,
  ABS615211 = 7693,
  ABS615213 = 7694,
  ABS615301 = 7695,
  ABS615311 = 7696,
  ABS615313 = 7697,
  ABS615315 = 7698,
  ABS615317 = 7699,
  ABS619111 = 7700,
  ABS619113 = 7701,
  ABS619211 = 7702,
  ABS619311 = 7703,
  ABS619313 = 7704,
  ABS619411 = 7705,
  ABS619413 = 7706,
  ABS619415 = 7707,
  ABS619417 = 7708,
  ABS619419 = 7709,
  ABS619421 = 7710,
  ABS619423 = 7711,
  ABS619479 = 7712,
  ABS619911 = 7713,
  ABS619913 = 7714,
  ABS619915 = 7715,
  ABS619917 = 7716,
  ABS619919 = 7717,
  ABS619921 = 7718,
  ABS619979 = 7719,
  ABS621111 = 7720,
  ABS621113 = 7721,
  ABS621115 = 7722,
  ABS621117 = 7723,
  ABS621179 = 7724,
  ABS621211 = 7725,
  ABS621213 = 7726,
  ABS621311 = 7727,
  ABS621313 = 7728,
  ABS631111 = 7729,
  ABS631113 = 7730,
  ABS631115 = 7731,
  ABS631117 = 7732,
  ABS631211 = 7733,
  ABS631213 = 7734,
  ABS631215 = 7735,
  ABS631311 = 7736,
  ABS631313 = 7737,
  ABS631315 = 7738,
  ABS631317 = 7739,
  ABS631319 = 7740,
  ABS631411 = 7741,
  ABS631413 = 7742,
  ABS632111 = 7743,
  ABS632201 = 7744,
  ABS632211 = 7745,
  ABS632301 = 7746,
  ABS632311 = 7747,
  ABS632313 = 7748,
  ABS632411 = 7749,
  ABS639111 = 7750,
  ABS639211 = 7751,
  ABS639311 = 7752,
  ABS639411 = 7753,
  ABS639413 = 7754,
  ABS639415 = 7755,
  ABS639511 = 7756,
  ABS639513 = 7757,
  ABS639515 = 7758,
  ABS639611 = 7759,
  ABS639613 = 7760,
  ABS639711 = 7761,
  ABS639713 = 7762,
  ABS639715 = 7763,
  ABS639911 = 7764,
  ABS639913 = 7765,
  ABS639915 = 7766,
  ABS639917 = 7767,
  ABS639979 = 7768,
  ABS711111 = 7769,
  ABS711113 = 7770,
  ABS711115 = 7771,
  ABS711117 = 7772,
  ABS711119 = 7773,
  ABS711121 = 7774,
  ABS711123 = 7775,
  ABS711125 = 7776,
  ABS711211 = 7777,
  ABS711911 = 7778,
  ABS711913 = 7779,
  ABS711915 = 7780,
  ABS711917 = 7781,
  ABS711979 = 7782,
  ABS712111 = 7783,
  ABS712211 = 7784,
  ABS712301 = 7785,
  ABS712311 = 7786,
  ABS712411 = 7787,
  ABS712413 = 7788,
  ABS712901 = 7789,
  ABS712911 = 7790,
  ABS712913 = 7791,
  ABS712915 = 7792,
  ABS712917 = 7793,
  ABS712919 = 7794,
  ABS712921 = 7795,
  ABS712923 = 7796,
  ABS712979 = 7797,
  ABS721101 = 7798,
  ABS721111 = 7799,
  ABS721201 = 7800,
  ABS721211 = 7801,
  ABS721213 = 7802,
  ABS721215 = 7803,
  ABS721217 = 7804,
  ABS721219 = 7805,
  ABS721221 = 7806,
  ABS721279 = 7807,
  ABS729101 = 7808,
  ABS729111 = 7809,
  ABS729113 = 7810,
  ABS729115 = 7811,
  ABS729117 = 7812,
  ABS729179 = 7813,
  ABS729201 = 7814,
  ABS729211 = 7815,
  ABS729279 = 7816,
  ABS729301 = 7817,
  ABS729311 = 7818,
  ABS729401 = 7819,
  ABS729411 = 7820,
  ABS729511 = 7821,
  ABS729601 = 7822,
  ABS729611 = 7823,
  ABS729701 = 7824,
  ABS729711 = 7825,
  ABS729713 = 7826,
  ABS729715 = 7827,
  ABS729779 = 7828,
  ABS729811 = 7829,
  ABS729911 = 7830,
  ABS729979 = 7831,
  ABS731111 = 7832,
  ABS731113 = 7833,
  ABS731211 = 7834,
  ABS731213 = 7835,
  ABS731311 = 7836,
  ABS731411 = 7837,
  ABS731511 = 7838,
  ABS731513 = 7839,
  ABS791111 = 7840,
  ABS791211 = 7841,
  ABS791311 = 7842,
  ABS791313 = 7843,
  ABS791315 = 7844,
  ABS791317 = 7845,
  ABS791411 = 7846,
  ABS791413 = 7847,
  ABS799111 = 7848,
  ABS799211 = 7849,
  ABS799213 = 7850,
  ABS799215 = 7851,
  ABS799301 = 7852,
  ABS799311 = 7853,
  ABS799411 = 7854,
  ABS799413 = 7855,
  ABS799501 = 7856,
  ABS799511 = 7857,
  ABS799513 = 7858,
  ABS799515 = 7859,
  ABS799611 = 7860,
  ABS799613 = 7861,
  ABS811111 = 7862,
  ABS811211 = 7863,
  ABS811213 = 7864,
  ABS811311 = 7865,
  ABS811411 = 7866,
  ABS811413 = 7867,
  ABS811511 = 7868,
  ABS811513 = 7869,
  ABS811515 = 7870,
  ABS811579 = 7871,
  ABS811611 = 7872,
  ABS811911 = 7873,
  ABS811913 = 7874,
  ABS811915 = 7875,
  ABS811917 = 7876,
  ABS811979 = 7877,
  ABS821111 = 7878,
  ABS821113 = 7879,
  ABS821115 = 7880,
  ABS821117 = 7881,
  ABS821179 = 7882,
  ABS829111 = 7883,
  ABS829113 = 7884,
  ABS829211 = 7885,
  ABS829213 = 7886,
  ABS829311 = 7887,
  ABS829313 = 7888,
  ABS829315 = 7889,
  ABS829411 = 7890,
  ABS829511 = 7891,
  ABS829513 = 7892,
  ABS829611 = 7893,
  ABS829711 = 7894,
  ABS829911 = 7895,
  ABS829913 = 7896,
  ABS829979 = 7897,
  ABS831111 = 7898,
  ABS831113 = 7899,
  ABS831179 = 7900,
  ABS831211 = 7901,
  ABS831213 = 7902,
  ABS831311 = 7903,
  ABS831411 = 7904,
  ABS831511 = 7905,
  ABS831911 = 7906,
  ABS831913 = 7907,
  ABS831915 = 7908,
  ABS831917 = 7909,
  ABS831919 = 7910,
  ABS831921 = 7911,
  ABS831923 = 7912,
  ABS831925 = 7913,
  ABS831927 = 7914,
  ABS831979 = 7915,
  ABS911111 = 7916,
  ABS911113 = 7917,
  ABS911115 = 7918,
  ABS911117 = 7919,
  ABS911119 = 7920,
  ABS911179 = 7921,
  ABS921111 = 7922,
  ABS921211 = 7923,
  ABS921311 = 7924,
  ABS921313 = 7925,
  ABS921315 = 7926,
  ABS921317 = 7927,
  ABS921411 = 7928,
  ABS921413 = 7929,
  ABS921415 = 7930,
  ABS921417 = 7931,
  ABS921419 = 7932,
  ABS921479 = 7933,
  ABS921511 = 7934,
  ABS921513 = 7935,
  ABS921515 = 7936,
  ABS921517 = 7937,
  ABS921911 = 7938,
  ABS921913 = 7939,
  ABS921915 = 7940,
  ABS921917 = 7941,
  ABS921919 = 7942,
  ABS921921 = 7943,
  ABS921923 = 7944,
  ABS921925 = 7945,
  ABS921979 = 7946,
  ABS922111 = 7947,
  ABS922211 = 7948,
  ABS991111 = 7949,
  ABS991113 = 7950,
  ABS991211 = 7951,
  ABS991311 = 7952,
  ABS991411 = 7953,
  ABS991511 = 7954,
  ABS991611 = 7955,
  ABS991613 = 7956,
  ABS991711 = 7957,
  ABS991811 = 7958,
  ABS991911 = 7959,
  ABS991913 = 7960,
  ABS991915 = 7961,
  ABS992111 = 7962,
  ABS992113 = 7963,
  ABS992115 = 7964,
  ABS992117 = 7965,
  ABS992179 = 7966,
  ABS992211 = 7967,
  ABS992213 = 7968,
  ABS992911 = 7969,
  ABS992913 = 7970,
  ABS992979 = 7971,
  ABS993111 = 7972,
  ABS993211 = 7973,
  ABS993311 = 7974,
  ABS993379 = 7975,
  ABS999111 = 7976,
  ABS999211 = 7977,
  ABS999213 = 7978,
  ABS999215 = 7979,
  ABS999311 = 7980,
  ABS999911 = 7981,
  ABS999913 = 7982,
  ABS999915 = 7983,
  ABS999917 = 7984,
  ABS999979 = 7985,
  HEMYes = 9000,
  HEMNo = 9001,
  CapitalizedToPropertyValueYes = 13000,
  CapitalizedToPropertyValueNo = 13001,
  Residential = 14000,
  Industrial = 14001,
  Commercial = 14002,
  Rural = 14003,
  NRASYes = 15000,
  NRASNo = 15001,
  Australia = 16001,
  NewZealand = 16002,
  Other = 16003,
  InvestmentCostsCompareToCapitalizedRentalIncomeYes = 18000,
  InvestmentCostsCompareToCapitalizedRentalIncomeNo = 18001,
  ShortStayIncomeYes = 19000,
  ShortStayIncomeNo = 19001,
  IncomeRecent = 24000,
  IncomePrevious = 24001,
  IncomePrior = 24002,
  IncomeRecentAndPrevious = 24003,
  IncomePreviousAndPrior = 24004,
  IncomeAny = 24005,
  //IncomeYTD = 24006,
  //IncomeYTDAndRecent = 24007,
  AgedCareUnit = 25000,
  ApartmentUnitFlat = 25001,
  BedsitterBachelor = 25002,
  CompanyTitleUnit = 25003,
  ConvertedCommercialProperty = 25004,
  ConvertedIndustrialProperty = 25005,
  ConvertedMotelUnits = 25006,
  DisplayHouse = 25007,
  Duplex = 25008,
  FullyDetachedHouse = 25009,
  HighDensityApartment = 25010,
  HolidayHome = 25011,
  HolidayRental = 25012,
  ImprovedLand = 25013,
  KitHome = 25014,
  LowDensityApartment = 25015,
  LuxuryHouse = 25016,
  LuxuryOther = 25017,
  MediumDensityApartment = 25018,
  PropertyDevelopment = 25019,
  RelocatableHome = 25020,
  ResortUnit = 25021,
  SemiDetachedHouse = 25022,
  ServicedApartment = 25023,
  StrataHotelUnit = 25024,
  StrataTitleUnit = 25025,
  StudioWarehouseApartment = 25026,
  Terrace = 25027,
  Townhouse = 25028,
  UnitStudentAccommodation = 25029,
  VacantLand = 25030,
  Villa = 25031,
  SingleUnitYes = 26000,
  SingleUnitNo = 26001,
  Boarding = 28000,
  Caravan = 28001,
  OtherHousing = 28002,
  OwnHome = 28003,
  OwnHomeMortgage = 28004,
  Renting = 28005,
  SuppliedbyEmployer = 28006,
  WithParents = 28007,
  SpecialDisabilityAccommodationYes = 29000,
  SpecialDisabilityAccommodationNo = 29001,
  NDISYes = 30000,
  NDISNo = 30001,
  PropertyPurposeBusiness = 31000,
  PropertyPurposeInvestment = 31001,
  PropertyPurposeOwnerOccupied = 31002,
  HolidayRentalYes = 32000,
  HolidayRentalNo = 32001,
  PaidInFullYes = 33000,
  PaidInFullNo = 33001,
  HasSplitLoansYes = 34000,
  HasSplitLoansNo = 34001,
  Company = 35000,
  Partnership = 35001,
  SoleTrader = 35002,
  Trust = 35003,
  OriginalTermFixedInterestType = 38000,
  OriginalTermVariableInterestType = 38001,
  FullDoc = 39000,
  LoDoc = 39001,
  NoDoc = 39002,
  AltDoc = 39003,
}

export const ServiceabilityFeatureOptionLabel = new Map<number, string>([
  [ServiceabilityFeatureOption.Preapproval, 'Preapproval'],
  [ServiceabilityFeatureOption.Loan, 'Loan'],
  [ServiceabilityFeatureOption.LoanVariation, 'Loan Variation'],
  [ServiceabilityFeatureOption.FullTime, 'Full Time'],
  [ServiceabilityFeatureOption.PartTime, 'Part Time'],
  [ServiceabilityFeatureOption.Contract, 'Contract'],
  [ServiceabilityFeatureOption.Temporary, 'Temporary'],
  [ServiceabilityFeatureOption.Casual, 'Casual'],
  [ServiceabilityFeatureOption.CommissionOnly, 'Commission Only'],
  [ServiceabilityFeatureOption.Seasonal, 'Seasonal'],
  [ServiceabilityFeatureOption.Primary, 'Primary'],
  [ServiceabilityFeatureOption.Secondary, 'Secondary'],
  [ServiceabilityFeatureOption.OnProbationYes, 'Yes'],
  [ServiceabilityFeatureOption.OnProbationNo, 'No'],
  [ServiceabilityFeatureOption.TemporarilyInAustralia, 'Temporarily in Australia'],
  [ServiceabilityFeatureOption.PermanentlyInAustralia, 'Permanently in Australia'],
  [ServiceabilityFeatureOption.TemporarilyInNZ, 'Temporarily in NZ'],
  [ServiceabilityFeatureOption.PermanentlyInNZ, 'Permanently in NZ'],
  [ServiceabilityFeatureOption.NonResident, 'Non Resident'],
  [ServiceabilityFeatureOption.ABS111111, 'ABS 1111-11 Parliamentarian or Councillor'],
  [ServiceabilityFeatureOption.ABS111113, 'ABS 1111-13 Judge'],
  [ServiceabilityFeatureOption.ABS111115, 'ABS 1111-15 Magistrate'],
  [ServiceabilityFeatureOption.ABS111117, 'ABS 1111-17 Tribunal Member'],
  [ServiceabilityFeatureOption.ABS111179, 'ABS 1111-79 Legislators and Government Appointed Officials nec'],
  [ServiceabilityFeatureOption.ABS111211, 'ABS 1112-11 General Manager'],
  [ServiceabilityFeatureOption.ABS119111, 'ABS 1191-11 Construction Project Manager'],
  [ServiceabilityFeatureOption.ABS119113, 'ABS 1191-13 Project Builder'],
  [ServiceabilityFeatureOption.ABS119211, 'ABS 1192-11 Importer or Exporter'],
  [ServiceabilityFeatureOption.ABS119213, 'ABS 1192-13 Wholesaler'],
  [ServiceabilityFeatureOption.ABS119311, 'ABS 1193-11 Manufacturer'],
  [ServiceabilityFeatureOption.ABS121111, 'ABS 1211-11 Finance Manager'],
  [ServiceabilityFeatureOption.ABS121211, 'ABS 1212-11 Company Secretary'],
  [ServiceabilityFeatureOption.ABS121311, 'ABS 1213-11 Human Resource Manager'],
  [ServiceabilityFeatureOption.ABS122111, 'ABS 1221-11 Engineering Manager'],
  [ServiceabilityFeatureOption.ABS122211, 'ABS 1222-11 Production Manager (Manufacturing)'],
  [ServiceabilityFeatureOption.ABS122213, 'ABS 1222-13 Production Manager (Mining)'],
  [ServiceabilityFeatureOption.ABS122311, 'ABS 1223-11 Supply and Distribution Manager'],
  [ServiceabilityFeatureOption.ABS122411, 'ABS 1224-11 Information Technology Manager'],
  [ServiceabilityFeatureOption.ABS123111, 'ABS 1231-11 Sales and Marketing Manager'],
  [ServiceabilityFeatureOption.ABS129111, 'ABS 1291-11 Policy and Planning Manager'],
  [ServiceabilityFeatureOption.ABS129211, 'ABS 1292-11 Director of Nursing'],
  [ServiceabilityFeatureOption.ABS129213, 'ABS 1292-13 Medical Administrator'],
  [ServiceabilityFeatureOption.ABS129311, 'ABS 1293-11 School Principal'],
  [ServiceabilityFeatureOption.ABS129313, 'ABS 1293-13 Faculty Head'],
  [ServiceabilityFeatureOption.ABS129315, 'ABS 1293-15 Regional Education Manager'],
  [ServiceabilityFeatureOption.ABS129379, 'ABS 1293-79 Education Managers nec'],
  [ServiceabilityFeatureOption.ABS129411, 'ABS 1294-11 Commissioned Defence Force Officer'],
  [ServiceabilityFeatureOption.ABS129413, 'ABS 1294-13 Commissioned Fire Officer'],
  [ServiceabilityFeatureOption.ABS129415, 'ABS 1294-15 Commissioned Police Officer'],
  [ServiceabilityFeatureOption.ABS129481, 'ABS 1294-81 Trainee Commissioned Defence Force Officer '],
  [ServiceabilityFeatureOption.ABS129511, 'ABS 1295-11 Child Care Co-ordinator'],
  [ServiceabilityFeatureOption.ABS129611, 'ABS 1296-11 Media Producer'],
  [ServiceabilityFeatureOption.ABS129613, 'ABS 1296-13 Artistic Director'],
  [ServiceabilityFeatureOption.ABS129911, 'ABS 1299-11 Research and Development Manager'],
  [ServiceabilityFeatureOption.ABS129913, 'ABS 1299-13 Laboratory Manager'],
  [ServiceabilityFeatureOption.ABS129915, 'ABS 1299-15 Welfare Centre Manager'],
  [ServiceabilityFeatureOption.ABS129917, 'ABS 1299-17 Environment, Parks and Land Care Manager'],
  [ServiceabilityFeatureOption.ABS129919, 'ABS 1299-19 Sports Administrator'],
  [ServiceabilityFeatureOption.ABS129979, 'ABS 1299-79 Specialist Managers nec'],
  [ServiceabilityFeatureOption.ABS131111, 'ABS 1311-11 Mixed Crop and Livestock Farmer'],
  [ServiceabilityFeatureOption.ABS131211, 'ABS 1312-11 Mixed Livestock Farmer'],
  [ServiceabilityFeatureOption.ABS131213, 'ABS 1312-13 Beef Cattle Farmer'],
  [ServiceabilityFeatureOption.ABS131215, 'ABS 1312-15 Dairy Farmer'],
  [ServiceabilityFeatureOption.ABS131217, 'ABS 1312-17 Sheep Farmer'],
  [ServiceabilityFeatureOption.ABS131219, 'ABS 1312-19 Pig Farmer'],
  [ServiceabilityFeatureOption.ABS131221, 'ABS 1312-21 Poultry Farmer'],
  [ServiceabilityFeatureOption.ABS131223, 'ABS 1312-23 Horse Breeder'],
  [ServiceabilityFeatureOption.ABS131225, 'ABS 1312-25 Apiarist'],
  [ServiceabilityFeatureOption.ABS131279, 'ABS 1312-79 Livestock Farmers nec'],
  [ServiceabilityFeatureOption.ABS131311, 'ABS 1313-11 Grain, Oilseed and Pasture Grower'],
  [ServiceabilityFeatureOption.ABS131313, 'ABS 1313-13 Sugar Cane Grower'],
  [ServiceabilityFeatureOption.ABS131315, 'ABS 1313-15 Tobacco Grower'],
  [ServiceabilityFeatureOption.ABS131317, 'ABS 1313-17 Fruit and Nut Grower'],
  [ServiceabilityFeatureOption.ABS131319, 'ABS 1313-19 Vegetable Grower'],
  [ServiceabilityFeatureOption.ABS131321, 'ABS 1313-21 Flower Grower'],
  [ServiceabilityFeatureOption.ABS131379, 'ABS 1313-79 Crop Farmers nec'],
  [ServiceabilityFeatureOption.ABS131411, 'ABS 1314-11 Aquaculture Farmer'],
  [ServiceabilityFeatureOption.ABS211111, 'ABS 2111-11 Chemist'],
  [ServiceabilityFeatureOption.ABS211211, 'ABS 2112-11 Geologist'],
  [ServiceabilityFeatureOption.ABS211213, 'ABS 2112-13 Geophysicist'],
  [ServiceabilityFeatureOption.ABS211311, 'ABS 2113-11 Anatomist or Physiologist'],
  [ServiceabilityFeatureOption.ABS211313, 'ABS 2113-13 Botanist'],
  [ServiceabilityFeatureOption.ABS211315, 'ABS 2113-15 Zoologist'],
  [ServiceabilityFeatureOption.ABS211317, 'ABS 2113-17 Biochemist'],
  [ServiceabilityFeatureOption.ABS211319, 'ABS 2113-19 Marine Biologist'],
  [ServiceabilityFeatureOption.ABS211379, 'ABS 2113-79 Life Scientists nec'],
  [ServiceabilityFeatureOption.ABS211411, 'ABS 2114-11 Environmental Research Scientist'],
  [ServiceabilityFeatureOption.ABS211413, 'ABS 2114-13 Forester'],
  [ServiceabilityFeatureOption.ABS211415, 'ABS 2114-15 Park Ranger'],
  [ServiceabilityFeatureOption.ABS211417, 'ABS 2114-17 Soil Scientist'],
  [ServiceabilityFeatureOption.ABS211419, 'ABS 2114-19 Agricultural Scientist'],
  [ServiceabilityFeatureOption.ABS211421, 'ABS 2114-21 Agricultural Adviser'],
  [ServiceabilityFeatureOption.ABS211479, 'ABS 2114-79 Environmental and Agricultural Science Professionals nec'],
  [ServiceabilityFeatureOption.ABS211511, 'ABS 2115-11 Medical Scientist'],
  [ServiceabilityFeatureOption.ABS211911, 'ABS 2119-11 Physicist'],
  [ServiceabilityFeatureOption.ABS211913, 'ABS 2119-13 Meteorologist'],
  [ServiceabilityFeatureOption.ABS211915, 'ABS 2119-15 Extractive Metallurgist'],
  [ServiceabilityFeatureOption.ABS211917, 'ABS 2119-17 Physical Metallurgist'],
  [ServiceabilityFeatureOption.ABS211919, 'ABS 2119-19 Materials Scientist'],
  [ServiceabilityFeatureOption.ABS211979, 'ABS 2119-79 Natural and Physical Science Professionals nec'],
  [ServiceabilityFeatureOption.ABS212111, 'ABS 2121-11 Architect'],
  [ServiceabilityFeatureOption.ABS212113, 'ABS 2121-13 Landscape Architect'],
  [ServiceabilityFeatureOption.ABS212211, 'ABS 2122-11 Quantity Surveyor'],
  [ServiceabilityFeatureOption.ABS212311, 'ABS 2123-11 Cartographer'],
  [ServiceabilityFeatureOption.ABS212313, 'ABS 2123-13 Surveyor'],
  [ServiceabilityFeatureOption.ABS212411, 'ABS 2124-11 Civil Engineer'],
  [ServiceabilityFeatureOption.ABS212511, 'ABS 2125-11 Electrical Engineer'],
  [ServiceabilityFeatureOption.ABS212513, 'ABS 2125-13 Electronics Engineer'],
  [ServiceabilityFeatureOption.ABS212611, 'ABS 2126-11 Mechanical Engineer'],
  [ServiceabilityFeatureOption.ABS212613, 'ABS 2126-13 Production or Plant Engineer'],
  [ServiceabilityFeatureOption.ABS212711, 'ABS 2127-11 Mining Engineer (excluding Petroleum)'],
  [ServiceabilityFeatureOption.ABS212713, 'ABS 2127-13 Petroleum Engineer'],
  [ServiceabilityFeatureOption.ABS212715, 'ABS 2127-15 Materials Engineer'],
  [ServiceabilityFeatureOption.ABS212811, 'ABS 2128-11 Civil Engineering Technologist'],
  [ServiceabilityFeatureOption.ABS212813, 'ABS 2128-13 Mechanical Engineering Technologist'],
  [ServiceabilityFeatureOption.ABS212815, 'ABS 2128-15 Electrical or Electronics Engineering Technologist'],
  [ServiceabilityFeatureOption.ABS212879, 'ABS 2128-79 Engineering Technologists nec'],
  [ServiceabilityFeatureOption.ABS212911, 'ABS 2129-11 Aeronautical Engineer'],
  [ServiceabilityFeatureOption.ABS212913, 'ABS 2129-13 Agricultural Engineer'],
  [ServiceabilityFeatureOption.ABS212915, 'ABS 2129-15 Biomedical Engineer'],
  [ServiceabilityFeatureOption.ABS212917, 'ABS 2129-17 Chemical Engineer'],
  [ServiceabilityFeatureOption.ABS212919, 'ABS 2129-19 Industrial Engineer'],
  [ServiceabilityFeatureOption.ABS212921, 'ABS 2129-21 Naval Architect'],
  [ServiceabilityFeatureOption.ABS212979, 'ABS 2129-79 Building and Engineering Professionals nec'],
  [ServiceabilityFeatureOption.ABS221111, 'ABS 2211-11 Accountant'],
  [ServiceabilityFeatureOption.ABS221211, 'ABS 2212-11 External Auditor'],
  [ServiceabilityFeatureOption.ABS221213, 'ABS 2212-13 Internal Auditor'],
  [ServiceabilityFeatureOption.ABS221311, 'ABS 2213-11 Corporate Treasurer'],
  [ServiceabilityFeatureOption.ABS222111, 'ABS 2221-11 Public Relations Officer'],
  [ServiceabilityFeatureOption.ABS222113, 'ABS 2221-13 Marketing Specialist'],
  [ServiceabilityFeatureOption.ABS222115, 'ABS 2221-15 Market Research Analyst'],
  [ServiceabilityFeatureOption.ABS222117, 'ABS 2221-17 Advertising Specialist'],
  [ServiceabilityFeatureOption.ABS222211, 'ABS 2222-11 Sales Representative (Industrial Products)'],
  [ServiceabilityFeatureOption.ABS222213, 'ABS 2222-13 Sales Representative (Information and Communication Products)'],
  [ServiceabilityFeatureOption.ABS222215, 'ABS 2222-15 Sales Representative (Medical and Pharmaceutical Products)'],
  [ServiceabilityFeatureOption.ABS222279, 'ABS 2222-79 Technical Sales Representatives nec'],
  [ServiceabilityFeatureOption.ABS223111, 'ABS 2231-11 Systems Manager'],
  [ServiceabilityFeatureOption.ABS223113, 'ABS 2231-13 Systems Designer'],
  [ServiceabilityFeatureOption.ABS223115, 'ABS 2231-15 Software Designer'],
  [ServiceabilityFeatureOption.ABS223117, 'ABS 2231-17 Applications and Analyst Programmer'],
  [ServiceabilityFeatureOption.ABS223119, 'ABS 2231-19 Systems Programmer'],
  [ServiceabilityFeatureOption.ABS223121, 'ABS 2231-21 Computer Systems Auditor'],
  [ServiceabilityFeatureOption.ABS223179, 'ABS 2231-79 Computing Professionals nec'],
  [ServiceabilityFeatureOption.ABS229111, 'ABS 2291-11 Personnel Officer'],
  [ServiceabilityFeatureOption.ABS229113, 'ABS 2291-13 Personnel Consultant'],
  [ServiceabilityFeatureOption.ABS229115, 'ABS 2291-15 Industrial Relations Officer'],
  [ServiceabilityFeatureOption.ABS229117, 'ABS 2291-17 Training Officer'],
  [ServiceabilityFeatureOption.ABS229211, 'ABS 2292-11 Librarian'],
  [ServiceabilityFeatureOption.ABS229311, 'ABS 2293-11 Mathematician'],
  [ServiceabilityFeatureOption.ABS229313, 'ABS 2293-13 Statistician'],
  [ServiceabilityFeatureOption.ABS229315, 'ABS 2293-15 Actuary'],
  [ServiceabilityFeatureOption.ABS229411, 'ABS 2294-11 Management Consultant'],
  [ServiceabilityFeatureOption.ABS229413, 'ABS 2294-13 Organisation and Methods Analyst'],
  [ServiceabilityFeatureOption.ABS229415, 'ABS 2294-15 Quality Assurance Manager'],
  [ServiceabilityFeatureOption.ABS229511, 'ABS 2295-11 Valuer'],
  [ServiceabilityFeatureOption.ABS229513, 'ABS 2295-13 Land Economist'],
  [ServiceabilityFeatureOption.ABS229911, 'ABS 2299-11 Health Information Manager'],
  [ServiceabilityFeatureOption.ABS229913, 'ABS 2299-13 Records Manager'],
  [ServiceabilityFeatureOption.ABS229915, 'ABS 2299-15 Archivist'],
  [ServiceabilityFeatureOption.ABS229917, 'ABS 2299-17 Policy Analyst'],
  [ServiceabilityFeatureOption.ABS229919, 'ABS 2299-19 Intelligence Officer'],
  [ServiceabilityFeatureOption.ABS229979, 'ABS 2299-79 Business and Information Professionals nec'],
  [ServiceabilityFeatureOption.ABS231111, 'ABS 2311-11 General Medical Practitioner'],
  [ServiceabilityFeatureOption.ABS231181, 'ABS 2311-81 Medical Practitioner in Training'],
  [ServiceabilityFeatureOption.ABS231211, 'ABS 2312-11 Anaesthetist'],
  [ServiceabilityFeatureOption.ABS231213, 'ABS 2312-13 Dermatologist'],
  [ServiceabilityFeatureOption.ABS231215, 'ABS 2312-15 Emergency Medicine Specialist'],
  [ServiceabilityFeatureOption.ABS231217, 'ABS 2312-17 Obstetrician and Gynaecologist'],
  [ServiceabilityFeatureOption.ABS231219, 'ABS 2312-19 Ophthalmologist'],
  [ServiceabilityFeatureOption.ABS231221, 'ABS 2312-21 Paediatrician'],
  [ServiceabilityFeatureOption.ABS231223, 'ABS 2312-23 Pathologist'],
  [ServiceabilityFeatureOption.ABS231225, 'ABS 2312-25 Specialist Physician'],
  [ServiceabilityFeatureOption.ABS231227, 'ABS 2312-27 Psychiatrist'],
  [ServiceabilityFeatureOption.ABS231229, 'ABS 2312-29 Radiologist'],
  [ServiceabilityFeatureOption.ABS231231, 'ABS 2312-31 Surgeon'],
  [ServiceabilityFeatureOption.ABS231279, 'ABS 2312-79 Specialist Medical Practitioners nec'],
  [ServiceabilityFeatureOption.ABS232111, 'ABS 2321-11 Nurse Manager'],
  [ServiceabilityFeatureOption.ABS232211, 'ABS 2322-11 Nurse Educator'],
  [ServiceabilityFeatureOption.ABS232213, 'ABS 2322-13 Nurse Researcher'],
  [ServiceabilityFeatureOption.ABS232311, 'ABS 2323-11 Registered Nurse'],
  [ServiceabilityFeatureOption.ABS232411, 'ABS 2324-11 Registered Midwife'],
  [ServiceabilityFeatureOption.ABS232511, 'ABS 2325-11 Registered Mental Health Nurse'],
  [ServiceabilityFeatureOption.ABS232611, 'ABS 2326-11 Registered Developmental Disability Nurse'],
  [ServiceabilityFeatureOption.ABS238111, 'ABS 2381-11 Dentist'],
  [ServiceabilityFeatureOption.ABS238113, 'ABS 2381-13 Dental Specialist'],
  [ServiceabilityFeatureOption.ABS238211, 'ABS 2382-11 Hospital Pharmacist'],
  [ServiceabilityFeatureOption.ABS238213, 'ABS 2382-13 Industrial Pharmacist'],
  [ServiceabilityFeatureOption.ABS238215, 'ABS 2382-15 Retail Pharmacist'],
  [ServiceabilityFeatureOption.ABS238311, 'ABS 2383-11 Occupational Therapist'],
  [ServiceabilityFeatureOption.ABS238411, 'ABS 2384-11 Optometrist'],
  [ServiceabilityFeatureOption.ABS238511, 'ABS 2385-11 Physiotherapist'],
  [ServiceabilityFeatureOption.ABS238611, 'ABS 2386-11 Speech Pathologist'],
  [ServiceabilityFeatureOption.ABS238711, 'ABS 2387-11 Chiropractor'],
  [ServiceabilityFeatureOption.ABS238713, 'ABS 2387-13 Osteopath'],
  [ServiceabilityFeatureOption.ABS238811, 'ABS 2388-11 Podiatrist'],
  [ServiceabilityFeatureOption.ABS239111, 'ABS 2391-11 Medical Diagnostic Radiographer'],
  [ServiceabilityFeatureOption.ABS239113, 'ABS 2391-13 Radiation Therapist'],
  [ServiceabilityFeatureOption.ABS239115, 'ABS 2391-15 Nuclear Medicine Technologist'],
  [ServiceabilityFeatureOption.ABS239117, 'ABS 2391-17 Sonographer'],
  [ServiceabilityFeatureOption.ABS239211, 'ABS 2392-11 Veterinarian'],
  [ServiceabilityFeatureOption.ABS239311, 'ABS 2393-11 Dietitian'],
  [ServiceabilityFeatureOption.ABS239411, 'ABS 2394-11 Naturopath'],
  [ServiceabilityFeatureOption.ABS239413, 'ABS 2394-13 Acupuncturist'],
  [ServiceabilityFeatureOption.ABS239479, 'ABS 2394-79 Natural Therapy Professionals nec'],
  [ServiceabilityFeatureOption.ABS239911, 'ABS 2399-11 Audiologist'],
  [ServiceabilityFeatureOption.ABS239913, 'ABS 2399-13 Orthoptist'],
  [ServiceabilityFeatureOption.ABS239915, 'ABS 2399-15 Orthotist'],
  [ServiceabilityFeatureOption.ABS239979, 'ABS 2399-79 Health Professionals nec'],
  [ServiceabilityFeatureOption.ABS241111, 'ABS 2411-11 Pre-Primary School Teacher'],
  [ServiceabilityFeatureOption.ABS241211, 'ABS 2412-11 Primary School Teacher'],
  [ServiceabilityFeatureOption.ABS241311, 'ABS 2413-11 Secondary School Teacher'],
  [ServiceabilityFeatureOption.ABS241411, 'ABS 2414-11 Special Needs Teacher'],
  [ServiceabilityFeatureOption.ABS241413, 'ABS 2414-13 Teacher of the Hearing Impaired'],
  [ServiceabilityFeatureOption.ABS241415, 'ABS 2414-15 Teacher of the Sight Impaired'],
  [ServiceabilityFeatureOption.ABS241479, 'ABS 2414-79 Special Education Teachers nec'],
  [ServiceabilityFeatureOption.ABS242111, 'ABS 2421-11 University Lecturer'],
  [ServiceabilityFeatureOption.ABS242113, 'ABS 2421-13 University Tutor'],
  [ServiceabilityFeatureOption.ABS242211, 'ABS 2422-11 Vocational Education Teacher'],
  [ServiceabilityFeatureOption.ABS249111, 'ABS 2491-11 Art Teacher (Private)'],
  [ServiceabilityFeatureOption.ABS249113, 'ABS 2491-13 Music Teacher (Private)'],
  [ServiceabilityFeatureOption.ABS249115, 'ABS 2491-15 Dance Teacher (Private)'],
  [ServiceabilityFeatureOption.ABS249117, 'ABS 2491-17 Drama Teacher (Private)'],
  [ServiceabilityFeatureOption.ABS249179, 'ABS 2491-79 Extra-Systemic Teachers nec'],
  [ServiceabilityFeatureOption.ABS249211, 'ABS 2492-11 English as a Second Language Teacher'],
  [ServiceabilityFeatureOption.ABS249311, 'ABS 2493-11 Education Officer'],
  [ServiceabilityFeatureOption.ABS251111, 'ABS 2511-11 Social Worker'],
  [ServiceabilityFeatureOption.ABS251211, 'ABS 2512-11 Welfare Worker'],
  [ServiceabilityFeatureOption.ABS251213, 'ABS 2512-13 Community Worker'],
  [ServiceabilityFeatureOption.ABS251311, 'ABS 2513-11 Rehabilitation Counsellor'],
  [ServiceabilityFeatureOption.ABS251313, 'ABS 2513-13 Drug and Alcohol Counsellor'],
  [ServiceabilityFeatureOption.ABS251315, 'ABS 2513-15 Family Counsellor'],
  [ServiceabilityFeatureOption.ABS251317, 'ABS 2513-17 Careers Counsellor'],
  [ServiceabilityFeatureOption.ABS251319, 'ABS 2513-19 Student Counsellor'],
  [ServiceabilityFeatureOption.ABS251379, 'ABS 2513-79 Counsellors nec'],
  [ServiceabilityFeatureOption.ABS251411, 'ABS 2514-11 Clinical Psychologist'],
  [ServiceabilityFeatureOption.ABS251413, 'ABS 2514-13 Educational Psychologist'],
  [ServiceabilityFeatureOption.ABS251415, 'ABS 2514-15 Organisational Psychologist'],
  [ServiceabilityFeatureOption.ABS251479, 'ABS 2514-79 Psychologists nec'],
  [ServiceabilityFeatureOption.ABS251511, 'ABS 2515-11 Minister of Religion'],
  [ServiceabilityFeatureOption.ABS252111, 'ABS 2521-11 Barrister'],
  [ServiceabilityFeatureOption.ABS252113, 'ABS 2521-13 Solicitor'],
  [ServiceabilityFeatureOption.ABS252179, 'ABS 2521-79 Legal Professionals nec'],
  [ServiceabilityFeatureOption.ABS252211, 'ABS 2522-11 Economist'],
  [ServiceabilityFeatureOption.ABS252311, 'ABS 2523-11 Urban and Regional Planner'],
  [ServiceabilityFeatureOption.ABS252911, 'ABS 2529-11 Historian'],
  [ServiceabilityFeatureOption.ABS252913, 'ABS 2529-13 Interpreter'],
  [ServiceabilityFeatureOption.ABS252915, 'ABS 2529-15 Translator'],
  [ServiceabilityFeatureOption.ABS252979, 'ABS 2529-79 Social Professionals nec'],
  [ServiceabilityFeatureOption.ABS253111, 'ABS 2531-11 Painter (Visual Arts)'],
  [ServiceabilityFeatureOption.ABS253113, 'ABS 2531-13 Sculptor'],
  [ServiceabilityFeatureOption.ABS253115, 'ABS 2531-15 Potter or Ceramic Artist'],
  [ServiceabilityFeatureOption.ABS253179, 'ABS 2531-79 Visual Arts and Crafts Professionals nec'],
  [ServiceabilityFeatureOption.ABS253211, 'ABS 2532-11 Photographer'],
  [ServiceabilityFeatureOption.ABS253311, 'ABS 2533-11 Fashion Designer'],
  [ServiceabilityFeatureOption.ABS253313, 'ABS 2533-13 Graphic Designer'],
  [ServiceabilityFeatureOption.ABS253315, 'ABS 2533-15 Industrial Designer'],
  [ServiceabilityFeatureOption.ABS253317, 'ABS 2533-17 Interior Designer'],
  [ServiceabilityFeatureOption.ABS253319, 'ABS 2533-19 Illustrator'],
  [ServiceabilityFeatureOption.ABS253411, 'ABS 2534-11 Editor'],
  [ServiceabilityFeatureOption.ABS253413, 'ABS 2534-13 Print Journalist'],
  [ServiceabilityFeatureOption.ABS253415, 'ABS 2534-15 Television Journalist'],
  [ServiceabilityFeatureOption.ABS253417, 'ABS 2534-17 Radio Journalist'],
  [ServiceabilityFeatureOption.ABS253419, 'ABS 2534-19 Copywriter'],
  [ServiceabilityFeatureOption.ABS253421, 'ABS 2534-21 Technical Writer'],
  [ServiceabilityFeatureOption.ABS253479, 'ABS 2534-79 Journalists and Related Professionals nec'],
  [ServiceabilityFeatureOption.ABS253511, 'ABS 2535-11 Author'],
  [ServiceabilityFeatureOption.ABS253513, 'ABS 2535-13 Book Editor'],
  [ServiceabilityFeatureOption.ABS253515, 'ABS 2535-15 Script Editor'],
  [ServiceabilityFeatureOption.ABS253611, 'ABS 2536-11 Art Director (Film, Television or Stage)'],
  [ServiceabilityFeatureOption.ABS253613, 'ABS 2536-13 Director (Film, Television, Radio or Stage)'],
  [ServiceabilityFeatureOption.ABS253615, 'ABS 2536-15 Director of Photography'],
  [ServiceabilityFeatureOption.ABS253617, 'ABS 2536-17 Film and Video Editor'],
  [ServiceabilityFeatureOption.ABS253619, 'ABS 2536-19 Stage Manager'],
  [ServiceabilityFeatureOption.ABS253621, 'ABS 2536-21 Program Director (Radio or Television)'],
  [ServiceabilityFeatureOption.ABS253623, 'ABS 2536-23 Technical Director'],
  [ServiceabilityFeatureOption.ABS253679, 'ABS 2536-79 Film, Television, Radio and Stage Directors nec'],
  [ServiceabilityFeatureOption.ABS253711, 'ABS 2537-11 Music Director'],
  [ServiceabilityFeatureOption.ABS253713, 'ABS 2537-13 Singer'],
  [ServiceabilityFeatureOption.ABS253715, 'ABS 2537-15 Instrumental Musician'],
  [ServiceabilityFeatureOption.ABS253717, 'ABS 2537-17 Composer'],
  [ServiceabilityFeatureOption.ABS253779, 'ABS 2537-79 Musicians and Related Professionals nec'],
  [ServiceabilityFeatureOption.ABS253811, 'ABS 2538-11 Actor'],
  [ServiceabilityFeatureOption.ABS253813, 'ABS 2538-13 Dancer or Choreographer'],
  [ServiceabilityFeatureOption.ABS253879, 'ABS 2538-79 Actors, Dancers and Related Professionals nec'],
  [ServiceabilityFeatureOption.ABS253911, 'ABS 2539-11 Radio Presenter'],
  [ServiceabilityFeatureOption.ABS253913, 'ABS 2539-13 Television Presenter'],
  [ServiceabilityFeatureOption.ABS254111, 'ABS 2541-11 Aircraft Pilot'],
  [ServiceabilityFeatureOption.ABS254113, 'ABS 2541-13 Air Traffic Controller'],
  [ServiceabilityFeatureOption.ABS254115, 'ABS 2541-15 Flight Service Officer'],
  [ServiceabilityFeatureOption.ABS254117, 'ABS 2541-17 Flight Engineer'],
  [ServiceabilityFeatureOption.ABS254119, 'ABS 2541-19 Flying Instructor'],
  [ServiceabilityFeatureOption.ABS254179, 'ABS 2541-79 Air Transport Professionals nec'],
  [ServiceabilityFeatureOption.ABS254211, 'ABS 2542-11 Ship’s Master'],
  [ServiceabilityFeatureOption.ABS254213, 'ABS 2542-13 Master Fisher'],
  [ServiceabilityFeatureOption.ABS254215, 'ABS 2542-15 Ship’s Engineer'],
  [ServiceabilityFeatureOption.ABS254217, 'ABS 2542-17 Ship’s Surveyor'],
  [ServiceabilityFeatureOption.ABS254219, 'ABS 2542-19 Ship’s Officer'],
  [ServiceabilityFeatureOption.ABS254279, 'ABS 2542-79 Sea Transport Professionals nec'],
  [ServiceabilityFeatureOption.ABS254311, 'ABS 2543-11 Occupational Health and Safety Officer'],
  [ServiceabilityFeatureOption.ABS254313, 'ABS 2543-13 Environmental Health Officer'],
  [ServiceabilityFeatureOption.ABS254911, 'ABS 2549-11 Conservator'],
  [ServiceabilityFeatureOption.ABS254913, 'ABS 2549-13 Electorate Officer'],
  [ServiceabilityFeatureOption.ABS254915, 'ABS 2549-15 Patents Examiner'],
  [ServiceabilityFeatureOption.ABS254917, 'ABS 2549-17 Oenologist'],
  [ServiceabilityFeatureOption.ABS254919, 'ABS 2549-19 Recreation Officer'],
  [ServiceabilityFeatureOption.ABS254921, 'ABS 2549-21 Museum or Gallery Curator'],
  [ServiceabilityFeatureOption.ABS254979, 'ABS 2549-79 Professionals nec'],
  [ServiceabilityFeatureOption.ABS311111, 'ABS 3111-11 Medical Laboratory Technical Officer'],
  [ServiceabilityFeatureOption.ABS311179, 'ABS 3111-79 Medical Technical Officers nec'],
  [ServiceabilityFeatureOption.ABS311211, 'ABS 3112-11 Chemistry Technical Officer'],
  [ServiceabilityFeatureOption.ABS311213, 'ABS 3112-13 Earth Science Technical Officer'],
  [ServiceabilityFeatureOption.ABS311215, 'ABS 3112-15 Life Science Technical Officer'],
  [ServiceabilityFeatureOption.ABS311217, 'ABS 3112-17 Agricultural Technical Officer'],
  [ServiceabilityFeatureOption.ABS311279, 'ABS 3112-79 Science Technical Officers nec'],
  [ServiceabilityFeatureOption.ABS312111, 'ABS 3121-11 Building Associate'],
  [ServiceabilityFeatureOption.ABS312113, 'ABS 3121-13 Architectural Associate'],
  [ServiceabilityFeatureOption.ABS312115, 'ABS 3121-15 Surveying and Cartographic Associate'],
  [ServiceabilityFeatureOption.ABS312117, 'ABS 3121-17 Building Inspector'],
  [ServiceabilityFeatureOption.ABS312119, 'ABS 3121-19 Plumbing Inspector'],
  [ServiceabilityFeatureOption.ABS312121, 'ABS 3121-21 Plumbing Engineering Associate'],
  [ServiceabilityFeatureOption.ABS312211, 'ABS 3122-11 Civil Engineering Associate'],
  [ServiceabilityFeatureOption.ABS312213, 'ABS 3122-13 Civil Engineering Technician'],
  [ServiceabilityFeatureOption.ABS312311, 'ABS 3123-11 Electrical Engineering Associate'],
  [ServiceabilityFeatureOption.ABS312313, 'ABS 3123-13 Electrical Engineering Technician'],
  [ServiceabilityFeatureOption.ABS312411, 'ABS 3124-11 Electronic Engineering Associate'],
  [ServiceabilityFeatureOption.ABS312413, 'ABS 3124-13 Electronic Engineering Technician'],
  [ServiceabilityFeatureOption.ABS312511, 'ABS 3125-11 Mechanical Engineering Associate'],
  [ServiceabilityFeatureOption.ABS312513, 'ABS 3125-13 Mechanical Engineering Technician'],
  [ServiceabilityFeatureOption.ABS312911, 'ABS 3129-11 Biomedical Engineering Associate'],
  [ServiceabilityFeatureOption.ABS312913, 'ABS 3129-13 Metallurgical and Materials Technician'],
  [ServiceabilityFeatureOption.ABS312915, 'ABS 3129-15 Mine Deputy'],
  [ServiceabilityFeatureOption.ABS312979, 'ABS 3129-79 Building and Engineering Associate Professionals nec'],
  [ServiceabilityFeatureOption.ABS321111, 'ABS 3211-11 Branch Accountant (Financial Institution)'],
  [ServiceabilityFeatureOption.ABS321113, 'ABS 3211-13 Financial Institution Branch Manager'],
  [ServiceabilityFeatureOption.ABS321211, 'ABS 3212-11 Stockbroking Dealer'],
  [ServiceabilityFeatureOption.ABS321213, 'ABS 3212-13 Futures Trader'],
  [ServiceabilityFeatureOption.ABS321215, 'ABS 3212-15 Financial Market Dealer'],
  [ServiceabilityFeatureOption.ABS321217, 'ABS 3212-17 Commodities Trader'],
  [ServiceabilityFeatureOption.ABS321219, 'ABS 3212-19 Insurance Broker'],
  [ServiceabilityFeatureOption.ABS321221, 'ABS 3212-21 Bookmaker'],
  [ServiceabilityFeatureOption.ABS321279, 'ABS 3212-79 Financial Dealers and Brokers nec'],
  [ServiceabilityFeatureOption.ABS321311, 'ABS 3213-11 Financial Investment Adviser'],
  [ServiceabilityFeatureOption.ABS329111, 'ABS 3291-11 Office Manager'],
  [ServiceabilityFeatureOption.ABS329211, 'ABS 3292-11 Project or Program Administrator'],
  [ServiceabilityFeatureOption.ABS329311, 'ABS 3293-11 Real Estate Agency Manager'],
  [ServiceabilityFeatureOption.ABS329313, 'ABS 3293-13 Property Manager'],
  [ServiceabilityFeatureOption.ABS329315, 'ABS 3293-15 Real Estate Salesperson'],
  [ServiceabilityFeatureOption.ABS329411, 'ABS 3294-11 Computing Support Technician'],
  [ServiceabilityFeatureOption.ABS331111, 'ABS 3311-11 Shop Manager'],
  [ServiceabilityFeatureOption.ABS332111, 'ABS 3321-11 Restaurant and Catering Manager'],
  [ServiceabilityFeatureOption.ABS332201, 'ABS 3322-01 Head Chef'],
  [ServiceabilityFeatureOption.ABS332211, 'ABS 3322-11 Chef'],
  [ServiceabilityFeatureOption.ABS332311, 'ABS 3323-11 Hotel or Motel Manager'],
  [ServiceabilityFeatureOption.ABS332411, 'ABS 3324-11 Club Manager (Licensed Premises)'],
  [ServiceabilityFeatureOption.ABS332511, 'ABS 3325-11 Caravan Park and Camping Ground Manager'],
  [ServiceabilityFeatureOption.ABS332911, 'ABS 3329-11 Other Hospitality and Accommodation Managers'],
  [ServiceabilityFeatureOption.ABS339111, 'ABS 3391-11 Fitness Centre Manager'],
  [ServiceabilityFeatureOption.ABS339113, 'ABS 3391-13 Other Sports Centre Manager'],
  [ServiceabilityFeatureOption.ABS339115, 'ABS 3391-15 Amusement Centre Manager'],
  [ServiceabilityFeatureOption.ABS339179, 'ABS 3391-79 Sport and Recreation Managers nec'],
  [ServiceabilityFeatureOption.ABS339211, 'ABS 3392-11 Customer Service Manager'],
  [ServiceabilityFeatureOption.ABS339311, 'ABS 3393-11 Transport Company Manager'],
  [ServiceabilityFeatureOption.ABS339911, 'ABS 3399-11 Post Office Manager'],
  [ServiceabilityFeatureOption.ABS339913, 'ABS 3399-13 Railway Station Manager'],
  [ServiceabilityFeatureOption.ABS339915, 'ABS 3399-15 Betting Agency Branch Manager'],
  [ServiceabilityFeatureOption.ABS339917, 'ABS 3399-17 Hair and Beauty Salon Manager'],
  [ServiceabilityFeatureOption.ABS339919, 'ABS 3399-19 Car Rental Agency Manager'],
  [ServiceabilityFeatureOption.ABS339921, 'ABS 3399-21 Fleet Manager'],
  [ServiceabilityFeatureOption.ABS339923, 'ABS 3399-23 Stock and Station Agent'],
  [ServiceabilityFeatureOption.ABS339925, 'ABS 3399-25 Travel Agency Manager'],
  [ServiceabilityFeatureOption.ABS339927, 'ABS 3399-27 Theatre or Cinema Manager'],
  [ServiceabilityFeatureOption.ABS339929, 'ABS 3399-29 Funeral Director'],
  [ServiceabilityFeatureOption.ABS339979, 'ABS 3399-79 Managing Supervisors (Sales and Service) nec'],
  [ServiceabilityFeatureOption.ABS341111, 'ABS 3411-11 Enrolled Nurse'],
  [ServiceabilityFeatureOption.ABS342111, 'ABS 3421-11 Parole or Probation Officer'],
  [ServiceabilityFeatureOption.ABS342113, 'ABS 3421-13 Youth Worker'],
  [ServiceabilityFeatureOption.ABS342115, 'ABS 3421-15 Residential Care Officer'],
  [ServiceabilityFeatureOption.ABS342117, 'ABS 3421-17 Disabilities Services Officer'],
  [ServiceabilityFeatureOption.ABS342119, 'ABS 3421-19 Family Support Worker'],
  [ServiceabilityFeatureOption.ABS349111, 'ABS 3491-11 Ambulance Officer'],
  [ServiceabilityFeatureOption.ABS349113, 'ABS 3491-13 Intensive Care Ambulance Paramedic'],
  [ServiceabilityFeatureOption.ABS349211, 'ABS 3492-11 Dental Therapist'],
  [ServiceabilityFeatureOption.ABS349213, 'ABS 3492-13 Dental Hygienist'],
  [ServiceabilityFeatureOption.ABS349215, 'ABS 3492-15 Dental Technician'],
  [ServiceabilityFeatureOption.ABS349311, 'ABS 3493-11 Aboriginal and Torres Strait Islander Health Worker'],
  [ServiceabilityFeatureOption.ABS349411, 'ABS 3494-11 Massage Therapist'],
  [ServiceabilityFeatureOption.ABS391101, 'ABS 3911-01 Supervisor, Police Officers'],
  [ServiceabilityFeatureOption.ABS391111, 'ABS 3911-11 Police Officer'],
  [ServiceabilityFeatureOption.ABS399111, 'ABS 3991-11 Primary Products Inspector'],
  [ServiceabilityFeatureOption.ABS399211, 'ABS 3992-11 Safety Inspector'],
  [ServiceabilityFeatureOption.ABS399311, 'ABS 3993-11 Jockey'],
  [ServiceabilityFeatureOption.ABS399313, 'ABS 3993-13 Golfer'],
  [ServiceabilityFeatureOption.ABS399315, 'ABS 3993-15 Footballer'],
  [ServiceabilityFeatureOption.ABS399317, 'ABS 3993-17 Other Sportsperson'],
  [ServiceabilityFeatureOption.ABS399319, 'ABS 3993-19 Gymnastics Coach'],
  [ServiceabilityFeatureOption.ABS399321, 'ABS 3993-21 Tennis Coach'],
  [ServiceabilityFeatureOption.ABS399323, 'ABS 3993-23 Swimming Coach'],
  [ServiceabilityFeatureOption.ABS399325, 'ABS 3993-25 Horseriding Coach'],
  [ServiceabilityFeatureOption.ABS399327, 'ABS 3993-27 Other Sports Coach'],
  [ServiceabilityFeatureOption.ABS399329, 'ABS 3993-29 Sports Development Officer'],
  [ServiceabilityFeatureOption.ABS399331, 'ABS 3993-31 Sports Umpire'],
  [ServiceabilityFeatureOption.ABS399333, 'ABS 3993-33 Horse or Dog Racing Official'],
  [ServiceabilityFeatureOption.ABS399335, 'ABS 3993-35 Other Sports Official'],
  [ServiceabilityFeatureOption.ABS399411, 'ABS 3994-11 Senior Non-Commissioned Defence Force Officer'],
  [ServiceabilityFeatureOption.ABS399511, 'ABS 3995-11 Senior Fire Fighter'],
  [ServiceabilityFeatureOption.ABS399611, 'ABS 3996-11 Retail Buyer'],
  [ServiceabilityFeatureOption.ABS399711, 'ABS 3997-11 Library Technician'],
  [ServiceabilityFeatureOption.ABS399911, 'ABS 3999-11 Interior Decorator'],
  [ServiceabilityFeatureOption.ABS399913, 'ABS 3999-13 Museum or Art Gallery Technician'],
  [ServiceabilityFeatureOption.ABS399915, 'ABS 3999-15 Radio Operator'],
  [ServiceabilityFeatureOption.ABS399917, 'ABS 3999-17 Private Investigator'],
  [ServiceabilityFeatureOption.ABS399919, 'ABS 3999-19 Security Adviser'],
  [ServiceabilityFeatureOption.ABS399979, 'ABS 3999-79 Associate Professionals nec'],
  [ServiceabilityFeatureOption.ABS411101, 'ABS 4111-01 Supervisor, General Mechanical Engineering Tradespersons'],
  [ServiceabilityFeatureOption.ABS411111, 'ABS 4111-11 General Mechanical Engineering Tradesperson'],
  [ServiceabilityFeatureOption.ABS411181, 'ABS 4111-81 Apprentice General Mechanical Engineering Tradesperson'],
  [ServiceabilityFeatureOption.ABS411201, 'ABS 4112-01 Supervisor, Metal Fitters and Machinists'],
  [ServiceabilityFeatureOption.ABS411211, 'ABS 4112-11 Fitter'],
  [ServiceabilityFeatureOption.ABS411213, 'ABS 4112-13 Metal Machinist (First Class)'],
  [ServiceabilityFeatureOption.ABS411215, 'ABS 4112-15 Textile, Clothing or Footwear Mechanic'],
  [ServiceabilityFeatureOption.ABS411281, 'ABS 4112-81 Apprentice Fitter'],
  [ServiceabilityFeatureOption.ABS411283, 'ABS 4112-83 Apprentice Metal Machinist'],
  [ServiceabilityFeatureOption.ABS411285, 'ABS 4112-85 Apprentice Textile, Clothing or Footwear Mechanic'],
  [ServiceabilityFeatureOption.ABS411301, 'ABS 4113-01 Supervisor, Toolmakers'],
  [ServiceabilityFeatureOption.ABS411311, 'ABS 4113-11 Toolmaker'],
  [ServiceabilityFeatureOption.ABS411381, 'ABS 4113-81 Apprentice Toolmaker'],
  [ServiceabilityFeatureOption.ABS411401, 'ABS 4114-01 Supervisor, Aircraft Maintenance Engineers'],
  [ServiceabilityFeatureOption.ABS411411, 'ABS 4114-11 Aircraft Maintenance Engineer (Mechanical)'],
  [ServiceabilityFeatureOption.ABS411413, 'ABS 4114-13 Aircraft Maintenance Engineer (Structures)'],
  [ServiceabilityFeatureOption.ABS411415, 'ABS 4114-15 Aircraft Maintenance Engineer (Avionics)'],
  [ServiceabilityFeatureOption.ABS411481, 'ABS 4114-81 Apprentice Aircraft Maintenance Engineer (Mechanical)'],
  [ServiceabilityFeatureOption.ABS411483, 'ABS 4114-83 Apprentice Aircraft Maintenance Engineer (Structures)'],
  [ServiceabilityFeatureOption.ABS411485, 'ABS 4114-85 Apprentice Aircraft Maintenance Engineer (Avionics)'],
  [ServiceabilityFeatureOption.ABS411501, 'ABS 4115-01 Supervisor, Precision Metal Tradespersons'],
  [ServiceabilityFeatureOption.ABS411511, 'ABS 4115-11 Precision Instrument Maker and Repairer'],
  [ServiceabilityFeatureOption.ABS411513, 'ABS 4115-13 Watch and Clock Maker and Repairer'],
  [ServiceabilityFeatureOption.ABS411515, 'ABS 4115-15 Locksmith'],
  [ServiceabilityFeatureOption.ABS411517, 'ABS 4115-17 Saw Maker and Repairer'],
  [ServiceabilityFeatureOption.ABS411519, 'ABS 4115-19 Gunsmith'],
  [ServiceabilityFeatureOption.ABS411521, 'ABS 4115-21 Engraver'],
  [ServiceabilityFeatureOption.ABS411581, 'ABS 4115-81 Apprentice Precision Metal Tradesperson'],
  [ServiceabilityFeatureOption.ABS412101, 'ABS 4121-01 Supervisor, General Fabrication Engineering Tradespersons'],
  [ServiceabilityFeatureOption.ABS412111, 'ABS 4121-11 General Fabrication Engineering Tradesperson'],
  [ServiceabilityFeatureOption.ABS412181, 'ABS 4121-81 Apprentice General Fabrication Engineering Tradesperson'],
  [ServiceabilityFeatureOption.ABS412201, 'ABS 4122-01 Supervisor, Structural Steel and Welding Tradespersons'],
  [ServiceabilityFeatureOption.ABS412211, 'ABS 4122-11 Metal Fabricator'],
  [ServiceabilityFeatureOption.ABS412213, 'ABS 4122-13 Pressure Welder'],
  [ServiceabilityFeatureOption.ABS412215, 'ABS 4122-15 Welder (First Class)'],
  [ServiceabilityFeatureOption.ABS412281, 'ABS 4122-81 Apprentice Metal Fabricator'],
  [ServiceabilityFeatureOption.ABS412283, 'ABS 4122-83 Apprentice Welder'],
  [ServiceabilityFeatureOption.ABS412301, 'ABS 4123-01 Supervisor, Forging Tradespersons'],
  [ServiceabilityFeatureOption.ABS412311, 'ABS 4123-11 Blacksmith'],
  [ServiceabilityFeatureOption.ABS412313, 'ABS 4123-13 Farrier'],
  [ServiceabilityFeatureOption.ABS412381, 'ABS 4123-81 Apprentice Blacksmith'],
  [ServiceabilityFeatureOption.ABS412383, 'ABS 4123-83 Apprentice Farrier'],
  [ServiceabilityFeatureOption.ABS412401, 'ABS 4124-01 Supervisor, Sheetmetal Tradespersons'],
  [ServiceabilityFeatureOption.ABS412411, 'ABS 4124-11 Sheetmetal Worker (First Class)'],
  [ServiceabilityFeatureOption.ABS412481, 'ABS 4124-81 Apprentice Sheetmetal Worker'],
  [ServiceabilityFeatureOption.ABS412501, 'ABS 4125-01 Supervisor, Metal Casting Tradespersons'],
  [ServiceabilityFeatureOption.ABS412511, 'ABS 4125-11 Metal Casting Tradesperson'],
  [ServiceabilityFeatureOption.ABS412581, 'ABS 4125-81 Apprentice Metal Casting Tradesperson'],
  [ServiceabilityFeatureOption.ABS412601, 'ABS 4126-01 Supervisor, Metal Finishing Tradespersons'],
  [ServiceabilityFeatureOption.ABS412611, 'ABS 4126-11 Metal Polisher'],
  [ServiceabilityFeatureOption.ABS412613, 'ABS 4126-13 Electroplater (First Class)'],
  [ServiceabilityFeatureOption.ABS412681, 'ABS 4126-81 Apprentice Metal Polisher'],
  [ServiceabilityFeatureOption.ABS412683, 'ABS 4126-83 Apprentice Electroplater'],
  [ServiceabilityFeatureOption.ABS421101, 'ABS 4211-01 Supervisor, Motor Mechanics'],
  [ServiceabilityFeatureOption.ABS421111, 'ABS 4211-11 Motor Mechanic'],
  [ServiceabilityFeatureOption.ABS421181, 'ABS 4211-81 Apprentice Motor Mechanic'],
  [ServiceabilityFeatureOption.ABS421201, 'ABS 4212-01 Supervisor, Automotive Electricians'],
  [ServiceabilityFeatureOption.ABS421211, 'ABS 4212-11 Automotive Electrician'],
  [ServiceabilityFeatureOption.ABS421281, 'ABS 4212-81 Apprentice Automotive Electrician'],
  [ServiceabilityFeatureOption.ABS421301, 'ABS 4213-01 Supervisor, Panel Beaters'],
  [ServiceabilityFeatureOption.ABS421311, 'ABS 4213-11 Panel Beater'],
  [ServiceabilityFeatureOption.ABS421381, 'ABS 4213-81 Apprentice Panel Beater'],
  [ServiceabilityFeatureOption.ABS421401, 'ABS 4214-01 Supervisor, Vehicle Painters'],
  [ServiceabilityFeatureOption.ABS421411, 'ABS 4214-11 Vehicle Painter'],
  [ServiceabilityFeatureOption.ABS421481, 'ABS 4214-81 Apprentice Vehicle Painter'],
  [ServiceabilityFeatureOption.ABS421501, 'ABS 4215-01 Supervisor, Vehicle Body Makers'],
  [ServiceabilityFeatureOption.ABS421511, 'ABS 4215-11 Vehicle Body Maker'],
  [ServiceabilityFeatureOption.ABS421581, 'ABS 4215-81 Apprentice Vehicle Body Maker'],
  [ServiceabilityFeatureOption.ABS421601, 'ABS 4216-01 Supervisor, Vehicle Trimmers'],
  [ServiceabilityFeatureOption.ABS421611, 'ABS 4216-11 Vehicle Trimmer'],
  [ServiceabilityFeatureOption.ABS421681, 'ABS 4216-81 Apprentice Vehicle Trimmer'],
  [ServiceabilityFeatureOption.ABS431101, 'ABS 4311-01 Supervisor, Electricians'],
  [ServiceabilityFeatureOption.ABS431111, 'ABS 4311-11 General Electrician'],
  [ServiceabilityFeatureOption.ABS431113, 'ABS 4311-13 Electrician (Special Class)'],
  [ServiceabilityFeatureOption.ABS431115, 'ABS 4311-15 Lift Mechanic'],
  [ServiceabilityFeatureOption.ABS431181, 'ABS 4311-81 Apprentice Electrician'],
  [ServiceabilityFeatureOption.ABS431183, 'ABS 4311-83 Apprentice Lift Mechanic'],
  [ServiceabilityFeatureOption.ABS431201, 'ABS 4312-01 Supervisor, Refrigeration and Airconditioning Mechanics'],
  [ServiceabilityFeatureOption.ABS431211, 'ABS 4312-11 Refrigeration and Airconditioning Mechanic'],
  [ServiceabilityFeatureOption.ABS431281, 'ABS 4312-81 Apprentice Refrigeration and Airconditioning Mechanic'],
  [ServiceabilityFeatureOption.ABS431301, 'ABS 4313-01 Supervisor, Electrical Distribution Tradespersons'],
  [ServiceabilityFeatureOption.ABS431311, 'ABS 4313-11 Electrical Powerline Tradesperson'],
  [ServiceabilityFeatureOption.ABS431313, 'ABS 4313-13 Cable Jointer'],
  [ServiceabilityFeatureOption.ABS431381, 'ABS 4313-81 Apprentice Electrical Powerline Tradesperson'],
  [ServiceabilityFeatureOption.ABS431383, 'ABS 4313-83 Apprentice Cable Jointer'],
  [ServiceabilityFeatureOption.ABS431401, 'ABS 4314-01 Supervisor, Electronic Instrument Tradespersons'],
  [ServiceabilityFeatureOption.ABS431411, 'ABS 4314-11 General Electronic Instrument Tradesperson'],
  [ServiceabilityFeatureOption.ABS431413, 'ABS 4314-13 Electronic Instrument Tradesperson (Special Class)'],
  [ServiceabilityFeatureOption.ABS431481, 'ABS 4314-81 Apprentice Electronic Instrument Tradesperson'],
  [ServiceabilityFeatureOption.ABS431501, 'ABS 4315-01 Supervisor, Electronic and Office Equipment Tradespersons'],
  [ServiceabilityFeatureOption.ABS431511, 'ABS 4315-11 Electronic Equipment Tradesperson'],
  [ServiceabilityFeatureOption.ABS431513, 'ABS 4315-13 Business Machine Mechanic'],
  [ServiceabilityFeatureOption.ABS431581, 'ABS 4315-81 Apprentice Electronic Equipment Tradesperson'],
  [ServiceabilityFeatureOption.ABS431583, 'ABS 4315-83 Apprentice Business Machine Mechanic'],
  [ServiceabilityFeatureOption.ABS431601, 'ABS 4316-01 Supervisor, Communications Tradespersons'],
  [ServiceabilityFeatureOption.ABS431611, 'ABS 4316-11 General Communications Tradesperson'],
  [ServiceabilityFeatureOption.ABS431613, 'ABS 4316-13 Communications Linesperson'],
  [ServiceabilityFeatureOption.ABS431681, 'ABS 4316-81 Apprentice General Communications Tradesperson'],
  [ServiceabilityFeatureOption.ABS431683, 'ABS 4316-83 Apprentice Communications Linesperson'],
  [ServiceabilityFeatureOption.ABS441101, 'ABS 4411-01 Supervisor, Carpentry and Joinery Tradespersons'],
  [ServiceabilityFeatureOption.ABS441111, 'ABS 4411-11 Carpenter and Joiner'],
  [ServiceabilityFeatureOption.ABS441113, 'ABS 4411-13 Carpenter'],
  [ServiceabilityFeatureOption.ABS441115, 'ABS 4411-15 Joiner'],
  [ServiceabilityFeatureOption.ABS441181, 'ABS 4411-81 Apprentice Carpenter and Joiner'],
  [ServiceabilityFeatureOption.ABS441183, 'ABS 4411-83 Apprentice Carpenter'],
  [ServiceabilityFeatureOption.ABS441185, 'ABS 4411-85 Apprentice Joiner'],
  [ServiceabilityFeatureOption.ABS441201, 'ABS 4412-01 Supervisor, Fibrous Plasterers'],
  [ServiceabilityFeatureOption.ABS441211, 'ABS 4412-11 Fibrous Plasterer'],
  [ServiceabilityFeatureOption.ABS441281, 'ABS 4412-81 Apprentice Fibrous Plasterer'],
  [ServiceabilityFeatureOption.ABS441301, 'ABS 4413-01 Supervisor, Roof Slaters and Tilers'],
  [ServiceabilityFeatureOption.ABS441311, 'ABS 4413-11 Roof Slater and Tiler'],
  [ServiceabilityFeatureOption.ABS441381, 'ABS 4413-81 Apprentice Roof Slater and Tiler'],
  [ServiceabilityFeatureOption.ABS441401, 'ABS 4414-01 Supervisor, Bricklayers'],
  [ServiceabilityFeatureOption.ABS441411, 'ABS 4414-11 Bricklayer'],
  [ServiceabilityFeatureOption.ABS441481, 'ABS 4414-81 Apprentice Bricklayer'],
  [ServiceabilityFeatureOption.ABS441501, 'ABS 4415-01 Supervisor, Solid Plasterers'],
  [ServiceabilityFeatureOption.ABS441511, 'ABS 4415-11 Solid Plasterer'],
  [ServiceabilityFeatureOption.ABS441581, 'ABS 4415-81 Apprentice Solid Plasterer'],
  [ServiceabilityFeatureOption.ABS441601, 'ABS 4416-01 Supervisor, Wall and Floor Tilers and Stonemasons'],
  [ServiceabilityFeatureOption.ABS441611, 'ABS 4416-11 Wall and Floor Tiler'],
  [ServiceabilityFeatureOption.ABS441613, 'ABS 4416-13 Stonemason'],
  [ServiceabilityFeatureOption.ABS441681, 'ABS 4416-81 Apprentice Wall and Floor Tiler'],
  [ServiceabilityFeatureOption.ABS441683, 'ABS 4416-83 Apprentice Stonemason'],
  [ServiceabilityFeatureOption.ABS442101, 'ABS 4421-01 Supervisor, Painters and Decorators'],
  [ServiceabilityFeatureOption.ABS442111, 'ABS 4421-11 Painter and Decorator'],
  [ServiceabilityFeatureOption.ABS442181, 'ABS 4421-81 Apprentice Painter and Decorator'],
  [ServiceabilityFeatureOption.ABS442201, 'ABS 4422-01 Supervisor, Signwriters'],
  [ServiceabilityFeatureOption.ABS442211, 'ABS 4422-11 Signwriter'],
  [ServiceabilityFeatureOption.ABS442281, 'ABS 4422-81 Apprentice Signwriter'],
  [ServiceabilityFeatureOption.ABS442301, 'ABS 4423-01 Supervisor, Floor Finishers'],
  [ServiceabilityFeatureOption.ABS442311, 'ABS 4423-11 Floor Finisher'],
  [ServiceabilityFeatureOption.ABS442381, 'ABS 4423-81 Apprentice Floor Finisher'],
  [ServiceabilityFeatureOption.ABS443101, 'ABS 4431-01 Supervisor, Plumbers'],
  [ServiceabilityFeatureOption.ABS443111, 'ABS 4431-11 General Plumber'],
  [ServiceabilityFeatureOption.ABS443113, 'ABS 4431-13 Gasfitter'],
  [ServiceabilityFeatureOption.ABS443115, 'ABS 4431-15 Drainer'],
  [ServiceabilityFeatureOption.ABS443117, 'ABS 4431-17 Roof Plumber'],
  [ServiceabilityFeatureOption.ABS443119, 'ABS 4431-19 Mechanical Services and Airconditioning Plumber'],
  [ServiceabilityFeatureOption.ABS443181, 'ABS 4431-81 Apprentice General Plumber'],
  [ServiceabilityFeatureOption.ABS443183, 'ABS 4431-83 Apprentice Gasfitter'],
  [ServiceabilityFeatureOption.ABS443185, 'ABS 4431-85 Apprentice Drainer'],
  [ServiceabilityFeatureOption.ABS443187, 'ABS 4431-87 Apprentice Roof Plumber'],
  [ServiceabilityFeatureOption.ABS443189, 'ABS 4431-89 Apprentice Mechanical Services and Airconditioning Plumber'],
  [ServiceabilityFeatureOption.ABS451101, 'ABS 4511-01 Supervisor, Meat Tradespersons'],
  [ServiceabilityFeatureOption.ABS451111, 'ABS 4511-11 Butcher'],
  [ServiceabilityFeatureOption.ABS451113, 'ABS 4511-13 Smallgoods Maker'],
  [ServiceabilityFeatureOption.ABS451115, 'ABS 4511-15 Slaughterperson'],
  [ServiceabilityFeatureOption.ABS451181, 'ABS 4511-81 Apprentice Butcher'],
  [ServiceabilityFeatureOption.ABS451183, 'ABS 4511-83 Apprentice Smallgoods Maker'],
  [ServiceabilityFeatureOption.ABS451185, 'ABS 4511-85 Apprentice Slaughterperson'],
  [ServiceabilityFeatureOption.ABS451201, 'ABS 4512-01 Supervisor, Bakers and Pastrycooks'],
  [ServiceabilityFeatureOption.ABS451211, 'ABS 4512-11 Baker'],
  [ServiceabilityFeatureOption.ABS451213, 'ABS 4512-13 Pastrycook'],
  [ServiceabilityFeatureOption.ABS451281, 'ABS 4512-81 Apprentice Baker'],
  [ServiceabilityFeatureOption.ABS451283, 'ABS 4512-83 Apprentice Pastrycook'],
  [ServiceabilityFeatureOption.ABS451311, 'ABS 4513-11 Cook'],
  [ServiceabilityFeatureOption.ABS451381, 'ABS 4513-81 Apprentice Cook or Chef'],
  [ServiceabilityFeatureOption.ABS451911, 'ABS 4519-11 Miller'],
  [ServiceabilityFeatureOption.ABS451913, 'ABS 4519-13 Buttermaker or Cheesemaker'],
  [ServiceabilityFeatureOption.ABS451915, 'ABS 4519-15 Confectioner'],
  [ServiceabilityFeatureOption.ABS451981, 'ABS 4519-81 Apprentice Miller'],
  [ServiceabilityFeatureOption.ABS451983, 'ABS 4519-83 Apprentice Buttermaker or Cheesemaker'],
  [ServiceabilityFeatureOption.ABS451985, 'ABS 4519-85 Apprentice Confectioner'],
  [ServiceabilityFeatureOption.ABS461111, 'ABS 4611-11 Farm Overseer'],
  [ServiceabilityFeatureOption.ABS461211, 'ABS 4612-11 Shearer'],
  [ServiceabilityFeatureOption.ABS461311, 'ABS 4613-11 Wool Classer'],
  [ServiceabilityFeatureOption.ABS461313, 'ABS 4613-13 Hide and Skin Classer'],
  [ServiceabilityFeatureOption.ABS461411, 'ABS 4614-11 Horse Trainer'],
  [ServiceabilityFeatureOption.ABS461479, 'ABS 4614-79 Animal Trainers nec'],
  [ServiceabilityFeatureOption.ABS462111, 'ABS 4621-11 Nurseryperson'],
  [ServiceabilityFeatureOption.ABS462181, 'ABS 4621-81 Apprentice Nurseryperson'],
  [ServiceabilityFeatureOption.ABS462211, 'ABS 4622-11 Greenkeeper'],
  [ServiceabilityFeatureOption.ABS462281, 'ABS 4622-81 Apprentice Greenkeeper'],
  [ServiceabilityFeatureOption.ABS462301, 'ABS 4623-01 Head Gardener'],
  [ServiceabilityFeatureOption.ABS462311, 'ABS 4623-11 General Gardener'],
  [ServiceabilityFeatureOption.ABS462313, 'ABS 4623-13 Landscape Gardener'],
  [ServiceabilityFeatureOption.ABS462315, 'ABS 4623-15 Tree Surgeon'],
  [ServiceabilityFeatureOption.ABS462381, 'ABS 4623-81 Apprentice General Gardener'],
  [ServiceabilityFeatureOption.ABS462383, 'ABS 4623-83 Apprentice Landscape Gardener'],
  [ServiceabilityFeatureOption.ABS462385, 'ABS 4623-85 Apprentice Tree Surgeon'],
  [ServiceabilityFeatureOption.ABS491111, 'ABS 4911-11 Graphic Pre-Press Tradesperson'],
  [ServiceabilityFeatureOption.ABS491181, 'ABS 4911-81 Apprentice Graphic Pre-Press Tradesperson'],
  [ServiceabilityFeatureOption.ABS491211, 'ABS 4912-11 Printing Machinist'],
  [ServiceabilityFeatureOption.ABS491213, 'ABS 4912-13 Small Offset Printer'],
  [ServiceabilityFeatureOption.ABS491281, 'ABS 4912-81 Apprentice Printing Machinist'],
  [ServiceabilityFeatureOption.ABS491283, 'ABS 4912-83 Apprentice Small Offset Printer'],
  [ServiceabilityFeatureOption.ABS491311, 'ABS 4913-11 Binder and Finisher'],
  [ServiceabilityFeatureOption.ABS491381, 'ABS 4913-81 Apprentice Binder and Finisher'],
  [ServiceabilityFeatureOption.ABS491411, 'ABS 4914-11 Screen Printer'],
  [ServiceabilityFeatureOption.ABS491481, 'ABS 4914-81 Apprentice Screen Printer'],
  [ServiceabilityFeatureOption.ABS492111, 'ABS 4921-11 Wood Machinist (A-Grade)'],
  [ServiceabilityFeatureOption.ABS492113, 'ABS 4921-13 Wood Turner'],
  [ServiceabilityFeatureOption.ABS492181, 'ABS 4921-81 Apprentice Wood Machinist'],
  [ServiceabilityFeatureOption.ABS492183, 'ABS 4921-83 Apprentice Wood Turner'],
  [ServiceabilityFeatureOption.ABS492201, 'ABS 4922-01 Supervisor, Cabinetmakers'],
  [ServiceabilityFeatureOption.ABS492211, 'ABS 4922-11 Cabinetmaker'],
  [ServiceabilityFeatureOption.ABS492281, 'ABS 4922-81 Apprentice Cabinetmaker'],
  [ServiceabilityFeatureOption.ABS492911, 'ABS 4929-11 Picture Framer'],
  [ServiceabilityFeatureOption.ABS492913, 'ABS 4929-13 Furniture Finisher'],
  [ServiceabilityFeatureOption.ABS492979, 'ABS 4929-79 Wood Tradespersons nec'],
  [ServiceabilityFeatureOption.ABS492981, 'ABS 4929-81 Apprentice Picture Framer'],
  [ServiceabilityFeatureOption.ABS492983, 'ABS 4929-83 Apprentice Furniture Finisher'],
  [ServiceabilityFeatureOption.ABS492999, 'ABS 4929-99 Apprentice Wood Tradespersons nec'],
  [ServiceabilityFeatureOption.ABS493101, 'ABS 4931-01 Supervisor, Hairdressers'],
  [ServiceabilityFeatureOption.ABS493111, 'ABS 4931-11 Hairdresser'],
  [ServiceabilityFeatureOption.ABS493181, 'ABS 4931-81 Apprentice Hairdresser'],
  [ServiceabilityFeatureOption.ABS494111, 'ABS 4941-11 General Clothing Tradesperson'],
  [ServiceabilityFeatureOption.ABS494113, 'ABS 4941-13 Tailor'],
  [ServiceabilityFeatureOption.ABS494115, 'ABS 4941-15 Dressmaker'],
  [ServiceabilityFeatureOption.ABS494117, 'ABS 4941-17 Apparel Cutter'],
  [ServiceabilityFeatureOption.ABS494119, 'ABS 4941-19 Patternmaker-Grader (Clothing)'],
  [ServiceabilityFeatureOption.ABS494179, 'ABS 4941-79 Clothing Tradespersons nec'],
  [ServiceabilityFeatureOption.ABS494181, 'ABS 4941-81 Apprentice Clothing Tradesperson'],
  [ServiceabilityFeatureOption.ABS494211, 'ABS 4942-11 Furniture Upholsterer'],
  [ServiceabilityFeatureOption.ABS494279, 'ABS 4942-79 Upholsterers and Bedding Tradespersons nec'],
  [ServiceabilityFeatureOption.ABS494281, 'ABS 4942-81 Apprentice Upholsterer or Bedding Tradespersons'],
  [ServiceabilityFeatureOption.ABS494311, 'ABS 4943-11 Shoemaker'],
  [ServiceabilityFeatureOption.ABS494313, 'ABS 4943-13 Medical Grade Shoemaker'],
  [ServiceabilityFeatureOption.ABS494381, 'ABS 4943-81 Apprentice Shoemaker'],
  [ServiceabilityFeatureOption.ABS494411, 'ABS 4944-11 Leather Goods Maker'],
  [ServiceabilityFeatureOption.ABS494413, 'ABS 4944-13 Canvas Goods Maker'],
  [ServiceabilityFeatureOption.ABS494415, 'ABS 4944-15 Sail Maker'],
  [ServiceabilityFeatureOption.ABS494481, 'ABS 4944-81 Apprentice Leather Goods, Canvas Goods or Sail Maker'],
  [ServiceabilityFeatureOption.ABS498111, 'ABS 4981-11 Shipwright'],
  [ServiceabilityFeatureOption.ABS498113, 'ABS 4981-13 Boat Builder and Repairer'],
  [ServiceabilityFeatureOption.ABS498181, 'ABS 4981-81 Apprentice Shipwright'],
  [ServiceabilityFeatureOption.ABS498183, 'ABS 4981-83 Apprentice Boat Builder and Repairer'],
  [ServiceabilityFeatureOption.ABS498211, 'ABS 4982-11 Flat Glass Tradesperson'],
  [ServiceabilityFeatureOption.ABS498213, 'ABS 4982-13 Glass Blower'],
  [ServiceabilityFeatureOption.ABS498281, 'ABS 4982-81 Apprentice Flat Glass Tradesperson'],
  [ServiceabilityFeatureOption.ABS498283, 'ABS 4982-83 Apprentice Glass Blower'],
  [ServiceabilityFeatureOption.ABS498311, 'ABS 4983-11 Jeweller'],
  [ServiceabilityFeatureOption.ABS498313, 'ABS 4983-13 Gem Cutter and Polisher'],
  [ServiceabilityFeatureOption.ABS498381, 'ABS 4983-81 Apprentice Jeweller'],
  [ServiceabilityFeatureOption.ABS498383, 'ABS 4983-83 Apprentice Gem Cutter and Polisher'],
  [ServiceabilityFeatureOption.ABS498411, 'ABS 4984-11 Florist'],
  [ServiceabilityFeatureOption.ABS498511, 'ABS 4985-11 Fire Fighter'],
  [ServiceabilityFeatureOption.ABS498601, 'ABS 4986-01 Supervisor, Drillers'],
  [ServiceabilityFeatureOption.ABS498611, 'ABS 4986-11 Driller'],
  [ServiceabilityFeatureOption.ABS498701, 'ABS 4987-01 Supervisor, Chemical, Petroleum and Gas Plant Operators'],
  [ServiceabilityFeatureOption.ABS498711, 'ABS 4987-11 Chemical Plant Operator'],
  [ServiceabilityFeatureOption.ABS498713, 'ABS 4987-13 Petroleum and Gas Plant Operator'],
  [ServiceabilityFeatureOption.ABS498801, 'ABS 4988-01 Supervisor, Power Generation Plant Operators'],
  [ServiceabilityFeatureOption.ABS498811, 'ABS 4988-11 Power Generation Plant Operator'],
  [ServiceabilityFeatureOption.ABS499111, 'ABS 4991-11 Defence Force Member Not Elsewhere Included'],
  [ServiceabilityFeatureOption.ABS499181, 'ABS 4991-81 Trainee Defence Force Member Not Elsewhere Included'],
  [ServiceabilityFeatureOption.ABS499211, 'ABS 4992-11 Sound Technician'],
  [ServiceabilityFeatureOption.ABS499213, 'ABS 4992-13 Camera Operator (Film, Television or Video)'],
  [ServiceabilityFeatureOption.ABS499215, 'ABS 4992-15 Television Equipment Operator'],
  [ServiceabilityFeatureOption.ABS499217, 'ABS 4992-17 Broadcast Transmitter Operator'],
  [ServiceabilityFeatureOption.ABS499219, 'ABS 4992-19 Motion Picture Projectionist'],
  [ServiceabilityFeatureOption.ABS499221, 'ABS 4992-21 Light Technician'],
  [ServiceabilityFeatureOption.ABS499223, 'ABS 4992-23 Production Assistant (Film, Television or Radio)'],
  [ServiceabilityFeatureOption.ABS499225, 'ABS 4992-25 Production Assistant (Theatre)'],
  [ServiceabilityFeatureOption.ABS499227, 'ABS 4992-27 Make Up Artist'],
  [ServiceabilityFeatureOption.ABS499279, 'ABS 4992-79 Performing Arts Support Workers nec'],
  [ServiceabilityFeatureOption.ABS499911, 'ABS 4999-11 Optical Mechanic'],
  [ServiceabilityFeatureOption.ABS499913, 'ABS 4999-13 Diver'],
  [ServiceabilityFeatureOption.ABS499915, 'ABS 4999-15 Aircraft Safety Equipment Worker'],
  [ServiceabilityFeatureOption.ABS499917, 'ABS 4999-17 Piano Tuner'],
  [ServiceabilityFeatureOption.ABS499979, 'ABS 4999-79 Tradespersons and Related Workers nec'],
  [ServiceabilityFeatureOption.ABS511111, 'ABS 5111-11 Secretary'],
  [ServiceabilityFeatureOption.ABS511113, 'ABS 5111-13 Personal Assistant'],
  [ServiceabilityFeatureOption.ABS591111, 'ABS 5911-11 Bookkeeper'],
  [ServiceabilityFeatureOption.ABS591211, 'ABS 5912-11 Credit and Loans Officer'],
  [ServiceabilityFeatureOption.ABS599111, 'ABS 5991-11 Clerk of Court'],
  [ServiceabilityFeatureOption.ABS599113, 'ABS 5991-13 Law Clerk'],
  [ServiceabilityFeatureOption.ABS599115, 'ABS 5991-15 Trust Officer'],
  [ServiceabilityFeatureOption.ABS599211, 'ABS 5992-11 Court or Hansard Reporter'],
  [ServiceabilityFeatureOption.ABS599311, 'ABS 5993-11 Insurance Agent'],
  [ServiceabilityFeatureOption.ABS599411, 'ABS 5994-11 Insurance Risk Surveyor'],
  [ServiceabilityFeatureOption.ABS599413, 'ABS 5994-13 Insurance Investigator'],
  [ServiceabilityFeatureOption.ABS599415, 'ABS 5994-15 Insurance Loss Adjuster'],
  [ServiceabilityFeatureOption.ABS599511, 'ABS 5995-11 Desktop Publishing Operator'],
  [ServiceabilityFeatureOption.ABS599611, 'ABS 5996-11 Flight Service Director'],
  [ServiceabilityFeatureOption.ABS599613, 'ABS 5996-13 Flight Attendant'],
  [ServiceabilityFeatureOption.ABS599679, 'ABS 5996-79 Travel Attendants nec'],
  [ServiceabilityFeatureOption.ABS599911, 'ABS 5999-11 Radio Despatcher'],
  [ServiceabilityFeatureOption.ABS599913, 'ABS 5999-13 Auctioneer'],
  [ServiceabilityFeatureOption.ABS599915, 'ABS 5999-15 Visual Merchandiser'],
  [ServiceabilityFeatureOption.ABS599917, 'ABS 5999-17 Photographer’s Assistant'],
  [ServiceabilityFeatureOption.ABS611111, 'ABS 6111-11 General Clerk'],
  [ServiceabilityFeatureOption.ABS612111, 'ABS 6121-11 Typist and Word Processing Operator'],
  [ServiceabilityFeatureOption.ABS612113, 'ABS 6121-13 Data Entry Operator'],
  [ServiceabilityFeatureOption.ABS613111, 'ABS 6131-11 Receptionist'],
  [ServiceabilityFeatureOption.ABS614101, 'ABS 6141-01 Supervisor, Accounting Clerks'],
  [ServiceabilityFeatureOption.ABS614111, 'ABS 6141-11 Accounts Clerk'],
  [ServiceabilityFeatureOption.ABS614113, 'ABS 6141-13 Credit Clerk'],
  [ServiceabilityFeatureOption.ABS614115, 'ABS 6141-15 Cost Clerk'],
  [ServiceabilityFeatureOption.ABS614211, 'ABS 6142-11 Payroll Clerk'],
  [ServiceabilityFeatureOption.ABS614301, 'ABS 6143-01 Supervisor, Bank Workers'],
  [ServiceabilityFeatureOption.ABS614311, 'ABS 6143-11 Bank Worker'],
  [ServiceabilityFeatureOption.ABS614401, 'ABS 6144-01 Supervisor, Insurance Clerks'],
  [ServiceabilityFeatureOption.ABS614411, 'ABS 6144-11 Insurance Clerk'],
  [ServiceabilityFeatureOption.ABS614501, 'ABS 6145-01 Supervisor, Money Market and Statistical Clerks'],
  [ServiceabilityFeatureOption.ABS614511, 'ABS 6145-11 Money Market Clerk'],
  [ServiceabilityFeatureOption.ABS614513, 'ABS 6145-13 Statistical Clerk'],
  [ServiceabilityFeatureOption.ABS615111, 'ABS 6151-11 Production Recording Clerk'],
  [ServiceabilityFeatureOption.ABS615201, 'ABS 6152-01 Supervisor, Transport and Despatching Clerks'],
  [ServiceabilityFeatureOption.ABS615211, 'ABS 6152-11 Receiving and Despatching Clerk'],
  [ServiceabilityFeatureOption.ABS615213, 'ABS 6152-13 Import-Export Clerk'],
  [ServiceabilityFeatureOption.ABS615301, 'ABS 6153-01 Supervisor, Stock and Purchasing Clerks'],
  [ServiceabilityFeatureOption.ABS615311, 'ABS 6153-11 Stock Clerk'],
  [ServiceabilityFeatureOption.ABS615313, 'ABS 6153-13 Purchasing Officer'],
  [ServiceabilityFeatureOption.ABS615315, 'ABS 6153-15 Order Clerk'],
  [ServiceabilityFeatureOption.ABS615317, 'ABS 6153-17 Sales Clerk'],
  [ServiceabilityFeatureOption.ABS619111, 'ABS 6191-11 Inquiry Clerk'],
  [ServiceabilityFeatureOption.ABS619113, 'ABS 6191-13 Admissions Clerk'],
  [ServiceabilityFeatureOption.ABS619211, 'ABS 6192-11 Library Assistant'],
  [ServiceabilityFeatureOption.ABS619311, 'ABS 6193-11 Personnel Records Clerk'],
  [ServiceabilityFeatureOption.ABS619313, 'ABS 6193-13 Employment Office Clerk'],
  [ServiceabilityFeatureOption.ABS619411, 'ABS 6194-11 Customs Inspector'],
  [ServiceabilityFeatureOption.ABS619413, 'ABS 6194-13 Taxation Inspector'],
  [ServiceabilityFeatureOption.ABS619415, 'ABS 6194-15 Social Security Assessor'],
  [ServiceabilityFeatureOption.ABS619417, 'ABS 6194-17 Motor Vehicle Licence Examiner'],
  [ServiceabilityFeatureOption.ABS619419, 'ABS 6194-19 Transport Operations Inspector'],
  [ServiceabilityFeatureOption.ABS619421, 'ABS 6194-21 Train Examiner'],
  [ServiceabilityFeatureOption.ABS619423, 'ABS 6194-23 Water Inspector'],
  [ServiceabilityFeatureOption.ABS619479, 'ABS 6194-79 Intermediate Inspectors and Examiners nec'],
  [ServiceabilityFeatureOption.ABS619911, 'ABS 6199-11 Proof Reader'],
  [ServiceabilityFeatureOption.ABS619913, 'ABS 6199-13 Debt Collector'],
  [ServiceabilityFeatureOption.ABS619915, 'ABS 6199-15 Mail Supervisor'],
  [ServiceabilityFeatureOption.ABS619917, 'ABS 6199-17 Court Orderly'],
  [ServiceabilityFeatureOption.ABS619919, 'ABS 6199-19 Bailiff or Sheriff'],
  [ServiceabilityFeatureOption.ABS619921, 'ABS 6199-21 Coding Clerk'],
  [ServiceabilityFeatureOption.ABS619979, 'ABS 6199-79 Intermediate Clerical Workers nec'],
  [ServiceabilityFeatureOption.ABS621111, 'ABS 6211-11 Sales Representative (Personal and Household Goods)'],
  [ServiceabilityFeatureOption.ABS621113, 'ABS 6211-13 Sales Representative (Business Services)'],
  [ServiceabilityFeatureOption.ABS621115, 'ABS 6211-15 Sales Representative (Builder’s and Plumber’s Supplies)'],
  [ServiceabilityFeatureOption.ABS621117, 'ABS 6211-17 Sales Representative (Motor Vehicle Parts and Accessories)'],
  [ServiceabilityFeatureOption.ABS621179, 'ABS 6211-79 Sales Representatives nec'],
  [ServiceabilityFeatureOption.ABS621211, 'ABS 6212-11 Motor Vehicle and Caravan Salesperson'],
  [ServiceabilityFeatureOption.ABS621213, 'ABS 6212-13 Motor Vehicle Parts Interpreter'],
  [ServiceabilityFeatureOption.ABS621311, 'ABS 6213-11 Retail Supervisor'],
  [ServiceabilityFeatureOption.ABS621313, 'ABS 6213-13 Checkout Supervisor'],
  [ServiceabilityFeatureOption.ABS631111, 'ABS 6311-11 Pre-School Aide'],
  [ServiceabilityFeatureOption.ABS631113, 'ABS 6311-13 Integration Aide'],
  [ServiceabilityFeatureOption.ABS631115, 'ABS 6311-15 Teachers’ Aide'],
  [ServiceabilityFeatureOption.ABS631117, 'ABS 6311-17 Aboriginal and Torres Strait Islander Education Worker'],
  [ServiceabilityFeatureOption.ABS631211, 'ABS 6312-11 Child Care Worker'],
  [ServiceabilityFeatureOption.ABS631213, 'ABS 6312-13 Family Day Care Worker'],
  [ServiceabilityFeatureOption.ABS631215, 'ABS 6312-15 Nanny'],
  [ServiceabilityFeatureOption.ABS631311, 'ABS 6313-11 Hostel Parent'],
  [ServiceabilityFeatureOption.ABS631313, 'ABS 6313-13 Child or Youth Residential Care Assistant'],
  [ServiceabilityFeatureOption.ABS631315, 'ABS 6313-15 Refuge Worker'],
  [ServiceabilityFeatureOption.ABS631317, 'ABS 6313-17 Aged or Disabled Person Carer'],
  [ServiceabilityFeatureOption.ABS631319, 'ABS 6313-19 Therapy Aide'],
  [ServiceabilityFeatureOption.ABS631411, 'ABS 6314-11 Personal Care Assistant'],
  [ServiceabilityFeatureOption.ABS631413, 'ABS 6314-13 Nursing Assistant'],
  [ServiceabilityFeatureOption.ABS632111, 'ABS 6321-11 Hotel Service Supervisor'],
  [ServiceabilityFeatureOption.ABS632201, 'ABS 6322-01 Supervisor, Bar Attendants'],
  [ServiceabilityFeatureOption.ABS632211, 'ABS 6322-11 Bar Attendant'],
  [ServiceabilityFeatureOption.ABS632301, 'ABS 6323-01 Supervisor, Waiters'],
  [ServiceabilityFeatureOption.ABS632311, 'ABS 6323-11 General Waiter'],
  [ServiceabilityFeatureOption.ABS632313, 'ABS 6323-13 Drink Waiter'],
  [ServiceabilityFeatureOption.ABS632411, 'ABS 6324-11 Hospitality Trainee'],
  [ServiceabilityFeatureOption.ABS639111, 'ABS 6391-11 Dental Assistant'],
  [ServiceabilityFeatureOption.ABS639211, 'ABS 6392-11 Veterinary Nurse'],
  [ServiceabilityFeatureOption.ABS639311, 'ABS 6393-11 Prison Officer'],
  [ServiceabilityFeatureOption.ABS639411, 'ABS 6394-11 Gaming Pit Boss'],
  [ServiceabilityFeatureOption.ABS639413, 'ABS 6394-13 Gaming Table Supervisor'],
  [ServiceabilityFeatureOption.ABS639415, 'ABS 6394-15 Gaming Dealer'],
  [ServiceabilityFeatureOption.ABS639511, 'ABS 6395-11 Beauty Therapist'],
  [ServiceabilityFeatureOption.ABS639513, 'ABS 6395-13 Natural Remedy Consultant'],
  [ServiceabilityFeatureOption.ABS639515, 'ABS 6395-15 Weight Loss Consultant'],
  [ServiceabilityFeatureOption.ABS639611, 'ABS 6396-11 Fitness Instructor'],
  [ServiceabilityFeatureOption.ABS639613, 'ABS 6396-13 Outdoor Adventure Leader'],
  [ServiceabilityFeatureOption.ABS639711, 'ABS 6397-11 Travel Agent'],
  [ServiceabilityFeatureOption.ABS639713, 'ABS 6397-13 Tourist Information Officer'],
  [ServiceabilityFeatureOption.ABS639715, 'ABS 6397-15 Tour Guide'],
  [ServiceabilityFeatureOption.ABS639911, 'ABS 6399-11 Museum or Gallery Attendant'],
  [ServiceabilityFeatureOption.ABS639913, 'ABS 6399-13 Driving Instructor'],
  [ServiceabilityFeatureOption.ABS639915, 'ABS 6399-15 Animal Attendant'],
  [ServiceabilityFeatureOption.ABS639917, 'ABS 6399-17 Pest and Weed Controller'],
  [ServiceabilityFeatureOption.ABS639979, 'ABS 6399-79 Intermediate Service Workers nec'],
  [ServiceabilityFeatureOption.ABS711111, 'ABS 7111-11 General Construction Plant Operator'],
  [ServiceabilityFeatureOption.ABS711113, 'ABS 7111-13 Bulldozer Operator'],
  [ServiceabilityFeatureOption.ABS711115, 'ABS 7111-15 Backhoe Operator'],
  [ServiceabilityFeatureOption.ABS711117, 'ABS 7111-17 Loader Operator'],
  [ServiceabilityFeatureOption.ABS711119, 'ABS 7111-19 Grader Operator'],
  [ServiceabilityFeatureOption.ABS711121, 'ABS 7111-21 Excavator Operator'],
  [ServiceabilityFeatureOption.ABS711123, 'ABS 7111-23 Paving Plant Operator'],
  [ServiceabilityFeatureOption.ABS711125, 'ABS 7111-25 Road Roller Operator'],
  [ServiceabilityFeatureOption.ABS711211, 'ABS 7112-11 Forklift Driver'],
  [ServiceabilityFeatureOption.ABS711911, 'ABS 7119-11 Agricultural and Horticultural Mobile Plant Operator'],
  [ServiceabilityFeatureOption.ABS711913, 'ABS 7119-13 Railway Track Repair Mobile Plant Operator'],
  [ServiceabilityFeatureOption.ABS711915, 'ABS 7119-15 Streetsweeper Operator'],
  [ServiceabilityFeatureOption.ABS711917, 'ABS 7119-17 Logging Plant Operator'],
  [ServiceabilityFeatureOption.ABS711979, 'ABS 7119-79 Mobile Plant Operators nec'],
  [ServiceabilityFeatureOption.ABS712111, 'ABS 7121-11 Engine or Boiler Operator'],
  [ServiceabilityFeatureOption.ABS712211, 'ABS 7122-11 Crane, Hoist or Lift Operator'],
  [ServiceabilityFeatureOption.ABS712301, 'ABS 7123-01 Supervisor, Engineering Production Systems Workers'],
  [ServiceabilityFeatureOption.ABS712311, 'ABS 7123-11 Engineering Production Systems Worker'],
  [ServiceabilityFeatureOption.ABS712411, 'ABS 7124-11 Pulp Mill Operator'],
  [ServiceabilityFeatureOption.ABS712413, 'ABS 7124-13 Paper Mill Operator'],
  [ServiceabilityFeatureOption.ABS712901, 'ABS 7129-01 Supervisor, Other Intermediate Stationary Plant Operators'],
  [ServiceabilityFeatureOption.ABS712911, 'ABS 7129-11 Railway Signal Operator'],
  [ServiceabilityFeatureOption.ABS712913, 'ABS 7129-13 Train Controller'],
  [ServiceabilityFeatureOption.ABS712915, 'ABS 7129-15 Concrete Pump Operator'],
  [ServiceabilityFeatureOption.ABS712917, 'ABS 7129-17 Cement Production Plant Operator'],
  [ServiceabilityFeatureOption.ABS712919, 'ABS 7129-19 Concrete Batching Plant Operator'],
  [ServiceabilityFeatureOption.ABS712921, 'ABS 7129-21 Water and Waste Water Plant Operator'],
  [ServiceabilityFeatureOption.ABS712923, 'ABS 7129-23 Bulk Materials Handling Plant Operator'],
  [ServiceabilityFeatureOption.ABS712979, 'ABS 7129-79 Intermediate Stationary Plant Operators nec'],
  [ServiceabilityFeatureOption.ABS721101, 'ABS 7211-01 Supervisor, Sewing Machinists'],
  [ServiceabilityFeatureOption.ABS721111, 'ABS 7211-11 Sewing Machinist'],
  [ServiceabilityFeatureOption.ABS721201, 'ABS 7212-01 Supervisor, Textile and Footwear Production Machine Operators'],
  [ServiceabilityFeatureOption.ABS721211, 'ABS 7212-11 Yarn Carding and Spinning Machine Operator'],
  [ServiceabilityFeatureOption.ABS721213, 'ABS 7212-13 Weaving Machine Operator'],
  [ServiceabilityFeatureOption.ABS721215, 'ABS 7212-15 Knitting Machine Operator'],
  [ServiceabilityFeatureOption.ABS721217, 'ABS 7212-17 Textile Dyeing and Finishing Machine Operator'],
  [ServiceabilityFeatureOption.ABS721219, 'ABS 7212-19 Hide and Skin Processing Machine Operator'],
  [ServiceabilityFeatureOption.ABS721221, 'ABS 7212-21 Footwear Production Machine Operator'],
  [ServiceabilityFeatureOption.ABS721279, 'ABS 7212-79 Textile and Footwear Production Machine Operators nec'],
  [ServiceabilityFeatureOption.ABS729101, 'ABS 7291-01 Supervisor, Plastics Production Machine Operators'],
  [ServiceabilityFeatureOption.ABS729111, 'ABS 7291-11 General Plastics Production Machine Operator'],
  [ServiceabilityFeatureOption.ABS729113, 'ABS 7291-13 Plastics Compounding and Reclamation Machine Operator'],
  [ServiceabilityFeatureOption.ABS729115, 'ABS 7291-15 Reinforced Plastic and Composite Production Worker'],
  [ServiceabilityFeatureOption.ABS729117, 'ABS 7291-17 Plastic Cablemaking Machine Operator'],
  [ServiceabilityFeatureOption.ABS729179, 'ABS 7291-79 Plastics Production Machine Operators nec'],
  [ServiceabilityFeatureOption.ABS729201, 'ABS 7292-01 Supervisor, Rubber Production Machine Operators'],
  [ServiceabilityFeatureOption.ABS729211, 'ABS 7292-11 Tyre Production Machine Operator'],
  [ServiceabilityFeatureOption.ABS729279, 'ABS 7292-79 Rubber Production Machine Operators nec'],
  [ServiceabilityFeatureOption.ABS729301, 'ABS 7293-01 Supervisor, Chemical Production Machine Operators'],
  [ServiceabilityFeatureOption.ABS729311, 'ABS 7293-11 Chemical Production Machine Operator'],
  [ServiceabilityFeatureOption.ABS729401, 'ABS 7294-01 Supervisor, Wood Processing Machine Operators'],
  [ServiceabilityFeatureOption.ABS729411, 'ABS 7294-11 Wood Processing Machine Operator'],
  [ServiceabilityFeatureOption.ABS729511, 'ABS 7295-11 Paper Products Machine Operator'],
  [ServiceabilityFeatureOption.ABS729601, 'ABS 7296-01 Supervisor, Glass Production Machine Operators'],
  [ServiceabilityFeatureOption.ABS729611, 'ABS 7296-11 Glass Production Machine Operator'],
  [
    ServiceabilityFeatureOption.ABS729701,
    'ABS 7297-01 Supervisor, Clay, Stone and Concrete Processing Machine Operators',
  ],
  [ServiceabilityFeatureOption.ABS729711, 'ABS 7297-11 Clay Products Machine Operator'],
  [ServiceabilityFeatureOption.ABS729713, 'ABS 7297-13 Stone Processing Machine Operator'],
  [ServiceabilityFeatureOption.ABS729715, 'ABS 7297-15 Concrete Products Machine Operator'],
  [ServiceabilityFeatureOption.ABS729779, 'ABS 7297-79 Clay, Stone and Concrete Processing Machine Operators nec'],
  [ServiceabilityFeatureOption.ABS729811, 'ABS 7298-11 Photographic Developer and Printer'],
  [ServiceabilityFeatureOption.ABS729911, 'ABS 7299-11 Industrial Spray Painter'],
  [ServiceabilityFeatureOption.ABS729979, 'ABS 7299-79 Intermediate Machine Operators nec'],
  [ServiceabilityFeatureOption.ABS731111, 'ABS 7311-11 Heavy Truck Driver'],
  [ServiceabilityFeatureOption.ABS731113, 'ABS 7311-13 Furniture Removalist'],
  [ServiceabilityFeatureOption.ABS731211, 'ABS 7312-11 Bus Driver'],
  [ServiceabilityFeatureOption.ABS731213, 'ABS 7312-13 Tram Driver'],
  [ServiceabilityFeatureOption.ABS731311, 'ABS 7313-11 Automobile Driver'],
  [ServiceabilityFeatureOption.ABS731411, 'ABS 7314-11 Delivery Driver'],
  [ServiceabilityFeatureOption.ABS731511, 'ABS 7315-11 Train Driver'],
  [ServiceabilityFeatureOption.ABS731513, 'ABS 7315-13 Train Driver’s Assistant'],
  [ServiceabilityFeatureOption.ABS791111, 'ABS 7911-11 Miner'],
  [ServiceabilityFeatureOption.ABS791211, 'ABS 7912-11 Blasting Worker'],
  [ServiceabilityFeatureOption.ABS791311, 'ABS 7913-11 Scaffolder'],
  [ServiceabilityFeatureOption.ABS791313, 'ABS 7913-13 Steel Fixer'],
  [ServiceabilityFeatureOption.ABS791315, 'ABS 7913-15 Structural Steel Erector'],
  [ServiceabilityFeatureOption.ABS791317, 'ABS 7913-17 Construction Rigger'],
  [ServiceabilityFeatureOption.ABS791411, 'ABS 7914-11 Building Insulation Installer'],
  [ServiceabilityFeatureOption.ABS791413, 'ABS 7914-13 Home Improvements Installer'],
  [ServiceabilityFeatureOption.ABS799111, 'ABS 7991-11 Motor Vehicle Parts and Accessories Fitter'],
  [ServiceabilityFeatureOption.ABS799211, 'ABS 7992-11 Product Examiner'],
  [ServiceabilityFeatureOption.ABS799213, 'ABS 7992-13 Product Grader'],
  [ServiceabilityFeatureOption.ABS799215, 'ABS 7992-15 Product Tester'],
  [ServiceabilityFeatureOption.ABS799301, 'ABS 7993-01 Supervisor, Storepersons'],
  [ServiceabilityFeatureOption.ABS799311, 'ABS 7993-11 Storeperson'],
  [ServiceabilityFeatureOption.ABS799411, 'ABS 7994-11 Seafarer'],
  [ServiceabilityFeatureOption.ABS799413, 'ABS 7994-13 Fishing Hand'],
  [ServiceabilityFeatureOption.ABS799501, 'ABS 7995-01 Supervisor, Forestry and Logging Workers'],
  [ServiceabilityFeatureOption.ABS799511, 'ABS 7995-11 Tree Faller'],
  [ServiceabilityFeatureOption.ABS799513, 'ABS 7995-13 Forestry Worker'],
  [ServiceabilityFeatureOption.ABS799515, 'ABS 7995-15 Logging Assistant'],
  [ServiceabilityFeatureOption.ABS799611, 'ABS 7996-11 Printing Table Hand'],
  [ServiceabilityFeatureOption.ABS799613, 'ABS 7996-13 Printer’s Assistant'],
  [ServiceabilityFeatureOption.ABS811111, 'ABS 8111-11 Registry or Filing Clerk'],
  [ServiceabilityFeatureOption.ABS811211, 'ABS 8112-11 Mail Clerk'],
  [ServiceabilityFeatureOption.ABS811213, 'ABS 8112-13 Postal Sorting Officer'],
  [ServiceabilityFeatureOption.ABS811311, 'ABS 8113-11 Switchboard Operator'],
  [ServiceabilityFeatureOption.ABS811411, 'ABS 8114-11 Courier'],
  [ServiceabilityFeatureOption.ABS811413, 'ABS 8114-13 Postal Delivery Officer'],
  [ServiceabilityFeatureOption.ABS811511, 'ABS 8115-11 Betting Agency Counter Clerk'],
  [ServiceabilityFeatureOption.ABS811513, 'ABS 8115-13 Telephone Betting Clerk'],
  [ServiceabilityFeatureOption.ABS811515, 'ABS 8115-15 Bookmaker’s Clerk'],
  [ServiceabilityFeatureOption.ABS811579, 'ABS 8115-79 Betting Clerks nec'],
  [ServiceabilityFeatureOption.ABS811611, 'ABS 8116-11 Office Trainee'],
  [ServiceabilityFeatureOption.ABS811911, 'ABS 8119-11 Meter Reader'],
  [ServiceabilityFeatureOption.ABS811913, 'ABS 8119-13 Classified Advertising Clerk'],
  [ServiceabilityFeatureOption.ABS811915, 'ABS 8119-15 Interviewer'],
  [ServiceabilityFeatureOption.ABS811917, 'ABS 8119-17 Parking Inspector'],
  [ServiceabilityFeatureOption.ABS811979, 'ABS 8119-79 Elementary Clerks nec'],
  [ServiceabilityFeatureOption.ABS821111, 'ABS 8211-11 Sales Assistant (Food and Drink Products)'],
  [ServiceabilityFeatureOption.ABS821113, 'ABS 8211-13 Sales Assistant (Fabric, Clothing and Footwear)'],
  [ServiceabilityFeatureOption.ABS821115, 'ABS 8211-15 Sales Assistant (Other Personal and Household Goods)'],
  [ServiceabilityFeatureOption.ABS821117, 'ABS 8211-17 Sales Assistant (Postal Services)'],
  [ServiceabilityFeatureOption.ABS821179, 'ABS 8211-79 Sales Assistants nec'],
  [ServiceabilityFeatureOption.ABS829111, 'ABS 8291-11 Checkout Operator'],
  [ServiceabilityFeatureOption.ABS829113, 'ABS 8291-13 Office Cashier'],
  [ServiceabilityFeatureOption.ABS829211, 'ABS 8292-11 Ticket Seller'],
  [ServiceabilityFeatureOption.ABS829213, 'ABS 8292-13 Transport Conductor'],
  [ServiceabilityFeatureOption.ABS829311, 'ABS 8293-11 Street Vendor'],
  [ServiceabilityFeatureOption.ABS829313, 'ABS 8293-13 Cash Van Salesperson'],
  [ServiceabilityFeatureOption.ABS829315, 'ABS 8293-15 Door-to-Door Salesperson'],
  [ServiceabilityFeatureOption.ABS829411, 'ABS 8294-11 Telemarketer'],
  [ServiceabilityFeatureOption.ABS829511, 'ABS 8295-11 Sales Demonstrator'],
  [ServiceabilityFeatureOption.ABS829513, 'ABS 8295-13 Model'],
  [ServiceabilityFeatureOption.ABS829611, 'ABS 8296-11 Service Station Attendant'],
  [ServiceabilityFeatureOption.ABS829711, 'ABS 8297-11 Sales and Service Trainee'],
  [ServiceabilityFeatureOption.ABS829911, 'ABS 8299-11 Rental Salesperson'],
  [ServiceabilityFeatureOption.ABS829913, 'ABS 8299-13 Materials Recycler'],
  [ServiceabilityFeatureOption.ABS829979, 'ABS 8299-79 Elementary Sales Workers nec'],
  [ServiceabilityFeatureOption.ABS831111, 'ABS 8311-11 Security Officer'],
  [ServiceabilityFeatureOption.ABS831113, 'ABS 8311-13 Armoured Car Escort'],
  [ServiceabilityFeatureOption.ABS831179, 'ABS 8311-79 Guards and Security Officers nec'],
  [ServiceabilityFeatureOption.ABS831211, 'ABS 8312-11 Ticket Collector or Usher'],
  [ServiceabilityFeatureOption.ABS831213, 'ABS 8312-13 Luggage Porter or Doorperson'],
  [ServiceabilityFeatureOption.ABS831311, 'ABS 8313-11 Domestic Housekeeper'],
  [ServiceabilityFeatureOption.ABS831411, 'ABS 8314-11 Caretaker'],
  [ServiceabilityFeatureOption.ABS831511, 'ABS 8315-11 Laundry Worker'],
  [ServiceabilityFeatureOption.ABS831911, 'ABS 8319-11 Railways Assistant'],
  [ServiceabilityFeatureOption.ABS831913, 'ABS 8319-13 Hair and Beauty Salon Assistant'],
  [ServiceabilityFeatureOption.ABS831915, 'ABS 8319-15 Sterilisation Technology Worker'],
  [ServiceabilityFeatureOption.ABS831917, 'ABS 8319-17 Car Park Attendant'],
  [ServiceabilityFeatureOption.ABS831919, 'ABS 8319-19 Crossing Supervisor'],
  [ServiceabilityFeatureOption.ABS831921, 'ABS 8319-21 Trolley Collector'],
  [ServiceabilityFeatureOption.ABS831923, 'ABS 8319-23 Leaflet and Newspaper Deliverer'],
  [ServiceabilityFeatureOption.ABS831925, 'ABS 8319-25 Examination Supervisor'],
  [ServiceabilityFeatureOption.ABS831927, 'ABS 8319-27 Prostitute or Escort'],
  [ServiceabilityFeatureOption.ABS831979, 'ABS 8319-79 Elementary Service Workers nec'],
  [ServiceabilityFeatureOption.ABS911111, 'ABS 9111-11 Commercial Cleaner'],
  [ServiceabilityFeatureOption.ABS911113, 'ABS 9111-13 Domestic Cleaner'],
  [ServiceabilityFeatureOption.ABS911115, 'ABS 9111-15 Carpet Cleaner'],
  [ServiceabilityFeatureOption.ABS911117, 'ABS 9111-17 Vehicle Cleaner'],
  [ServiceabilityFeatureOption.ABS911119, 'ABS 9111-19 Window Cleaner'],
  [ServiceabilityFeatureOption.ABS911179, 'ABS 9111-79 Cleaners nec'],
  [ServiceabilityFeatureOption.ABS921111, 'ABS 9211-11 Engineering Production Process Worker'],
  [ServiceabilityFeatureOption.ABS921211, 'ABS 9212-11 Product Assembler'],
  [ServiceabilityFeatureOption.ABS921311, 'ABS 9213-11 Meatworks Labourer'],
  [ServiceabilityFeatureOption.ABS921313, 'ABS 9213-13 Meat Boner and Slicer'],
  [ServiceabilityFeatureOption.ABS921315, 'ABS 9213-15 Poultry Process Worker'],
  [ServiceabilityFeatureOption.ABS921317, 'ABS 9213-17 Fish Process Worker'],
  [ServiceabilityFeatureOption.ABS921411, 'ABS 9214-11 Food and Drink Processing Machine Attendant'],
  [ServiceabilityFeatureOption.ABS921413, 'ABS 9214-13 Baking Factory Hand'],
  [ServiceabilityFeatureOption.ABS921415, 'ABS 9214-15 Dairy Factory Hand'],
  [ServiceabilityFeatureOption.ABS921417, 'ABS 9214-17 Fruit and Vegetable Factory Hand'],
  [ServiceabilityFeatureOption.ABS921419, 'ABS 9214-19 Food Products Millhand'],
  [ServiceabilityFeatureOption.ABS921479, 'ABS 9214-79 Food Factory Hands nec'],
  [ServiceabilityFeatureOption.ABS921511, 'ABS 9215-11 Sawmill Labourer'],
  [ServiceabilityFeatureOption.ABS921513, 'ABS 9215-13 Timberyard Labourer'],
  [ServiceabilityFeatureOption.ABS921515, 'ABS 9215-15 Wood and Wood Products Factory Hand'],
  [ServiceabilityFeatureOption.ABS921517, 'ABS 9215-17 Pulp and Paper Mill General Hand'],
  [ServiceabilityFeatureOption.ABS921911, 'ABS 9219-11 Cement and Concrete Plant Labourer'],
  [ServiceabilityFeatureOption.ABS921913, 'ABS 9219-13 Clay Processing Factory Hand'],
  [ServiceabilityFeatureOption.ABS921915, 'ABS 9219-15 Chemical Plant Labourer'],
  [ServiceabilityFeatureOption.ABS921917, 'ABS 9219-17 Rubber and Plastics Factory Hand'],
  [ServiceabilityFeatureOption.ABS921919, 'ABS 9219-19 Hide and Skin Processing Labourer'],
  [ServiceabilityFeatureOption.ABS921921, 'ABS 9219-21 Glass Processing Labourer'],
  [ServiceabilityFeatureOption.ABS921923, 'ABS 9219-23 Fabric and Textile Factory Hand'],
  [ServiceabilityFeatureOption.ABS921925, 'ABS 9219-25 Footwear Factory Hand'],
  [ServiceabilityFeatureOption.ABS921979, 'ABS 9219-79 Process Workers nec'],
  [ServiceabilityFeatureOption.ABS922111, 'ABS 9221-11 Hand Packer'],
  [ServiceabilityFeatureOption.ABS922211, 'ABS 9222-11 Packager and Container Filler'],
  [ServiceabilityFeatureOption.ABS991111, 'ABS 9911-11 Mining Support Worker'],
  [ServiceabilityFeatureOption.ABS991113, 'ABS 9911-13 Driller’s Assistant'],
  [ServiceabilityFeatureOption.ABS991211, 'ABS 9912-11 Earthmoving Labourer'],
  [ServiceabilityFeatureOption.ABS991311, 'ABS 9913-11 Paving and Surfacing Labourer'],
  [ServiceabilityFeatureOption.ABS991411, 'ABS 9914-11 Survey Hand'],
  [ServiceabilityFeatureOption.ABS991511, 'ABS 9915-11 Railway Labourer'],
  [ServiceabilityFeatureOption.ABS991611, 'ABS 9916-11 Construction Assistant'],
  [ServiceabilityFeatureOption.ABS991613, 'ABS 9916-13 Plumber’s Assistant'],
  [ServiceabilityFeatureOption.ABS991711, 'ABS 9917-11 Concreter'],
  [ServiceabilityFeatureOption.ABS991811, 'ABS 9918-11 Electrical or Telecommunications Trades Assistant'],
  [ServiceabilityFeatureOption.ABS991911, 'ABS 9919-11 Lagger'],
  [ServiceabilityFeatureOption.ABS991913, 'ABS 9919-13 Crane Chaser'],
  [ServiceabilityFeatureOption.ABS991915, 'ABS 9919-15 Fence Erector'],
  [ServiceabilityFeatureOption.ABS992111, 'ABS 9921-11 General Farm Hand'],
  [ServiceabilityFeatureOption.ABS992113, 'ABS 9921-13 Fruit, Vegetable or Nut Farm Hand'],
  [ServiceabilityFeatureOption.ABS992115, 'ABS 9921-15 Stud Hand or Stable Hand'],
  [ServiceabilityFeatureOption.ABS992117, 'ABS 9921-17 Shearing Shed Hand'],
  [ServiceabilityFeatureOption.ABS992179, 'ABS 9921-79 Farm Hands nec'],
  [ServiceabilityFeatureOption.ABS992211, 'ABS 9922-11 Horticultural Nursery Assistant'],
  [ServiceabilityFeatureOption.ABS992213, 'ABS 9922-13 Garden Labourer'],
  [ServiceabilityFeatureOption.ABS992911, 'ABS 9929-11 Shooter-Trapper'],
  [ServiceabilityFeatureOption.ABS992913, 'ABS 9929-13 Rural Trainee'],
  [ServiceabilityFeatureOption.ABS992979, 'ABS 9929-79 Agricultural and Horticultural Labourers nec'],
  [ServiceabilityFeatureOption.ABS993111, 'ABS 9931-11 Kitchenhand'],
  [ServiceabilityFeatureOption.ABS993211, 'ABS 9932-11 Fast Food Cook'],
  [ServiceabilityFeatureOption.ABS993311, 'ABS 9933-11 Pastrycook’s Assistant'],
  [ServiceabilityFeatureOption.ABS993379, 'ABS 9933-79 Food Trades Assistants nec'],
  [ServiceabilityFeatureOption.ABS999111, 'ABS 9991-11 Garbage Collector'],
  [ServiceabilityFeatureOption.ABS999211, 'ABS 9992-11 Freight Handler (Road and Rail)'],
  [ServiceabilityFeatureOption.ABS999213, 'ABS 9992-13 Waterside Worker'],
  [ServiceabilityFeatureOption.ABS999215, 'ABS 9992-15 Truck Driver’s Offsider'],
  [ServiceabilityFeatureOption.ABS999311, 'ABS 9993-11 Handyperson'],
  [ServiceabilityFeatureOption.ABS999911, 'ABS 9999-11 Vending Machine Attendant'],
  [ServiceabilityFeatureOption.ABS999913, 'ABS 9999-13 Sign Erector'],
  [ServiceabilityFeatureOption.ABS999915, 'ABS 9999-15 Mechanic’s Assistant'],
  [ServiceabilityFeatureOption.ABS999917, 'ABS 9999-17 Stormwater, Drainage and Sewerage System Labourer'],
  [ServiceabilityFeatureOption.ABS999979, 'ABS 9999-79 Labourers and Related Workers nec'],
  [ServiceabilityFeatureOption.HEMYes, 'Yes'],
  [ServiceabilityFeatureOption.HEMNo, 'No'],
  [ServiceabilityFeatureOption.CapitalizedToPropertyValueYes, 'Yes'],
  [ServiceabilityFeatureOption.CapitalizedToPropertyValueNo, 'No'],
  [ServiceabilityFeatureOption.Residential, 'Residential'],
  [ServiceabilityFeatureOption.Industrial, 'Industrial'],
  [ServiceabilityFeatureOption.Commercial, 'Commercial'],
  [ServiceabilityFeatureOption.Rural, 'Rural'],
  [ServiceabilityFeatureOption.NRASYes, 'Yes'],
  [ServiceabilityFeatureOption.NRASNo, 'No'],
  [ServiceabilityFeatureOption.Australia, 'Australia'],
  [ServiceabilityFeatureOption.NewZealand, 'New Zealand'],
  [ServiceabilityFeatureOption.Other, 'Other'],
  [ServiceabilityFeatureOption.InvestmentCostsCompareToCapitalizedRentalIncomeYes, 'Yes'],
  [ServiceabilityFeatureOption.InvestmentCostsCompareToCapitalizedRentalIncomeNo, 'No'],
  [ServiceabilityFeatureOption.ShortStayIncomeYes, 'Yes'],
  [ServiceabilityFeatureOption.ShortStayIncomeNo, 'No'],
  [ServiceabilityFeatureOption.IncomeRecent, 'Income recent'],
  [ServiceabilityFeatureOption.IncomePrevious, 'Income previous'],
  [ServiceabilityFeatureOption.IncomePrior, 'Income prior'],
  [ServiceabilityFeatureOption.IncomeRecentAndPrevious, 'Income recent and previous'],
  [ServiceabilityFeatureOption.IncomePreviousAndPrior, 'Income previous and prior'],
  [ServiceabilityFeatureOption.IncomeAny, 'Income any'],
  //[ServiceabilityFeatureOption.IncomeYTD, 'Income YTD'],
  //[ServiceabilityFeatureOption.IncomeYTDAndRecent, 'Income YTD and recent'],
  [ServiceabilityFeatureOption.AgedCareUnit, 'Aged Care Unit'],
  [ServiceabilityFeatureOption.ApartmentUnitFlat, 'Apartment Unit Flat'],
  [ServiceabilityFeatureOption.BedsitterBachelor, 'Bedsitter Bachelor'],
  [ServiceabilityFeatureOption.CompanyTitleUnit, 'Company Title Unit'],
  [ServiceabilityFeatureOption.ConvertedCommercialProperty, 'Converted Commercial Property'],
  [ServiceabilityFeatureOption.ConvertedIndustrialProperty, 'Converted Industrial Property'],
  [ServiceabilityFeatureOption.ConvertedMotelUnits, 'Converted Motel Units'],
  [ServiceabilityFeatureOption.DisplayHouse, 'Display House'],
  [ServiceabilityFeatureOption.Duplex, 'Duplex'],
  [ServiceabilityFeatureOption.FullyDetachedHouse, 'Fully Detached House'],
  [ServiceabilityFeatureOption.HighDensityApartment, 'High Density Apartment'],
  [ServiceabilityFeatureOption.HolidayHome, 'Holiday Home'],
  [ServiceabilityFeatureOption.HolidayRental, 'Holiday Rental'],
  [ServiceabilityFeatureOption.ImprovedLand, 'Improved Land'],
  [ServiceabilityFeatureOption.KitHome, 'Kit Home'],
  [ServiceabilityFeatureOption.LowDensityApartment, 'Low Density Apartment'],
  [ServiceabilityFeatureOption.LuxuryHouse, 'Luxury House'],
  [ServiceabilityFeatureOption.LuxuryOther, 'Luxury Other'],
  [ServiceabilityFeatureOption.MediumDensityApartment, 'Medium Density Apartment'],
  [ServiceabilityFeatureOption.PropertyDevelopment, 'Property Development'],
  [ServiceabilityFeatureOption.RelocatableHome, 'Relocatable Home'],
  [ServiceabilityFeatureOption.ResortUnit, 'Resort Unit'],
  [ServiceabilityFeatureOption.SemiDetachedHouse, 'Semi Detached House'],
  [ServiceabilityFeatureOption.ServicedApartment, 'Serviced Apartment'],
  [ServiceabilityFeatureOption.StrataHotelUnit, 'Strata Hotel Unit'],
  [ServiceabilityFeatureOption.StrataTitleUnit, 'Strata Title Unit'],
  [ServiceabilityFeatureOption.StudioWarehouseApartment, 'Studio Warehouse Apartment'],
  [ServiceabilityFeatureOption.Terrace, 'Terrace'],
  [ServiceabilityFeatureOption.Townhouse, 'Townhouse'],
  [ServiceabilityFeatureOption.UnitStudentAccommodation, 'Unit Student Accommodation'],
  [ServiceabilityFeatureOption.VacantLand, 'Vacant Land'],
  [ServiceabilityFeatureOption.Villa, 'Villa'],
  [ServiceabilityFeatureOption.SingleUnitYes, 'Yes'],
  [ServiceabilityFeatureOption.SingleUnitNo, 'No'],
  [ServiceabilityFeatureOption.Boarding, 'Boarding'],
  [ServiceabilityFeatureOption.Caravan, 'Caravan'],
  [ServiceabilityFeatureOption.OtherHousing, 'Other'],
  [ServiceabilityFeatureOption.OwnHome, 'Own Home'],
  [ServiceabilityFeatureOption.OwnHomeMortgage, 'Own Home Mortgage'],
  [ServiceabilityFeatureOption.Renting, 'Renting'],
  [ServiceabilityFeatureOption.SuppliedbyEmployer, 'Supplied by Employer'],
  [ServiceabilityFeatureOption.WithParents, 'With Parents'],
  [ServiceabilityFeatureOption.SpecialDisabilityAccommodationYes, 'Yes'],
  [ServiceabilityFeatureOption.SpecialDisabilityAccommodationNo, 'No'],
  [ServiceabilityFeatureOption.NDISYes, 'Yes'],
  [ServiceabilityFeatureOption.NDISNo, 'No'],
  [ServiceabilityFeatureOption.PropertyPurposeBusiness, 'Business'],
  [ServiceabilityFeatureOption.PropertyPurposeInvestment, 'Investment'],
  [ServiceabilityFeatureOption.PropertyPurposeOwnerOccupied, 'Owner Occupied'],
  [ServiceabilityFeatureOption.HolidayRentalYes, 'Yes'],
  [ServiceabilityFeatureOption.HolidayRentalNo, 'No'],
  [ServiceabilityFeatureOption.PaidInFullYes, 'Yes'],
  [ServiceabilityFeatureOption.PaidInFullNo, 'No'],
  [ServiceabilityFeatureOption.HasSplitLoansYes, 'Yes'],
  [ServiceabilityFeatureOption.HasSplitLoansNo, 'No'],
  [ServiceabilityFeatureOption.Company, 'Company'],
  [ServiceabilityFeatureOption.Partnership, 'Partnership'],
  [ServiceabilityFeatureOption.SoleTrader, 'SoleTrader'],
  [ServiceabilityFeatureOption.Trust, 'Trust'],
  [ServiceabilityFeatureOption.OriginalTermFixedInterestType, 'Fixed Rate'],
  [ServiceabilityFeatureOption.OriginalTermVariableInterestType, 'Variable'],
  [ServiceabilityFeatureOption.FullDoc, 'Full doc'],
  [ServiceabilityFeatureOption.LoDoc, 'Lo doc'],
  [ServiceabilityFeatureOption.NoDoc, 'No doc'],
  [ServiceabilityFeatureOption.AltDoc, 'Alt doc'],
]);

export enum ServiceabilityIncomeType {
  GrossSalary = 1,
  GrossRegularOvertime = 2,
  Bonus = 3,
  Commission = 4,
  CarAllowance = 5,
  WorkAllowance = 6,
  WorkersCompensation = 7,
  FullyMaintainedCar = 8,
  ProfitBeforeTax = 9,
  SalaryAddback = 10,
  BonusAddback = 11,
  AllowancesAddback = 12,
  DepreciationAddback = 13,
  InterestAddback = 14,
  LeaseAddback = 15,
  NonCashBenefitsAddback = 16,
  NonRecurringExpensesAddback = 17,
  CarExpenseAddback = 18,
  SuperannuationExcessAddback = 19,
  CarryForwardLossesAddback = 20,
  AmortisationOfGoodwillAddback = 21,
  UnemploymentBenefits = 22,
  PrivatePension = 23,
  Superannuation = 24,
  OtherIncome = 25,
  PaymentToDirectorAddback = 26,
  PaymentToTrusteeAddback = 27,
  ExistingRentalIncome = 28,
  ProposedRentalIncome = 29,
  IncludeInPAHouseholdIncome = 30,
  IncludeInCAHouseholdIncome = 31,
  IncludeInTAHouseholdIncome = 32,
  Annuities = 33,
  ChildMaintenance = 34,
  Dividends = 35,
  ForeignSourced = 36,
  GovernmentBenefitsAbstudy = 37,
  GovernmentBenefitsAgePension = 38,
  GovernmentBenefitsAustudy = 39,
  GovernmentBenefitsCarerPayment = 40,
  GovernmentBenefitsChildSupport = 41,
  GovernmentBenefitsCrisisPayment = 42,
  GovernmentBenefitsDisabilitySupportPension = 43,
  GovernmentBenefitsFamilyAllowance = 44,
  GovernmentBenefitsMobilityAllowance = 45,
  GovernmentBenefitsParentingPayment = 46,
  GovernmentBenefitsWidowsAllowance = 47,
  GovernmentBenefitsYouthAllowance = 48,
  GovernmentBenefitsOther = 49,
  InterestIncome = 50,
  SuperConcessionalContribution = 51,
  SuperExcessConcessionalContribution = 52,
  SuperNonConcessionalContribution = 53,
  HomeOfficeAddback = 54,
  JobseekerSupport = 55,
  OtherAddback = 56,
  Other = 57,
  SuperannuationConcessionalContributionAddback = 58,
  SuperannuationNonConcessionalContributionAddback = 59,
}

export const ServiceabilityIncomeTypeLabel = new Map<number, string>([
  [ServiceabilityIncomeType.GrossSalary, 'Gross salary'],
  [ServiceabilityIncomeType.GrossRegularOvertime, 'Gross regular overtime'],
  [ServiceabilityIncomeType.Bonus, 'Bonus'],
  [ServiceabilityIncomeType.Commission, 'Commission'],
  [ServiceabilityIncomeType.CarAllowance, 'Car allowance'],
  [ServiceabilityIncomeType.WorkAllowance, 'Work allowance'],
  [ServiceabilityIncomeType.WorkersCompensation, 'Workers compensation'],
  [ServiceabilityIncomeType.FullyMaintainedCar, 'Fully maintained car'],
  [ServiceabilityIncomeType.ProfitBeforeTax, 'Profit before tax'],
  [ServiceabilityIncomeType.SalaryAddback, 'Salary addback'],
  [ServiceabilityIncomeType.BonusAddback, 'Bonus addback'],
  [ServiceabilityIncomeType.AllowancesAddback, 'Allowances addback'],
  [ServiceabilityIncomeType.DepreciationAddback, 'Depreciation addback'],
  [ServiceabilityIncomeType.InterestAddback, 'Interest addback'],
  [ServiceabilityIncomeType.LeaseAddback, 'Lease addback'],
  [ServiceabilityIncomeType.NonCashBenefitsAddback, 'Non cash benefits addback'],
  [ServiceabilityIncomeType.NonRecurringExpensesAddback, 'Non recurring expenses addback'],
  [ServiceabilityIncomeType.CarExpenseAddback, 'Car expense addback'],
  [ServiceabilityIncomeType.SuperannuationExcessAddback, 'Superannuation excess addback'],
  [ServiceabilityIncomeType.CarryForwardLossesAddback, 'Carry forward losses addback'],
  [ServiceabilityIncomeType.AmortisationOfGoodwillAddback, 'Amortisation of goodwill addback'],
  [ServiceabilityIncomeType.UnemploymentBenefits, 'Unemployment benefits'],
  [ServiceabilityIncomeType.PrivatePension, 'Private pension'],
  [ServiceabilityIncomeType.Superannuation, 'Superannuation'],
  [ServiceabilityIncomeType.OtherIncome, 'Other income'],
  [ServiceabilityIncomeType.PaymentToDirectorAddback, 'Payment to director addback'],
  [ServiceabilityIncomeType.PaymentToTrusteeAddback, 'Payment to trustee addback'],
  [ServiceabilityIncomeType.ExistingRentalIncome, 'Existing rental income'],
  [ServiceabilityIncomeType.ProposedRentalIncome, 'Proposed rental income'],
  [ServiceabilityIncomeType.IncludeInPAHouseholdIncome, 'Include in PA household income'],
  [ServiceabilityIncomeType.IncludeInCAHouseholdIncome, 'Include in CA household income'],
  [ServiceabilityIncomeType.IncludeInTAHouseholdIncome, 'Include in TA household income'],
  [ServiceabilityIncomeType.Annuities, 'Annuities'],
  [ServiceabilityIncomeType.ChildMaintenance, 'Child maintenance'],
  [ServiceabilityIncomeType.Dividends, 'Dividends'],
  [ServiceabilityIncomeType.ForeignSourced, 'Foreign sourced'],
  [ServiceabilityIncomeType.GovernmentBenefitsAbstudy, 'Government benefits abstudy'],
  [ServiceabilityIncomeType.GovernmentBenefitsAgePension, 'Government benefits age pension'],
  [ServiceabilityIncomeType.GovernmentBenefitsAustudy, 'Government benefits austudy'],
  [ServiceabilityIncomeType.GovernmentBenefitsCarerPayment, 'Government benefits carer payment'],
  [ServiceabilityIncomeType.GovernmentBenefitsChildSupport, 'Government benefits child support'],
  [ServiceabilityIncomeType.GovernmentBenefitsCrisisPayment, 'Government benefits crisis payment'],
  [
    ServiceabilityIncomeType.GovernmentBenefitsDisabilitySupportPension,
    'Government benefits disability support pension',
  ],
  [ServiceabilityIncomeType.GovernmentBenefitsFamilyAllowance, 'Government benefits family allowance'],
  [ServiceabilityIncomeType.GovernmentBenefitsMobilityAllowance, 'Government benefits mobility allowance'],
  [ServiceabilityIncomeType.GovernmentBenefitsParentingPayment, 'Government benefits parenting payment'],
  [ServiceabilityIncomeType.GovernmentBenefitsWidowsAllowance, 'Government benefits widows allowance'],
  [ServiceabilityIncomeType.GovernmentBenefitsYouthAllowance, 'Government benefits youth allowance'],
  [ServiceabilityIncomeType.GovernmentBenefitsOther, 'Government benefits other'],
  [ServiceabilityIncomeType.InterestIncome, 'Interest income'],
  [ServiceabilityIncomeType.SuperConcessionalContribution, 'Super concessional contribution'],
  [ServiceabilityIncomeType.SuperExcessConcessionalContribution, 'Super excess concessional contribution'],
  [ServiceabilityIncomeType.SuperNonConcessionalContribution, 'Super non concessional contribution'],
  [ServiceabilityIncomeType.HomeOfficeAddback, 'Home office addback'],
  [ServiceabilityIncomeType.JobseekerSupport, 'Jobseeker support'],
  [ServiceabilityIncomeType.OtherAddback, 'Other addback'],
  [ServiceabilityIncomeType.Other, 'Other'],
  [
    ServiceabilityIncomeType.SuperannuationConcessionalContributionAddback,
    'Superannuation concessional contribution addback',
  ],
  [
    ServiceabilityIncomeType.SuperannuationNonConcessionalContributionAddback,
    'Superannuation non concessional contribution addback',
  ],
]);

export enum ServiceabilityIncomeYear {
  //YTD = 1, TODO: Hide YTD as it is not used currently. May add later if any lender required.
  IncomeRecent = 2,
  IncomePrevious = 3,
  IncomePrior = 4,
}

export const ServiceabilityIncomeYearLabel = new Map<number, string>([
  //[ServiceabilityIncomeYear.YTD, 'YTD (Annualised)'],
  [ServiceabilityIncomeYear.IncomeRecent, 'Income recent'],
  [ServiceabilityIncomeYear.IncomePrevious, 'Income previous'],
  [ServiceabilityIncomeYear.IncomePrior, 'Income prior'],
]);

export enum ServiceabilityIncomeAmountCalculationType {
  Actual = 1,
  Average = 2,
  Lower = 3,
  Percentage = 4,
}

export const ServiceabilityIncomeAmountCalculationTypeLabel = new Map<number, string>([
  [ServiceabilityIncomeAmountCalculationType.Actual, 'Actual'],
  [ServiceabilityIncomeAmountCalculationType.Average, 'Average'],
  [ServiceabilityIncomeAmountCalculationType.Lower, 'Lower'],
  [ServiceabilityIncomeAmountCalculationType.Percentage, 'Percentage'],
]);

export enum ServiceabilityNewFinancialCommitmentRepaymentCalculationType {
  PMTAndIPMT = 1,
  PMTExcludeIOTerm = 2,
  PMTForTotalTerm = 3,
  PMTForBenchmarkTerm = 4,
  PMTForAIR = 5,
  PMTExcludeIOTermAIR = 6,
  CreditLimit = 7,
}

export const ServiceabilityNewFinancialCommitmentRepaymentCalculationTypeLabel = new Map<number, string>([
  [ServiceabilityNewFinancialCommitmentRepaymentCalculationType.PMTAndIPMT, 'PMT for P&I and IPMT for IO'],
  [
    ServiceabilityNewFinancialCommitmentRepaymentCalculationType.PMTExcludeIOTerm,
    'PMT for P&I and IO (Loan Term = Total Term - Interest Only Term)',
  ],
  [
    ServiceabilityNewFinancialCommitmentRepaymentCalculationType.PMTForTotalTerm,
    'PMT for P&I and IO (Loan Term = Total Term)',
  ],
  [
    ServiceabilityNewFinancialCommitmentRepaymentCalculationType.PMTForBenchmarkTerm,
    'PMT for P&I and IO (Benchmark Term)',
  ],
  [ServiceabilityNewFinancialCommitmentRepaymentCalculationType.PMTForAIR, 'PMT for P&I and IO (AIR)'],
  [
    ServiceabilityNewFinancialCommitmentRepaymentCalculationType.PMTExcludeIOTermAIR,
    'PMT for P&I and IO (Loan Term = Total Term - Interest Only Term) AIR',
  ],
  [ServiceabilityNewFinancialCommitmentRepaymentCalculationType.CreditLimit, 'Credit limit'],
]);

export enum CompareOperator {
  Greater = 1,
  GreaterThanEqual = 2,
  Less = 3,
  LessThanEqual = 4,
}

export const CompareOperatorLabel = new Map<number, string>([
  [CompareOperator.Greater, '>'],
  [CompareOperator.GreaterThanEqual, '>='],
  [CompareOperator.Less, '<'],
  [CompareOperator.LessThanEqual, '<='],
]);

export enum BrandImageType {
  InfoRequest = 5,
  EmailHeader = 3,
  EmailFooter = 4,
}

export const BrandImageTypeLabel = new Map<number, string>([
  [BrandImageType.InfoRequest, 'Information Request'],
  [BrandImageType.EmailHeader, 'Email Header'],
  [BrandImageType.EmailFooter, 'Email Footer'],
]);

export enum DocumentActivity {
  SupportingDoc = 1,
  DecisionSupport = 2,
}

export const DocumentActivityLabel = new Map<number, string>([
  [DocumentActivity.SupportingDoc, 'Supporting documents'],
  [DocumentActivity.DecisionSupport, 'Document verification'],
]);

export enum ChecklistVerificationResult {
  Pass = 1,
  Fail = 2,
  Reject = 3,
  AllowException = 4,
}

export const ChecklistVerificationResultLabel = new Map<number, string>([
  [ChecklistVerificationResult.Pass, 'Pass'],
  [ChecklistVerificationResult.Fail, 'Fail'],
  [ChecklistVerificationResult.Reject, 'Reject'],
  [ChecklistVerificationResult.AllowException, 'Allow exception'],
]);

export enum ChangeEmailResult {
  PendingChangeNewEmail = 1,
  FailedDuplicateEmail = 2,
  UnregisteredExistingEmail = 3,
  UnregisteredEmail = 4,
}

export enum PrimaryDataSourceOption {
  DefaultOption = 0,
  FromChecklistTemplate = 1,
  FromUploadDoc = 2,
  FromLoanApp = 3,
}

export const PrimaryDataSourceOptionLabel = new Map<number, string>([
  [PrimaryDataSourceOption.DefaultOption, 'Use default option'],
  [PrimaryDataSourceOption.FromChecklistTemplate, 'CHNL checklist template'],
  [PrimaryDataSourceOption.FromUploadDoc, 'Upload form (PDF)'],
  [PrimaryDataSourceOption.FromLoanApp, 'LoanApp data'],
]);

export enum OriginationSource {
  LoanApp = 1000,
  ApprovalInPrinciple = 1001,
  FullApplication = 1002,
  Refi = 1003,
  ServiceabilityCheck = 1004,
  Serviceability = 1005,
  RapidRefi = 1006,
  Purchase = 1007,
  EasyRefi = 1008,
  FactFind = 1009,
  StatementOfPosition = 1010,
}

export const OriginationSourceLabel = new Map<number, string>([
  [OriginationSource.LoanApp, 'Loanapp V1'],
  [OriginationSource.ApprovalInPrinciple, 'Approval In Principle'],
  [OriginationSource.FullApplication, 'Full Application'],
  [OriginationSource.Refi, 'Refinance'],
  [OriginationSource.ServiceabilityCheck, 'Serviceability Check'],
  [OriginationSource.Serviceability, 'Serviceability Journey'],
  [OriginationSource.RapidRefi, 'Rapid Refi'],
  [OriginationSource.Purchase, 'Purchase'],
  [OriginationSource.EasyRefi, 'Easy Refi'],
  [OriginationSource.FactFind, 'Fact Find'],
  [OriginationSource.StatementOfPosition, 'Statement Of Position'],
]);

export enum JourneyType {
  AllJourney = 0,
  ApprovalInPrinciple = 1,
  FactFind = 2,
  StatementOfPosition = 3,
  FullApplication = 4,
  Refi = 11,
  Serviceability = 21,
  ServiceabilityCheck = 31,
  EasyRefi = 32,
  RapidRefi = 41,
  Purchase = 51,
}

export const JourneyTypeLabel = new Map<number, string>([
  [JourneyType.ApprovalInPrinciple, 'Approval In Principle'],
  [JourneyType.FactFind, 'Fact Find'],
  [JourneyType.StatementOfPosition, 'Statement Of Position'],
  [JourneyType.FullApplication, 'Full Application'],
  [JourneyType.Refi, 'Refinance'],
  [JourneyType.Serviceability, 'Serviceability'],
  [JourneyType.ServiceabilityCheck, 'Serviceability Check'],
  [JourneyType.EasyRefi, 'Easy Refi'],
  [JourneyType.RapidRefi, 'Rapid Refi'],
  [JourneyType.Purchase, 'Purchase'],
]);

export enum VisibilityLevel {
  Applicant = 1,
  Broker = 1 << 1,
  MortgageManager = 1 << 2,
  Aggregator = 1 << 3,
  Lender = 1 << 4,
  Funder = 1 << 5,
}

export const VisibilityLevelLabel = new Map<number, string>([
  [VisibilityLevel.Applicant, 'Applicant'],
  [VisibilityLevel.Broker, 'Broker'],
  [VisibilityLevel.MortgageManager, 'Mortgage manager'],
  [VisibilityLevel.Aggregator, 'Aggregator'],
  [VisibilityLevel.Lender, 'Lender'],
  [VisibilityLevel.Funder, 'Funder'],
]);

export enum OutcomeAction {
  Pass = 1,
  Fail = 2,
  Approve = 3,
  Decline = 4,
  Review = 5,
}

export const OutcomeActionLabel = new Map<number, string>([
  [OutcomeAction.Pass, 'Pass'],
  [OutcomeAction.Fail, 'Fail'],
  [OutcomeAction.Approve, 'Approve'],
  [OutcomeAction.Decline, 'Decline'],
  [OutcomeAction.Review, 'Review'],
]);

export enum OverridableAccessLevel {
  All = 1,
  PrivilegedOnly = 2,
}

export const OverridableAccessLevelLabel = new Map<number, string>([
  [OverridableAccessLevel.All, 'All'],
  [OverridableAccessLevel.PrivilegedOnly, 'Privileged only'],
]);

export enum RbaLendingPurpose {
  PurchaseExistingDwelling = 1003,
  Refinance = 1006,
}

export const RbaLendingPurposeLabel = new Map<number, string>([
  [RbaLendingPurpose.PurchaseExistingDwelling, 'Purchase - Existing Dwelling'],
  [RbaLendingPurpose.Refinance, 'Refinance'],
]);

export enum LoanInsuranceCalculatorType {
  LMI = 1000,
  LDP = 1001,
  LMIAndLDP = 1002,
}

export const LoanInsuranceCalculatorTypeLabel = new Map<number, string>([
  [LoanInsuranceCalculatorType.LMI, 'LMI'],
  [LoanInsuranceCalculatorType.LDP, 'LDP'],
  [LoanInsuranceCalculatorType.LMIAndLDP, 'LMI/LDP'],
]);

export enum LoanInsuranceCalculatorLoanPurpose {
  Investment = 1002,
  OwnerOccupied = 1003,
}

export const LoanInsuranceCalculatorLoanPurposeLabel = new Map<number, string>([
  [LoanInsuranceCalculatorLoanPurpose.Investment, 'Investment'],
  [LoanInsuranceCalculatorLoanPurpose.OwnerOccupied, 'Owner Occupied'],
]);

export enum LoanInsuranceCalculatorLoadingTriggerType {
  LoanPurpose = 1,
  Postcode = 2,
}

export const LoanInsuranceCalculatorLoadingTriggerTypeLabel = new Map<number, string>([
  [LoanInsuranceCalculatorLoadingTriggerType.LoanPurpose, 'Loan purpose'],
  [LoanInsuranceCalculatorLoadingTriggerType.Postcode, 'Postcode'],
]);

export enum LoanInsuranceCalculatorPricingCalculationType {
  PremiumCredits = 1,
  IncrementalPricing = 2,
}

export const LoanInsuranceCalculatorPricingCalculationTypeLabel = new Map<number, string>([
  [LoanInsuranceCalculatorPricingCalculationType.PremiumCredits, 'Premium Credits'],
  [LoanInsuranceCalculatorPricingCalculationType.IncrementalPricing, 'Incremental Pricing'],
]);

export enum OmniActionItemCustomType {
  LoanInstructionDocumentSchedule4 = 1,
  LoanInstructionDocumentCreditAnalysisMemorandum = 2,
}

export const OmniActionItemCustomTypeLabel = new Map<number, string>([
  [OmniActionItemCustomType.LoanInstructionDocumentSchedule4, 'Loan Instruction Document Schedule 4'],
  [
    OmniActionItemCustomType.LoanInstructionDocumentCreditAnalysisMemorandum,
    'Loan Instruction Document Credit Analysis Memorandum',
  ],
]);

export enum DateSearchOption {
  ApplicationDate = 1,
  SubmissionDate = 2,
  SettlementDate = 3,
}

export const ApplicationDateFilterTypeLabel = new Map<number, string>([
  [DateSearchOption.ApplicationDate, 'Application date'],
  [DateSearchOption.SubmissionDate, 'Submission date'],
  [DateSearchOption.SettlementDate, 'Settlement date'],
]);

export enum ApplicationOrganisationFilter {
  All = 1,
  MyOrganisation = 2,
  MyOwn = 3,
}

export const ApplicationOrganisationFilterLabel = new Map<number, string>([
  [ApplicationOrganisationFilter.All, 'All'],
  [ApplicationOrganisationFilter.MyOrganisation, 'My organisation'],
  [ApplicationOrganisationFilter.MyOwn, 'My own'],
]);

export enum PrintAppDataArrayType {
  Root = 0,
  PersonApplicants = 1,
  LoanDetails = 2,
  Households = 3,
  HomeLoans = 4,
  PersonalLoans = 5,
  OtherLiabilities = 6,
  CreditCards = 7,
  RealEstateAssets = 8,
  Savings = 9,
  OtherAssets = 10,
  OtherIncomes = 11,
  Securities = 12,
}

export const PrintAppDataArrayTypeLabel = new Map<number, string>([
  [PrintAppDataArrayType.Root, 'Root'],
  [PrintAppDataArrayType.PersonApplicants, 'PersonApplicants'],
  [PrintAppDataArrayType.LoanDetails, 'LoanDetails'],
  [PrintAppDataArrayType.Households, 'Households'],
  [PrintAppDataArrayType.HomeLoans, 'HomeLoans'],
  [PrintAppDataArrayType.PersonalLoans, 'PersonalLoans'],
  [PrintAppDataArrayType.OtherLiabilities, 'OtherLiabilities'],
  [PrintAppDataArrayType.CreditCards, 'CreditCards'],
  [PrintAppDataArrayType.RealEstateAssets, 'RealEstateAssets'],
  [PrintAppDataArrayType.Savings, 'Savings'],
  [PrintAppDataArrayType.OtherAssets, 'OtherAssets'],
  [PrintAppDataArrayType.OtherIncomes, 'OtherIncomes'],
  [PrintAppDataArrayType.Securities, 'Securities'],
]);

export enum PrintDataType {
  String = 1,
  Number = 2,
  Date = 3,
  Boolean = 4,
}

export const PrintDataTypeLabel = new Map<number, string>([
  [PrintDataType.String, 'String'],
  [PrintDataType.Number, 'Number'],
  [PrintDataType.Date, 'Date'],
  [PrintDataType.Boolean, 'Boolean'],
]);

export enum PrintDataSource {
  AppData = 1,
  S4Data = 2,
  HligData = 3,
  BrokerDetailsDocument = 4,
  BrokerListDocument = 5,
  LoanInsuranceCalculationDocument = 6,
}

export const PrintDataSourceLabel = new Map<number, string>([
  [PrintDataSource.AppData, 'Application Data'],
  [PrintDataSource.S4Data, 'S4 Data'],
  [PrintDataSource.HligData, 'HLIG Data'],
]);

export enum PrintHligDataArrayType {
  Root = 0,
  SignificantChanges = 1,
}

export const PrintHligDataArrayTypeLabel = new Map<number, string>([
  [PrintHligDataArrayType.Root, 'Root'],
  [PrintHligDataArrayType.SignificantChanges, 'Significant Changes'],
]);

export enum JourneySetupMultipleSimultaneousSessions {
  Enabled = 1,
  WithWarning = 2,
  BlockedWithOverride = 3,
  Blocked = 4,
}

export const JourneySetupMultipleSimultaneousSessionsLabel = new Map<number, string>([
  [JourneySetupMultipleSimultaneousSessions.Enabled, 'Enabled'],
  [JourneySetupMultipleSimultaneousSessions.WithWarning, 'Enabled with warning'],
  [JourneySetupMultipleSimultaneousSessions.BlockedWithOverride, 'Blocked with the ability to override'],
  [JourneySetupMultipleSimultaneousSessions.Blocked, 'Blocked'],
]);

export enum RuleOption {
  NoRule = 1,
  Activates = 2,
  Blocks = 3,
}

export const RuleOptionLabel = new Map<number, string>([
  [RuleOption.NoRule, 'No Rule'],
  [RuleOption.Activates, 'Activates'],
  [RuleOption.Blocks, 'Blocks'],
]);

export enum MetricFormatType {
  Number = 1,
  CurrencyWithFrequency = 2,
  Percentage = 3,
  Currency = 4,
}

export const MetricFormatTypeLabel = new Map<number, string>([
  [MetricFormatType.Number, 'Number'],
  [MetricFormatType.CurrencyWithFrequency, 'Currency with frequency'],
  [MetricFormatType.Percentage, 'Percentage'],
  [MetricFormatType.Currency, 'Currency'],
]);

export enum ChannelCompanyNumberType {
  ABN = 1,
  NZBN = 2,
  EIN = 3,
  USCC = 4,
}

export const ChannelCompanyNumberTypeLabel = new Map<number, string>([
  [ChannelCompanyNumberType.ABN, 'ABN'],
  [ChannelCompanyNumberType.NZBN, 'NZBN'],
  [ChannelCompanyNumberType.EIN, 'EIN'],
  [ChannelCompanyNumberType.USCC, 'USCC'],
]);

export enum ChannelBrandImageBrandType {
  Security = 1,
  Application = 2,
  EmailHeader = 3,
  EmailFooter = 4,
  InfoRequest = 5,
}

export const ChannelBrandImageBrandTypeLabel = new Map<number, string>([
  [ChannelBrandImageBrandType.Security, 'Security functions'],
  [ChannelBrandImageBrandType.Application, 'Application page'],
  [ChannelBrandImageBrandType.EmailHeader, 'Email header'],
  [ChannelBrandImageBrandType.EmailFooter, 'Email footer'],
  [ChannelBrandImageBrandType.InfoRequest, 'Information request'],
]);

export enum EntityType {
  Lender = 1,
  Aggregator = 2,
  Brokerage = 3,
  LoanFunder = 4,
  LoanOriginator = 5,
  MortgageManager = 6,
}

export const EntityTypeLabel = new Map<number, string>([
  [EntityType.Lender, 'Lender'],
  [EntityType.Aggregator, 'Aggregator'],
  [EntityType.Brokerage, 'Brokerage'],
  [EntityType.LoanFunder, 'Loan funder'],
  [EntityType.LoanOriginator, 'Loan originator'],
  [EntityType.MortgageManager, 'Mortgage manager'],
]);

export enum PersonType {
  Administrator = 1,
  Broker = 2,
  TeamLead = 3,
  CreditAssessor = 4,
  Consumer = 5,
  LoanOfficer = 6,
  MobileLender = 7,
  Manager = 8,
  SeniorAssessor = 9,
  Lawyer = 10,
  Accountant = 11,
  Advisor = 12,
  Assessor = 13,
  Valuer = 14,
  ProductManager = 15,
  SalesAssessmentOfficer = 16,
  PropertyDeveloper = 17,
  MortgageManager = 18,
  Settlements = 19,
  BusinessDevelopmentManager = 20,
  BrokerAdministration = 21,
  ProductTeam = 22,
  ProjectTeam = 23,
  DistributionTeam = 24,
  MortgageServicesTeamLeader = 25,
  MortgageServicesTeamMember = 26,
  QualityAssuranceTeamMember = 27,
  SimpologyAdmin = 28,
  MortgageInsurer = 29,
  PackagingOfficer = 30,
  ReadOnly = 31,
  Translator = 32,
  RetentionOfficer = 33,
  BrokerSupportOfficer = 34,
  SupportStaff = 35,
  BrokerageManager = 36,
  LoanSpecialist = 37,
  SalesSupportOfficer = 38,
  UnknownEntity = 39,
  Solicitor = 40,
  CreditTeamLead = 41,
  Assistant = 42,
  LoanWriterAdminAssistant = 43,
  Supervisor = 44,
  SeniorManager = 45,
  InternalLoanWriter = 46,
  FraudManager = 47,
  Conveyencer = 48,
  ServiceabilityManager = 49,
  PASAASManager = 50,
  SystemManager = 51,
  UserManagement = 52,
  Franchise = 53,
  BusinessManager = 54,
  AccreditedAdministrator = 55,
  FranchiseAdministrator = 56,
  LoanWriter = 57,
  Partner = 58,
  LMISetupAdmin = 59,
  TechSupport = 60,
  FranchiseOwner = 61,
  MLManagement = 62,
  AdminAssistant = 63,
  BusinessAdministrator = 64,
  BusinessAdministratorFirstParty = 65,
  BusinessAdministratorThirdParty = 66,
  SubmittingOfficer = 67,
  LendingSpecialist = 68,
  RegionalManager = 69,
  VerificationOfficer = 70,
  CreditAnalyst = 71,
  Hdla = 72,
  SettlementsOfficer = 73,
  TeamManagerLendingAssessment = 74,
  ReportingTeam = 75,
  DirectHomeLoanSpecialist = 76,
  BankFraud = 77,
  FinancialCrimeOfficer = 78
}

export const PersonTypeLabel = new Map<number, string>([
  [PersonType.Administrator, 'Administrator'],
  [PersonType.Broker, 'Broker'],
  [PersonType.TeamLead, 'Team Lead'],
  [PersonType.CreditAssessor, 'Credit Assessor'],
  [PersonType.Consumer, 'Consumer'],
  [PersonType.LoanOfficer, 'Loan Officer'],
  [PersonType.MobileLender, 'Mobile Lender'],
  [PersonType.Manager, 'Manager'],
  [PersonType.SeniorAssessor, 'Senior Assessor'],
  [PersonType.Lawyer, 'Lawyer'],
  [PersonType.Accountant, 'Accountant'],
  [PersonType.Advisor, 'Advisor'],
  [PersonType.Assessor, 'Assessor'],
  [PersonType.Valuer, 'Valuer'],
  [PersonType.ProductManager, 'Product Manager'],
  [PersonType.SalesAssessmentOfficer, 'Sales Assessment Officer'],
  [PersonType.PropertyDeveloper, 'Property Developer'],
  [PersonType.MortgageManager, 'Mortgage Manager'],
  [PersonType.Settlements, 'Settlements'],
  [PersonType.BusinessDevelopmentManager, 'Business Development Manager'],
  [PersonType.BrokerAdministration, 'Broker Administration'],
  [PersonType.ProductTeam, 'Product Team'],
  [PersonType.ProjectTeam, 'Project Team'],
  [PersonType.DistributionTeam, 'Distribution Team'],
  [PersonType.MortgageServicesTeamLeader, 'Mortgage Services Team Leader'],
  [PersonType.MortgageServicesTeamMember, 'Mortgage Services Team Member'],
  [PersonType.QualityAssuranceTeamMember, 'Quality Assurance Team Member'],
  [PersonType.SimpologyAdmin, 'Simpology Admin'],
  [PersonType.MortgageInsurer, 'Mortgage Insurer'],
  [PersonType.PackagingOfficer, 'Packaging Officer'],
  [PersonType.ReadOnly, 'Read Only'],
  [PersonType.Translator, 'Translator'],
  [PersonType.RetentionOfficer, 'Retention Officer'],
  [PersonType.BrokerSupportOfficer, 'Broker Support Officer'],
  [PersonType.SupportStaff, 'Support Staff'],
  [PersonType.BrokerageManager, 'Brokerage Manager'],
  [PersonType.LoanSpecialist, 'Loan Specialist'],
  [PersonType.SalesSupportOfficer, 'Sales Support Officer'],
  [PersonType.UnknownEntity, 'Unknown Entity'],
  [PersonType.Solicitor, 'Solicitor'],
  [PersonType.CreditTeamLead, 'Credit Team Lead'],
  [PersonType.Assistant, 'Assistant'],
  [PersonType.LoanWriterAdminAssistant, 'Loan Writer Admin Assistant'],
  [PersonType.Supervisor, 'Supervisor'],
  [PersonType.SeniorManager, 'Senior Manager'],
  [PersonType.InternalLoanWriter, 'Internal Loan Writer'],
  [PersonType.FraudManager, 'Fraud Manager'],
  [PersonType.Conveyencer, 'Conveyencer'],
  [PersonType.ServiceabilityManager, 'Serviceability Manager'],
  [PersonType.PASAASManager, 'PASAAS Manager'],
  [PersonType.SystemManager, 'System Manager'],
  [PersonType.UserManagement, 'User Management'],
  [PersonType.Franchise, 'Franchise'],
  [PersonType.BusinessManager, 'Business Manager'],
  [PersonType.AccreditedAdministrator, 'Accredited Administrator'],
  [PersonType.FranchiseAdministrator, 'Franchise Administrator'],
  [PersonType.LoanWriter, 'Loan Writer'],
  [PersonType.Partner, 'Partner'],
  [PersonType.LMISetupAdmin, 'LMI Setup Admin'],
  [PersonType.TechSupport, 'Tech Support'],
  [PersonType.FranchiseOwner, 'Franchise Owner'],
  [PersonType.MLManagement, 'ML Management'],
  [PersonType.AdminAssistant, 'Admin Assistant'],
  [PersonType.BusinessAdministrator, 'Business Administrator'],
  [PersonType.BusinessAdministratorFirstParty, 'Business Administrator - First Party'],
  [PersonType.BusinessAdministratorThirdParty, 'Business Administrator - Third Party'],
  [PersonType.SubmittingOfficer, 'Submitting Officer'],
  [PersonType.LendingSpecialist, 'Lending Specialist'],
  [PersonType.RegionalManager, 'Regional Manager'],
  [PersonType.VerificationOfficer, 'Verification Officer'],
  [PersonType.CreditAnalyst, 'Credit Analyst'],
  [PersonType.Hdla, 'HDLA'],
  [PersonType.SettlementsOfficer, 'Settlements Officer'],
  [PersonType.TeamManagerLendingAssessment, 'Team Manager Lending Assessment'],
  [PersonType.ReportingTeam, 'Reporting Team'],
  [PersonType.DirectHomeLoanSpecialist, 'Direct Home Loan Specialist'],
  [PersonType.BankFraud, 'Bank Fraud'],
  [PersonType.FinancialCrimeOfficer, 'Financial Crime Officer'],
]);

export enum PersonStatus {
  Active = 1,
  Inactive = 2,
  PreRegistered = 3,
  New = 4,
}

export const PersonStatusLabel = new Map<number, string>([
  [PersonStatus.Active, 'Active'],
  [PersonStatus.Inactive, 'Inactive'],
  [PersonStatus.PreRegistered, 'Pre Registered'],
  [PersonStatus.New, 'New'],
]);

export enum GeographicZone {
  Metro = 1,
  NonMetro = 2,
  Rural = 3,
  Regional = 4,
  HighDensity = 5,
  InnerCity = 6,
  HighRisk = 7,
}

export const GeographicZoneLabel = new Map<number, string>([
  [GeographicZone.Metro, 'Metro'],
  [GeographicZone.NonMetro, 'Non-Metro'],
  [GeographicZone.Rural, 'Rural'],
  [GeographicZone.Regional, 'Regional'],
  [GeographicZone.HighDensity, 'High Density'],
  [GeographicZone.InnerCity, 'Inner City'],
  [GeographicZone.HighRisk, 'High Risk'],
]);

export enum RiskZone {
  Bushfire = 1,
  Flood = 2,
  Commercial = 3,
  HighRisk = 4,
  HighDensity = 5,
}

export const RiskZoneLabel = new Map<number, string>([
  [RiskZone.Bushfire, 'Bushfire'],
  [RiskZone.Flood, 'Flood'],
  [RiskZone.Commercial, 'Commercial'],
  [RiskZone.HighRisk, 'High risk'],
  [RiskZone.HighDensity, 'High Density'],
]);

export enum RulesEngineRuleTriggerEvent {
  ApplicationSubmission = 1,
  ActionItemGeneration = 2,
  DocumentChecklistGeneration = 3,
  CreditReportCheck = 4,
  VerificationOfIdCheck = 5,
  BankingData = 6,
  LenderSubmission = 7,
  ServiceabilityRequired = 8,
  ServiceabilityDetermination = 9,
  ProductCustomLoaderFilter = 10,
  CreditCardFeatureFilter = 11,
  PrintFormGeneration = 12,
  DigitalServiceTrigger = 13,
  ApplicationLock = 14,
  ApplicationAssessment = 15,
  PrintFormRuleBasedDataPoints = 16,
  LoanappModelPopulation = 17,
  DigitalServiceExpiry = 18,
}

export const RulesEngineRuleTriggerEventLabel = new Map<number, string>([
  [RulesEngineRuleTriggerEvent.ApplicationSubmission, 'Application Submission'],
  [RulesEngineRuleTriggerEvent.ActionItemGeneration, 'Process and action item generation'],
  [RulesEngineRuleTriggerEvent.DocumentChecklistGeneration, 'Document Checklist Generation'],
  [RulesEngineRuleTriggerEvent.CreditReportCheck, 'Credit report check'],
  [RulesEngineRuleTriggerEvent.VerificationOfIdCheck, 'Verification of ID check'],
  [RulesEngineRuleTriggerEvent.BankingData, 'Banking data'],
  [RulesEngineRuleTriggerEvent.LenderSubmission, 'Lender submission'],
  [RulesEngineRuleTriggerEvent.ServiceabilityRequired, 'Serviceability Required'],
  [RulesEngineRuleTriggerEvent.ServiceabilityDetermination, 'Serviceability Determination'],
  [RulesEngineRuleTriggerEvent.ProductCustomLoaderFilter, 'Product custom loader filter'],
  [RulesEngineRuleTriggerEvent.CreditCardFeatureFilter, 'Credit card feature filter'],
  [RulesEngineRuleTriggerEvent.PrintFormGeneration, 'Print Form Generation'],
  [RulesEngineRuleTriggerEvent.DigitalServiceTrigger, 'Digital service trigger'],
  [RulesEngineRuleTriggerEvent.ApplicationLock, 'Application Lock'],
  [RulesEngineRuleTriggerEvent.ApplicationAssessment, 'Application assessment'],
  [RulesEngineRuleTriggerEvent.PrintFormRuleBasedDataPoints, 'Print form rule based data points'],
  [RulesEngineRuleTriggerEvent.LoanappModelPopulation, 'Loanapp model population'],
  [RulesEngineRuleTriggerEvent.DigitalServiceExpiry, 'Digital service expiry'],
]);

export const EnumLabelClient: EnumMapper[] = [
  { id: 'ChannelCompanyNumberType', enumType: ChannelCompanyNumberType, mapping: ChannelCompanyNumberTypeLabel },
  { id: 'ChannelBrandImageBrandType', enumType: ChannelBrandImageBrandType, mapping: ChannelBrandImageBrandTypeLabel },
  { id: 'EntityType', enumType: EntityType, mapping: EntityTypeLabel },
  { id: 'PersonType', enumType: PersonType, mapping: PersonTypeLabel },
  { id: 'PersonStatus', enumType: PersonStatus, mapping: PersonStatusLabel },
  { id: 'ProvideDocPersonType', enumType: ProvideDocPersonType, mapping: ProvideDocPersonTypeLabel },
  { id: 'UsedInEventsType', enumType: UsedInEventsType, mapping: UsedInEventsTypeLabel },
  { id: 'PrintIndividualDocType', enumType: PrintIndividualDocType, mapping: PrintIndividualDocTypeLabel },
  { id: 'IdentityDocumentType', enumType: IdentityDocumentType, mapping: IdentityDocumentTypeLabel },
  { id: 'AttachmentType', enumType: AttachmentType, mapping: AttachmentTypeLabel },
  { id: 'RequirementCondition', enumType: RequirementCondition, mapping: RequirementConditionLabel },
  { id: 'FeatureOptionStateCode', enumType: FeatureOptionStateCode, mapping: FeatureOptionStateCodeLabel },
  {
    id: 'ApplicationParticipantSubType',
    enumType: ApplicationParticipantSubType,
    mapping: ApplicationParticipantSubTypeLabel,
  },
  { id: 'ServiceabilityFeature', enumType: ServiceabilityFeature, mapping: ServiceabilityFeatureLabel },
  {
    id: 'ServiceabilityFeatureOption',
    enumType: ServiceabilityFeatureOption,
    mapping: ServiceabilityFeatureOptionLabel,
  },
  { id: 'ServiceabilityIncomeType', enumType: ServiceabilityIncomeType, mapping: ServiceabilityIncomeTypeLabel },
  { id: 'ServiceabilityIncomeYear', enumType: ServiceabilityIncomeYear, mapping: ServiceabilityIncomeYearLabel },
  {
    id: 'ServiceabilityIncomeAmountCalculationType',
    enumType: ServiceabilityIncomeAmountCalculationType,
    mapping: ServiceabilityIncomeAmountCalculationTypeLabel,
  },
  {
    id: 'ServiceabilityNewFinancialCommitmentRepaymentCalculationType',
    enumType: ServiceabilityNewFinancialCommitmentRepaymentCalculationType,
    mapping: ServiceabilityNewFinancialCommitmentRepaymentCalculationTypeLabel,
  },
  {
    id: 'CompareOperator',
    enumType: CompareOperator,
    mapping: CompareOperatorLabel,
  },
  {
    id: 'ServiceabilitySubsection',
    enumType: ServiceabilitySubsection,
    mapping: ServiceabilitySubsectionLabel,
  },
  { id: 'BrandImageType', enumType: BrandImageType, mapping: BrandImageTypeLabel },
  { id: 'DocumentActivity', enumType: DocumentActivity, mapping: DocumentActivityLabel },
  {
    id: 'ChecklistVerificationResult',
    enumType: ChecklistVerificationResult,
    mapping: ChecklistVerificationResultLabel,
  },
  {
    id: 'PrimaryDataSourceOption',
    enumType: PrimaryDataSourceOption,
    mapping: PrimaryDataSourceOptionLabel,
  },
  {
    id: 'OriginationSource',
    enumType: OriginationSource,
    mapping: OriginationSourceLabel,
  },
  {
    id: 'VisibilityLevel',
    enumType: VisibilityLevel,
    mapping: VisibilityLevelLabel,
  },
  {
    id: 'OutcomeAction',
    enumType: OutcomeAction,
    mapping: OutcomeActionLabel,
  },
  {
    id: 'OverridableAccessLevel',
    enumType: OverridableAccessLevel,
    mapping: OverridableAccessLevelLabel,
  },
  {
    id: 'LoanInsuranceCalculatorType',
    enumType: LoanInsuranceCalculatorType,
    mapping: LoanInsuranceCalculatorTypeLabel,
  },
  {
    id: 'LoanInsuranceCalculatorLoanPurpose',
    enumType: LoanInsuranceCalculatorLoanPurpose,
    mapping: LoanInsuranceCalculatorLoanPurposeLabel,
  },
  {
    id: 'LoanInsuranceCalculatorLoadingTriggerType',
    enumType: LoanInsuranceCalculatorLoadingTriggerType,
    mapping: LoanInsuranceCalculatorLoadingTriggerTypeLabel,
  },
  {
    id: 'LoanInsuranceCalculatorPricingCalculationType',
    enumType: LoanInsuranceCalculatorPricingCalculationType,
    mapping: LoanInsuranceCalculatorPricingCalculationTypeLabel,
  },
  {
    id: 'OmniActionItemCustomType',
    enumType: OmniActionItemCustomType,
    mapping: OmniActionItemCustomTypeLabel,
  },
  {
    id: 'RbaLendingPurpose',
    enumType: RbaLendingPurpose,
    mapping: RbaLendingPurposeLabel,
  },
  {
    id: 'ApplicationDateFilterType',
    enumType: DateSearchOption,
    mapping: ApplicationDateFilterTypeLabel,
  },
  {
    id: 'ApplicationOrganisationFilter',
    enumType: ApplicationOrganisationFilter,
    mapping: ApplicationOrganisationFilterLabel,
  },
  {
    id: 'PrintAppDataArrayType',
    enumType: PrintAppDataArrayType,
    mapping: PrintAppDataArrayTypeLabel,
  },
  {
    id: 'PrintDataType',
    enumType: PrintDataType,
    mapping: PrintDataTypeLabel,
  },
  {
    id: 'PrintDataSource',
    enumType: PrintDataSource,
    mapping: PrintDataSourceLabel,
  },
  {
    id: 'PrintHligDataArrayType',
    enumType: PrintHligDataArrayType,
    mapping: PrintHligDataArrayTypeLabel,
  },
  {
    id: 'JourneySetupMultipleSimultaneousSessions',
    enumType: JourneySetupMultipleSimultaneousSessions,
    mapping: JourneySetupMultipleSimultaneousSessionsLabel,
  },
  {
    id: 'RuleOption',
    enumType: RuleOption,
    mapping: RuleOptionLabel,
  },
  {
    id: 'MetricFormatType',
    enumType: MetricFormatType,
    mapping: MetricFormatTypeLabel,
  },
  {
    id: 'RecipientExtraType',
    enumType: RecipientExtraType,
    mapping: RecipientExtraTypeLabel,
  },
  {
    id: 'GeographicZone',
    enumType: GeographicZone,
    mapping: GeographicZoneLabel,
  },
  {
    id: 'RiskZone',
    enumType: RiskZone,
    mapping: RiskZoneLabel,
  },
  {
    id: 'RulesEngineRuleTriggerEvent',
    enumType: RulesEngineRuleTriggerEvent,
    mapping: RulesEngineRuleTriggerEventLabel,
  },
];
