import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InfoRequestNotificationService {
  public infoRequestUpdated$: Observable<string>;
  private infoRequestSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor() {
    this.infoRequestUpdated$ = this.infoRequestSubject$.asObservable();
  }

  public notifyChange(msg: string): void {
    this.infoRequestSubject$.next(msg);
  }
}
