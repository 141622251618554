import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormMetaData } from '@shared/model/form-metadata';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import formMetaData from '../../../assets/form/metadata/secure.json';

@Injectable({
  providedIn: 'root',
})
export class MetadataService extends BaseApiService<any> {
  setupInitialised$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private metaData: FormMetaData[] = [];

  constructor(http: HttpClient, router: Router, toastr: ToastrService) {
    super(http, toastr, router, ENV_CONFIG);
  }

  loadInitialSetup(): void {
    this.fetchMetaData().subscribe(() => {
      this.setupInitialised$.next(true);
    });
  }

  getElementFromMetaData(path: string): FormMetaData {
    const getPath = path.replace('.', '#');
    const field = this.metaData.find((x) => x.fieldKey == getPath);
    return field || { title: 'Missing JSON' };
  }

  setControlsAndValidations(form: UntypedFormGroup, section: string, data?: any): void {
    this.setupEvaluationsOnFormChange(form, section);

    Object.keys(form.controls).forEach((key) => {
      const element = this.getElementFromMetaData(`${section}#${key}`);

      if (data && data[key]) {
        form.get(key)?.setValue(data[key], { emitEvent: false });
      } else {
        this.setDefaultValue(form, section, key, this.getElementFromMetaData(`${section}#${key}`).defaultValue);
      }

      // TODO Need a solution for this that allows unhiding based on the expression
      //this.expressionEval(form, element.hide) ? (form.contains(key) ? form.removeControl(key) : null) : null;

      if (form.contains(key)) {
        const control = form.get(key);
        this.expressionEval(form, element.disabled)
          ? control.disable({ emitEvent: false })
          : control.enable({ emitEvent: false });
        this.expressionEval(form, element.required)
          ? control.addValidators(Validators.required)
          : control.clearValidators();

        element.min ? control.addValidators(Validators.min(element.min)) : null;
        element.max ? control.addValidators(Validators.max(element.max)) : null;
        element.minLength ? control.addValidators(Validators.minLength(element.minLength)) : null;
        element.maxLength ? control.addValidators(Validators.maxLength(element.maxLength)) : null;
        element.pattern ? control.addValidators(Validators.pattern(element.pattern)) : null;
        control.markAsPristine();
        control.markAsUntouched();
      }
    });
  }

  private setupEvaluationsOnFormChange(form: UntypedFormGroup, section: string) {
    form.valueChanges.subscribe((value) => {
      Object.keys(form.controls).forEach((key) => {
        const element = this.getElementFromMetaData(`${section}#${key}`);

        if (form.contains(key)) {
          const control = form.get(key);

          this.expressionEval(form, element.required)
            ? control.addValidators(Validators.required)
            : control.clearValidators();

          this.expressionEval(form, element.disabled)
            ? control.disable({ emitEvent: false })
            : control.enable({ emitEvent: false });
        }
      });
    });
  }

  private fetchMetaData(): Observable<FormMetaData[]> {
    this.setMetaRoute('Metadata');
    return this.getCustom('Secure/Secure_Secure').pipe(
      map((res: FormMetaData[]) => {
        if (res.length) {
          this.metaData = res;
          return res;
        } else {
          this.metaData = formMetaData as unknown as FormMetaData[];
          return this.metaData;
        }
      }),
      catchError(() => {
        this.metaData = formMetaData as unknown as FormMetaData[];
        return of(this.metaData);
      })
    );
  }

  // TODO Setup enums store, akita store could be setup to match what tama-calculators uses
  //   private fetchOptions(listName: string): Observable<EnumObject[]> {
  //     this.setMetaRoute('ListOption');
  //     return this.getCustom(listName).pipe(
  //       map((options: { optionId: number; text: string; isDefault?: boolean }[]) => {
  //         return options.map((option) => {
  //           return {
  //             id: option.optionId,
  //             label: option.text,
  //           } as EnumObject;
  //         });
  //       }),
  //       tap((options: EnumObject[]) => {
  //         this.storeService.upsertEnumsStore(listName, options as unknown as FormEnums);
  //       })
  //     );
  //   }

  expressionEval(form: UntypedFormGroup, expression: string | undefined): boolean {
    if (expression?.toLowerCase() === 'true') return true;
    if (expression?.toLowerCase() === 'false') return false;

    const model = form.getRawValue();
    const updatedExpression = expression?.split('?.').join('.').split('.').join('?.');

    return updatedExpression !== undefined && model ? eval(updatedExpression) : false;
  }

  private setDefaultValue(form: UntypedFormGroup, section: string, key: string, defaultValue?: string | number): void {
    if (defaultValue) {
      if (typeof defaultValue === 'number') {
        form.get(key)?.setValue(Number(defaultValue));
        return;
      }

      if (typeof defaultValue === 'string') {
        if (defaultValue.toLowerCase() === 'true') {
          form.get(key)?.setValue(true);
          return;
        }

        if (defaultValue.toLowerCase() === 'false') {
          form.get(key)?.setValue(false);
          return;
        }
      }

      form.get(key)?.setValue(defaultValue);
    } else {
      form.get(key)?.reset();
    }
  }
}
