import { Injectable } from '@angular/core';
import {
  StoreConfig,
  EntityState,
  EntityStore,
  ActiveState,
} from '@datorama/akita';
import { People } from './people.model';

export interface PeopleState extends EntityState<People>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'people', resettable: true })
export class PeopleStore extends EntityStore<PeopleState, People> {
  constructor() {
    super({});
  }
}
