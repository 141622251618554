<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1"></div>
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img src="assets/images/logo/logo-pink.png" alt="branding logo" />
                </div>
                <div *ngIf="state === 1" class="pt-5 pb-5 ml-4">
                  <h2>Registration</h2>
                  <br />
                  Welcome!
                  <br />
                  <br />
                  Please enter your security code to complete your registration.<br />
                  Call Simpology on 1300 746 765 if you don't have a code
                  <br />
                  <br />
                  <div class="row">
                    <input type="text" class="form-control col-md-4 ml-3" [(ngModel)]="code" />
                    <div *ngIf="badCode" class="ml-4 pt-2 form-text text-muted danger">Incorrect code</div>
                  </div>
                  <br />
                  <br />
                  <button type="button" class="btn btn-sm btn-primary" (click)="checkCode()">
                    Complete registration
                  </button>
                  <br />
                  <br />
                </div>
                <div *ngIf="state === 5">
                  <br />
                  Checking code, please wait...
                </div>
                <div *ngIf="state === 2">
                  <br />
                  Finalising your registration, please wait...
                </div>
                <div *ngIf="state === 4">Registration failed...</div>

                <div *ngIf="state === 3" class="pt-5 pb-5 ml-4">
                  <!--Success-->
                  <h2>Registration completed</h2>
                  <br />
                  Welcome!
                  <br />
                  <br />
                  Please login to access your account.
                  <br />
                  <br />
                  <button type="button" class="btn btn-sm btn-primary ml-3" (click)="login()">Login</button>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
