export class Constant {
  public static readonly addActionQueryParam = 'add';
  public static readonly editActionQueryParam = 'edit';
  public static readonly returnUrlKey = 'simp:returnUrl';
  public static readonly currentUser = 'simp:currentUser';
  public static readonly decisionSupportDefaultMiddleViewKey = 'simp:setting:decisionSupport:defaultMiddlePanelView';
  public static readonly applicationDefaultFilteredStatusesKey = 'simp:setting:application:defaultFilteredStatuses';
  public static readonly documentManagerUseSimplifySourceKey = 'simp:setting:documentManager:useSimplifySource';
  public static readonly applicationFilteredKey = 'simp:setting:application:filter';

  public static readonly simpologyManagerSolutionId = 1001;
  public static readonly newId = -1;
  public static readonly countryCodeAustralia = 'AU';

  public static readonly conflictErrorCode = 409;
  public static readonly notFoundErrorCode = 404;

  public static readonly xdpDocumentImagePath = './assets/images/icons/xdp.png';
  public static readonly pdfDocumentImagePath = './assets/images/icons/pdf.png';
  public static readonly wordImagePath = './assets/images/icons/word.png';
  public static readonly excelImagePath = './assets/images/icons/excel.png';
  public static readonly noImagePath = './assets/images/logo/application_noimage.png';
  public static readonly noAvatarPath = './assets/images/portrait/small/default.png';
  public static readonly commonFileExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'bmp'];
  public static readonly textControlFileExtensions = ['pdf', 'doc', 'docx', 'xlsx', 'rtf', 'txt', 'html', 'tx'];
  public static readonly textFileExtensions = ['json', 'txt'];

  public static readonly deletedStatus = -1;

  public static readonly errorMessages: Record<string, string> = {
    existingPersonWithSameEmail: 'ERR01',
    existingConsumerPersonWithSameEmail: 'ERR02',
  };

  public static readonly iTextStandardFonts = [
    'Courier',
    'Courier-Bold',
    'Courier-BoldOblique',
    'Courier-Oblique',
    'Helvetica',
    'Helvetica-Bold',
    'Helvetica-BoldOblique',
    'Helvetica-Oblique',
    'Symbol',
    'Times-Roman',
    'Times-Bold',
    'Times-BoldItalic',
    'Times-Italic',
    'ZapfDingbats',
  ];
}
