import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { AuthCallbackComponent } from './authentication/auth-callback/auth-callback.component';
import { FinaliseComponent } from './finalise/finalise.component';
import { FinalisePersonComponent } from './finalise/finalise-person.component';
import { ExternalLoginComponent } from './authentication/external-login/external-login.component';
import { ConfirmEmailChangeComponent } from './confirm-email-change/confirm-email-change.component';

const routes: Routes = [
  // { path: 'solutions', component: DashboardComponent },
  // { path: 'help', component: DashboardComponent },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'externalLogin', component: ExternalLoginComponent },
  { path: 'finalise', component: FinaliseComponent },
  { path: 'finalisePerson', component: FinalisePersonComponent },
  { path: 'confirmEmailChange', component: ConfirmEmailChangeComponent },

  {
    path: '',
    loadChildren: (): any => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'home',
    loadChildren: (): any => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'organisation',
    loadChildren: (): any => import('./organisation/organisation.module').then((m) => m.OrganisationModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'security',
    loadChildren: (): any => import('./security/security.module').then((m) => m.SecurityModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: (): any => import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'subscriptions',
    loadChildren: (): any => import('./subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'solutions',
    loadChildren: (): any => import('./solutions/solutions.module').then((m) => m.SolutionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'setup',
    loadChildren: (): any => import('./setup/setup.module').then((m) => m.SetupModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
