import { NgModule } from '@angular/core';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigationComponent } from './navigation.component';
import { HeaderComponent } from './header/header.component';
import { HorizontalNavigationComponent } from './horizontal-navigation/horizontal-navigation.component';
import { VerticalNavigationComponent } from './vertical-navigation/vertical-navigation.component';
import { HeaderExtraComponent } from './header/header-extra/header-extra.component';
import { NavigationService } from './navigation.service';

@NgModule({
  declarations: [
    NavigationComponent,
    HeaderComponent,
    HorizontalNavigationComponent,
    VerticalNavigationComponent,
    HeaderExtraComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    NoopAnimationsModule,
    CommonModule,
    RouterModule,
    NgbModule,
  ],
  exports: [
    NavigationComponent,
    HeaderComponent,
    HorizontalNavigationComponent,
    VerticalNavigationComponent,
  ],
  providers: [NavigationService],
})
export class NavigationModule {}
