import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MetadataService } from '../service/metadata.service';

@Pipe({
  name: 'isReadonly',
  pure: false,
})
export class IsReadonlyPipe implements PipeTransform {
  constructor(private metaDataService: MetadataService) {}
  transform(value: string, form: FormGroup) {
    if (!value) {
      return undefined;
    }
    const readonlyValue = this.metaDataService.getElementFromMetaData(value)?.readonly || '';
    return this.metaDataService.expressionEval(form, readonlyValue);
  }
}
