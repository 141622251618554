import { Injectable } from '@angular/core';
import { AuthService } from '@simpology/authentication';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Constant } from 'src/app/helper/constant';
import { PeopleQuery } from 'src/app/organisation/store/people/people.query';
import { PeopleService } from 'src/app/organisation/store/people/people.service';
import { CurrentUser } from '../model/current-user.model';

@Injectable({ providedIn: 'root' })
export class PostLoginService {
  public loginCompleted$: Observable<CurrentUser>;
  private loginCompletedSource = new BehaviorSubject<CurrentUser>(null);

  constructor(
    private authService: AuthService,
    private peopleService: PeopleService,
    private peopleQuery: PeopleQuery
  ) {
    this.loginCompleted$ = this.loginCompletedSource.asObservable();

    this.authService.authNavStatus$.subscribe((value: boolean) => {
      if (value) {
        this.peopleService
          .getAll()
          .pipe(mergeMap(() => this.peopleService.getCurrentPersonChannel()))
          .subscribe((personChannel) => {
            const people = this.peopleQuery.getByUser(this.authService.id);
            const peopleId = people ? people.id : null;
            const entityId = people ? people.entityId : null;
            const photoURL = people ? people.avatar : null;

            const currentUser = {
              displayName: this.authService.name,
              photoURL,
              peopleId,
              entityId,
              channelName: personChannel.currentChannelName,
              entityName: personChannel.currentEntityName,
              hasMultiChannels: personChannel.numberOfChannels > 1,
              hasMultiEntities: personChannel.numberOfEntities > 1,
              accessibleEntityIds: personChannel.accessibleEntityIds,
            };
            localStorage.setItem(Constant.currentUser, JSON.stringify(currentUser));
            this.loginCompletedSource.next(currentUser);
          });

        this.peopleService.postLogin().subscribe();
      }
    });
  }

  public getCurrentUser(): CurrentUser {
    const value = localStorage.getItem(Constant.currentUser);
    if (value && value.trim().length > 0) {
      return JSON.parse(value);
    }
    return {
      displayName: this.authService.name,
      photoURL: null,
      peopleId: null,
      entityId: null,
      channelName: null,
      entityName: null,
      hasMultiChannels: false,
      hasMultiEntities: false,
      accessibleEntityIds: null,
    };
  }
}
