import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { permissionId } from '@shared/access/permission-ids';
import { AccessService } from '@shared/service/access.service';
import { InfoRequestNotificationService } from '@shared/service/info-request-notification.service';
import { AuthService } from '@simpology/authentication';
import { InfoRequestApiService } from '@solution/api/inforequest-api.service';
import { InfoRequestNotification } from '@solution/model/inforequest.model';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PeopleService } from 'src/app/organisation/store/people/people.service';
import { ChannelBrandImageBrandType } from 'src/app/shared/model/client-enum.model';
import { CurrentUser } from 'src/app/shared/model/current-user.model';
import { BrandImageApiService } from 'src/app/shared/service/api/brand-image.api.service';
import { PictureUpdatedNotificationService } from 'src/app/shared/service/picture-updated-notification.service';
import { PostLoginService } from 'src/app/shared/service/post-login.service';

@Component({
  selector: 'app-header-extra',
  templateUrl: './header-extra.component.html',
  styleUrls: ['./header-extra.component.scss'],
})
export class HeaderExtraComponent implements OnInit, OnDestroy {
  @ViewChild('notificationDropdown') private notificationDropdown: NgbDropdown;

  public currentUser: CurrentUser;
  public notificationCount = 0;
  public infoRequestNotifications: InfoRequestNotification[] = [];

  private securityLogoSubscription: Subscription;
  private postLoginSubscription: Subscription;
  private infoRequestUpdatedSubscription: Subscription;
  private hasReadAccessToInfoRequest = false;
  private hasAccessToMyInfoRequest = false;
  public hasAccessToEditProfileUserContextMenu = false;
  public hasAccessToChangePasswordUserContextMenu = false;

  constructor(
    private accessService: AccessService,
    private authService: AuthService,
    private peopleService: PeopleService,
    private router: Router,
    private brandImageApiService: BrandImageApiService,
    private pictureUpdatedNotificationService: PictureUpdatedNotificationService,
    private infoRequestNotificationService: InfoRequestNotificationService,
    private infoRequestApiService: InfoRequestApiService,
    private postLoginService: PostLoginService
  ) {}

  public passwordLink: string;

  public ngOnInit(): void {
    this.hasReadAccessToInfoRequest = this.accessService.canRead(permissionId.InfoRequestsManagement);
    this.hasAccessToMyInfoRequest = this.accessService.canDoAny([permissionId.MyInfoRequestsManagement]);
    this.setChangePasswordUrl();
    this.securityLogoSubscription = this.pictureUpdatedNotificationService.securityLogoUpdate$.subscribe((url) => {
      this.buildChangePasswordUrl(url);
    });
    this.postLoginSubscription = this.postLoginService.loginCompleted$.subscribe((result) => {
      if (result) {
        this.currentUser = result;
      }
    });

    this.hasAccessToChangePasswordUserContextMenu = this.accessService.canAccess(
      permissionId.ChangePasswordUserContextMenu
    );
    this.hasAccessToEditProfileUserContextMenu = this.accessService.canAccess(permissionId.UserProfileContextMenu);

    this.infoRequestUpdatedSubscription = this.infoRequestNotificationService.infoRequestUpdated$.subscribe(() => {
      this.refreshInfoRequestNotification();
    });
  }

  public ngOnDestroy(): void {
    this.securityLogoSubscription.unsubscribe();
    this.postLoginSubscription.unsubscribe();
    this.infoRequestUpdatedSubscription.unsubscribe();
  }

  public logout(): void {
    this.authService.signout();
  }

  public editProfile(): void {
    this.peopleService.setMyProfile(this.currentUser.peopleId);
    this.router.navigateByUrl('/organisation/people');
  }

  public onClickNotificationBell(): void {
    this.refreshInfoRequestNotification();
  }

  public goToInfoRequest(notification: InfoRequestNotification): void {
    this.notificationDropdown.close();
    if (notification.isMyInfoRequest) {
      this.router.navigate(['/solutions/my-info-request', notification.applicationId]);
    } else {
      this.router.navigate(['/solutions/inforequest', notification.applicationId]);
    }
  }

  private refreshInfoRequestNotification(): void {
    const calls: Observable<InfoRequestNotification[]>[] = [];

    calls.push(this.getInfoRequestNotification());
    calls.push(this.getMyInfoRequestNotification());
    forkJoin(calls).subscribe((results) => {
      this.infoRequestNotifications = results[0];
      this.infoRequestNotifications.push(...results[1]);
      this.notificationCount = this.infoRequestNotifications.length;
    });
  }

  private getInfoRequestNotification(): Observable<InfoRequestNotification[]> {
    if (!this.hasReadAccessToInfoRequest) {
      return of([]);
    }

    return this.infoRequestApiService.getInfoRequestNotification();
  }

  private getMyInfoRequestNotification(): Observable<InfoRequestNotification[]> {
    if (!this.hasAccessToMyInfoRequest) {
      return of([]);
    }

    return this.infoRequestApiService.getMyInfoRequestNotification().pipe(
      tap((result) => {
        result.forEach((x) => (x.isMyInfoRequest = true));
      })
    );
  }

  public changePassword(): void {}

  private setChangePasswordUrl(): void {
    let securityLogoPath = '';
    this.brandImageApiService.getAll().subscribe(
      (result) => {
        if (result && result.length > 0) {
          const securityBrandImage = result.find((i) => i.brandTypeEnum === ChannelBrandImageBrandType.Security);
          if (securityBrandImage) {
            securityLogoPath = securityBrandImage.imagePath;
          }
        }
        this.buildChangePasswordUrl(securityLogoPath);
      },
      (err) => {
        this.buildChangePasswordUrl('');
      }
    );
  }

  private buildChangePasswordUrl(imagePath: string): void {
    const url = encodeURIComponent(`${window.location.protocol}//${window.location.host}`);
    const idServerUrl = this.authService.identityUrl().replace(/\/$/, '');
    this.passwordLink = `${idServerUrl}/Account/ChangePassword?clientId=${this.authService.clientId()}&returnUrl=${url}&logo=${imagePath}`;
  }
}
