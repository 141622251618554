import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BrandImage } from 'src/app/shared/model/brand-image.model';
import { ChannelBrandImageBrandType } from 'src/app/shared/model/client-enum.model';
import { BrandImageApiService } from 'src/app/shared/service/api/brand-image.api.service';
import { PictureUpdatedNotificationService } from 'src/app/shared/service/picture-updated-notification.service';
import { ENV_CONFIG } from 'src/env-config';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public displayHeaderExtra = false;
  public profileLogo = '';
  public hamburgerOptions: { label?: string; onClick?: () => void }[] = [
    { label: 'Application dashboard', onClick: () => this.goToApp('/home/dashboard') },
    {
      label: 'Workflow manager',
      onClick: () => this.goToApp(ENV_CONFIG.omniUrl, true),
    },
    {
      label: 'Admin platform',
      onClick: () => this.goToApp(ENV_CONFIG.platformUrl, true),
    },
  ];

  private profileLogoSubscription: Subscription;
  constructor(
    private navigationService: NavigationService,
    private brandImageApiService: BrandImageApiService,
    private pictureUpdatedNotificationService: PictureUpdatedNotificationService
  ) {}

  public ngOnInit(): void {
    this.getProfileLogo();
    this.profileLogoSubscription = this.pictureUpdatedNotificationService.profileLogoUpdate$.subscribe((url) => {
      this.profileLogo = url;
    });
  }

  public ngOnDestroy(): void {
    this.profileLogoSubscription.unsubscribe();
  }

  public toggleFixMenu(): void {
    this.navigationService.toggleHorizontalMenu();
  }

  public toggleVirticalNavigation(): void {
    this.displayHeaderExtra = false;
    this.navigationService.toggleVerticalMenu();
  }

  public toggleMoreOption(): void {
    this.displayHeaderExtra = !this.displayHeaderExtra;
  }

  private getProfileLogo(): void {
    this.brandImageApiService.getAll().subscribe((result: BrandImage[]) => {
      const profileLogoBrand = result.find((b) => b.brandTypeEnum === ChannelBrandImageBrandType.Application);
      if (profileLogoBrand) {
        this.profileLogo = profileLogoBrand.imagePath;
      }
    });
  }

  private goToApp(appURL: string, newTab = false): void {
    if (newTab) {
      window.open(appURL, '_blank');
    } else {
      window.open(appURL, '_self');
    }
  }
}
