<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1"></div>
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img src="assets/images/logo/logo-pink.png" alt="branding logo" />
                </div>
                <br />
                <br />
                <br />
                <br />
                <div *ngIf="state === State.Initial">starting</div>
                <div *ngIf="state === State.Finalising">Finalising your email change, please wait...</div>

                <div *ngIf="state === State.Complete">
                  <!--Success-->
                  <h2>Email change completed</h2>
                  <br />
                  <br />
                  Please login to continue...
                  <br />
                  <br />
                  <button type="button" class="btn btn-sm btn-primary ml-3" (click)="login()">Login</button>
                </div>
                <div *ngIf="state === State.PendingOther">Waiting for the other change confirmation...</div>
                <div *ngIf="state === State.Failure">Email change failed...</div>
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
