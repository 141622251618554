import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';

import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { AccountProfile } from '../store/profile/account-profile.model';

@Injectable({ providedIn: 'root' })
export class ProfileApiService extends BaseApiService<AccountProfile> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('Channel');
  }

  public getProfile(): Observable<AccountProfile> {
    return this.get('');
  }

  public updateProfile(profile: AccountProfile): Observable<number> {
    return this.put('', profile) as Observable<number>;
  }
}
