import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EnumDesc } from '@helper/enum-desc.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SimpologyComponentModule } from '@simpology/client-components';
import { QueryBuilderModule } from '@syncfusion/ej2-angular-querybuilder';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ApprovalProcessModalComponent } from './approval-process-modal/approval-process-modal.component';
import { BlockUiTemplateComponent } from './block-ui/block-ui-template.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AddressPickerComponent } from './component/address-picker/address-picker.component';
import { DynamicQueryBuilderComponent } from './component/dynamic-query-builder/dynamic-query-builder.component';
import { FormulaBuilderComponent } from './component/formula-builder/formula-builder.component';
import { ImageViewerComponent } from './component/image-viewer/image-viewer.component';
import { LenderLogoComponent } from './component/lender-logo/lender-logo.component';
import { MetaCheckboxComponent } from './component/metadata/meta-checkbox/meta-checkbox.component';
import { NotFoundComponent } from './component/notfound/notfound.component';
import { PictureComponent } from './component/picture/picture.component';
import { EditorComponent } from './component/popup-text-editor/editor/editor.component';
import { PopupTextEditorComponent } from './component/popup-text-editor/popup-text-editor.component';
import { UnauthorizedComponent } from './component/unauthorized/unauthorized.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationModalWithCommentComponent } from './confirmation-modal-with-comment/confirmation-modal-with-comment.component';
import { SortableHeaderDirective } from './directive/sortable-header.directive';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { GetDecimalPlacesPipe } from './pipes/get-decimal-places.pipe';
import { GetPlaceholderPipe } from './pipes/get-placeholder.pipe';
import { GetTitlePipe } from './pipes/get-title.pipe';
import { GetToolTipPipe } from './pipes/get-tooltip.pipe';
import { GetValidationMessagesPipe } from './pipes/get-validation-messages.pipe';
import { IsHiddenPipe } from './pipes/is-hidden.pipe';
import { IsReadonlyPipe } from './pipes/is-readonly.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ImageCropperModule,
    QueryBuilderModule,
    NgSelectModule,
    RichTextEditorModule,
    SimpologyComponentModule,
  ],
  exports: [
    BreadcrumbComponent,
    BlockUiTemplateComponent,
    SortableHeaderDirective,
    AddressPickerComponent,
    PictureComponent,
    ImageViewerComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    EnumDesc,
    DynamicQueryBuilderComponent,
    PopupTextEditorComponent,
    PasswordStrengthComponent,
    FormulaBuilderComponent,
    LenderLogoComponent,
    MetaCheckboxComponent,
    SimpologyComponentModule,
    GetDecimalPlacesPipe,
    GetPlaceholderPipe,
    GetTitlePipe,
    GetToolTipPipe,
    GetValidationMessagesPipe,
    IsHiddenPipe,
    IsReadonlyPipe,
    ApprovalProcessModalComponent,
    ConfirmationModalWithCommentComponent,
  ],
  declarations: [
    BreadcrumbComponent,
    BlockUiTemplateComponent,
    SortableHeaderDirective,
    AddressPickerComponent,
    ConfirmationDialogComponent,
    PictureComponent,
    UnauthorizedComponent,
    NotFoundComponent,
    EnumDesc,
    DynamicQueryBuilderComponent,
    PopupTextEditorComponent,
    EditorComponent,
    ImageViewerComponent,
    PasswordStrengthComponent,
    FormulaBuilderComponent,
    LenderLogoComponent,
    MetaCheckboxComponent,
    GetDecimalPlacesPipe,
    GetPlaceholderPipe,
    GetTitlePipe,
    GetToolTipPipe,
    GetValidationMessagesPipe,
    IsHiddenPipe,
    IsReadonlyPipe,
    ApprovalProcessModalComponent,
    ConfirmationModalWithCommentComponent,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, IsReadonlyPipe],
})
export class SharedModule {}
