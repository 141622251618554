import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { BrandImage } from '../../model/brand-image.model';
import { UploadFileDetail } from '../../model/upload-file-detail.model';

@Injectable({ providedIn: 'root' })
export class BrandImageApiService extends BaseApiService<BrandImage> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('ChannelBrandImage');
  }

  public save(brandImage: BrandImage, image: UploadFileDetail | null): Observable<BrandImage> {
    if (image) {
      const formData: FormData = this.getFormData(image, brandImage);
      return this.post('', formData as any);
    }
  }

  private getFormData(imageDetail: UploadFileDetail, brandImage: BrandImage): FormData {
    const formData: FormData = new FormData();
    formData.append('imageFormFile', imageDetail.uploadedFile, imageDetail.uploadedFile.name);

    formData.append('jsonModelData', JSON.stringify(brandImage));
    return formData;
  }
}
