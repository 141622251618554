import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';

import { Router } from '@angular/router';
import { ReferenceData } from '@shared/model/reference-data.model';
import { BaseApiService } from '@simpology/authentication';
import { UploadFileDetail } from 'src/app/shared/model/upload-file-detail.model';
import { OrganisationEntity } from '../store/entity/organisation-entity.model';

@Injectable({ providedIn: 'root' })
export class EntityApiService extends BaseApiService<OrganisationEntity> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('Entity');
  }

  public save(entity: OrganisationEntity, logo: UploadFileDetail | null): Observable<OrganisationEntity> {
    if (logo) {
      const formData: FormData = this.getFormData(logo, entity);
      return this.post('SaveWithAvatar', formData as any);
    } else {
      return this.post('', entity);
    }
  }

  private getFormData(imageDetail: UploadFileDetail, entity: OrganisationEntity): FormData {
    const formData: FormData = new FormData();
    formData.append('logoFormFile', imageDetail.uploadedFile, imageDetail.uploadedFile.name);

    formData.append('jsonModelData', JSON.stringify(entity));
    return formData;
  }

  public getEntityList(): Observable<ReferenceData[]> {
    return this.getCustom('EntityList');
  }
}
