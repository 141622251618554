/**
 * Filled in main.ts by config fetch
 * This is dynamically generated at runtime.
 * For local, it fetches from src\assets\configs\env.config.json
 * For remote, it fetched from assets\configs\env.config.json (filled with jenkins based on environment and channel)
 *
 */
export const ENV_CONFIG: EnvConfig = {} as EnvConfig;

interface EnvConfig {
  clientId: string;
  identityUrl: string;
  apiUrl: string;
  omniUrl: string;
  loanappUrl: string;
  dsServerUrl: string;
  lmiCalculatorUrl: string;
  platformUrl: string;
  metaApiUrl: string;
  ladmApiUrl: string;
  syncFusionLicenseKey: string;
  gcPdfViewerLicenseKey: string;
  enableIntercom: boolean;
  intercomAppId: string;
  logOutRedirectUri?: string;
  dynamicIdentityUrl?: Object;
  dynamicApiUrl?: Object;
}
