import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseApiService } from '@simpology/authentication';
import { ApplicationForInfoRequest } from '@solution/model/loan-applications.model';
import { FileInfo } from '@syncfusion/ej2-angular-inputs';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import {
  InfoRequest,
  InfoRequestCommon,
  InfoRequestCompleteResponse,
  InfoRequestDeclineResponse,
  InfoRequestDetail,
  InfoRequestInitialResponse,
  InfoRequestList,
  InfoRequestNominateResponse,
  InfoRequestNotification,
  InfoRequestResponse,
  InfoRequestResponseSingleDoc,
  InfoRequestReviewAction,
  InfoRequestSendModel,
  InfoRequestWithTemplate,
  NominatePersonInfo,
  ParticipantInfo,
} from '../model/inforequest.model';

@Injectable({ providedIn: 'root' })
export class InfoRequestApiService extends BaseApiService<InfoRequest> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('InfoRequest');
  }

  public getInfoRequestNotification(): Observable<InfoRequestNotification[]> {
    return this.getCustom(`GetInfoRequestNotification`);
  }

  public getMyInfoRequestNotification(): Observable<InfoRequestNotification[]> {
    return this.getCustom(`GetMyInfoRequestNotification`);
  }

  public getInfoRequest(infoRequestId: number): Observable<InfoRequest> {
    return this.get(`${infoRequestId}`);
  }

  public getInfoRequestDetail(infoRequestId: number): Observable<InfoRequestDetail> {
    return this.getCustom(`${infoRequestId}/detail`);
  }

  public getMyInfoRequests(): Observable<InfoRequestList[]> {
    return this.getCustom(`my-info-request`);
  }

  public getMyInfoRequestsByAppId(applicationId: number): Observable<InfoRequestList> {
    return this.getCustom(`my-info-request/${applicationId}`);
  }

  public getInfoRequestsByAppId(applicationId: number): Observable<InfoRequestList> {
    return this.getCustom(`application/${applicationId}`);
  }

  public getMyInfoRequestApplications(): Observable<ApplicationForInfoRequest[]> {
    return this.getCustom(`GetMyInfoRequestApplications`);
  }

  public getApplications(searchTerm: string, appId: number): Observable<ApplicationForInfoRequest[]> {
    const query = this.getApplicationsQuery(searchTerm, appId);
    return this.getCustom(query);
  }

  private getApplicationsQuery(searchTerm: string, appId: number): string {
    let query = `GetApplications`;
    if (appId) {
      query += `?applicationId=${appId}`;
      return query;
    }

    if (searchTerm) {
      query += `?searchTerm=${searchTerm}`;
      return query;
    }

    return query;
  }

  public getParticipants(applicationId: number): Observable<NominatePersonInfo[]> {
    return this.getCustom(`application/${applicationId}/participants`);
  }

  public getParticipantsForInfoRequest(applicationId: number): Observable<ParticipantInfo[]> {
    return this.getCustom(`application/${applicationId}/participants-for-info-request`);
  }

  public postInfoRequest(item: InfoRequestCommon): Observable<number> {
    return this.postCustom('', item);
  }

  public sendInfoRequest(item: InfoRequestSendModel): Observable<number> {
    return this.postCustom('Send', item);
  }

  public sendInfoRequestFromTemplate(item: InfoRequestWithTemplate): Observable<number> {
    return this.postCustom('SendFromTemplate', item);
  }

  public sendNewInfoRequests(applicationId: number): Observable<number> {
    const loanApp = {
      id: applicationId,
    } as ApplicationForInfoRequest;
    return this.postCustom('SendNewInfoRequests', loanApp);
  }

  public resendInfoRequest(item: InfoRequestReviewAction): Observable<void> {
    return this.patch('Resend', item);
  }

  public closeInfoRequest(item: InfoRequestReviewAction): Observable<void> {
    return this.patch('Close', item);
  }

  public cancelInfoRequest(item: InfoRequestReviewAction): Observable<void> {
    return this.patch('Cancel', item);
  }

  public suspendInfoRequest(item: InfoRequestReviewAction): Observable<void> {
    return this.patch('Suspend', item);
  }

  public setFraudConcern(item: InfoRequestReviewAction): Observable<void> {
    return this.patch('SetFraudConcern', item);
  }

  public reactivateInfoRequest(item: InfoRequestReviewAction): Observable<void> {
    return this.patch('Reactivate', item);
  }

  public nominateInfoRequest(item: InfoRequestNominateResponse): Observable<void> {
    return this.postCustom('Nominate', item);
  }

  public sendDocsToSupDocs(item: InfoRequestReviewAction): Observable<void> {
    return this.patch('SendToSupportingDocs', item);
  }

  public deleteAllDocs(item: InfoRequestReviewAction): Observable<void> {
    return this.patch('DeleteAllDocs', item);
  }

  public deleteInfoRequest(id: number): Observable<void> {
    return this.delete(`/${id}`);
  }

  public submitInfoRequestResponse(item: InfoRequestResponse, documents: FileInfo[]): Observable<number> {
    const formData: FormData = this.getFormData(documents, item);
    return this.postCustom('Response', formData as any);
  }

  public initializeDocResponse(item: InfoRequestInitialResponse): Observable<number> {
    return this.postCustom('InitializeDocumentResponse', item);
  }

  public submitSingleDocResponse(item: InfoRequestResponseSingleDoc, document: FileInfo): Observable<number> {
    const formData: FormData = this.getFormDataSingleDoc(document, item);
    return this.postCustom('SingleDocumentResponse', formData as any);
  }

  public completeInfoRequestResponse(item: InfoRequestCompleteResponse): Observable<void> {
    return this.patch('CompleteResponse', item);
  }

  public deleteInfoRequestResponse(responseId: number): Observable<void> {
    return this.patch(`DeleteResponse/${responseId}`, {});
  }

  public declineInfoRequest(item: InfoRequestDeclineResponse): Observable<void> {
    return this.patch('Decline', item);
  }

  private getFormData(documents: FileInfo[], item: InfoRequestResponse): FormData {
    const formData: FormData = new FormData();
    for (let i = 0; i < documents.length; i++) {
      formData.append(`files${i}`, documents[i].rawFile, documents[i].name);
    }

    formData.append('jsonModelData', JSON.stringify(item));
    return formData;
  }

  private getFormDataSingleDoc(document: FileInfo, item: InfoRequestResponseSingleDoc): FormData {
    const formData: FormData = new FormData();
    formData.append('file', document.rawFile, document.name);

    formData.append('jsonModelData', JSON.stringify(item));
    return formData;
  }

  protected getUnauthorisedMessage(error: any): string {
    return `Your credentials don't allow you to see these information requests.
      <br/>
      <br/>
      Please try again, or use an incognito/private browser in case you are already logged on with different credentials.`;
  }
}
