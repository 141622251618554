import { Injectable } from '@angular/core';
import { tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { People } from './people.model';
import { PeopleStore } from './people.store';
import { PeopleApiService } from 'src/app/shared/service/api/people-api.service';
import { UploadFileDetail } from 'src/app/shared/model/upload-file-detail.model';
import { ChannelApiService } from '../../api/channel-api.service';
import { PersonChannel } from '../../model/channel-registration.model';

@Injectable({ providedIn: 'root' })
export class PeopleService {
  public peopleListRefreshed$: Observable<void>;
  private peopleListRefreshedSource = new BehaviorSubject<void>(null);

  constructor(
    private store: PeopleStore,
    private peopleApiService: PeopleApiService,
    private channelApiService: ChannelApiService
  ) {
    this.peopleListRefreshed$ = this.peopleListRefreshedSource.asObservable();
  }

  public getAll(): Observable<People[]> {
    return this.peopleApiService.getAll().pipe(
      tap((result) => {
        this.store.set(result);
        if (result.length > 0) {
          this.setActive(result[0].id); // TODO: Should be changed to set the logged in person
          this.peopleListRefreshedSource.next();
        }
      })
    );
  }

  public getCurrentPersonChannel(): Observable<PersonChannel> {
    return this.channelApiService.getCurrentPersonChannel();
  }

  public postLogin(): Observable<void> {
    return this.peopleApiService.postCustom('PostLogin', '').pipe();
  }

  public setMyProfile(peopleId: number): void {
    this.setActive(peopleId);
  }

  public save(people: People, avatar: UploadFileDetail | null): Observable<People> {
    return this.peopleApiService.save(people, avatar).pipe(
      tap((result: People) => {
        result.hasFullDetail = true;
        this.store.upsert(result.id, result);
        this.setActive(result.id);
      })
    );
  }

  public setActive(id: number): void {
    this.store.setActive(id);
    this.peopleListRefreshedSource.next();
  }

  public setPreviousActive(): void {
    this.store.setActive({ prev: true });
  }

  public setNextActive(): void {
    this.store.setActive({ next: true });
  }

  public delete(peopleId: number): Observable<void> {
    return this.peopleApiService.delete(peopleId.toString()).pipe(
      tap((_) => {
        this.setNextActive();
        this.peopleListRefreshedSource.next();
        this.store.remove(peopleId);
      })
    );
  }

  public updateDetails(id: number): Observable<People> {
    return this.peopleApiService.get(id.toString()).pipe(
      tap((data) => {
        if (data) {
          data.hasFullDetail = true;
          this.store.update(id, data);
        }
      })
    );
  }

  public refreshApplicants(): Observable<People[]> {
    return this.peopleApiService.refreshApplicants().pipe(
      tap((data) => {
        if (data) {
          this.store.upsertMany(data);
        }
      })
    );
  }

  public updateEmailChangePending(isPending: boolean): void {
    this.store.updateActive({ isEmailChangePending: isPending });
  }
}
