import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';
import { AccountProfile } from './account-profile.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'accountProfile', resettable: true })
export class AccountProfileStore extends Store<AccountProfile> {
  constructor() {
    super({});
  }
}
