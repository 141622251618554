<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1"></div>
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-lg-6 col-md-8 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 m-0">
              <div class="card-header border-0 pb-0">
                <div class="card-title text-center">
                  <img src="assets/images/logo/default_security_logo.jfif" alt="branding logo" height="30" />
                </div>
                <div *ngIf="!registered">
                  <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                    <span>Please Sign Up</span>
                  </h6>
                  <div class="card-content">
                    <div class="card-body">
                      <form class="form-horizontal" [formGroup]="registerForm" (ngSubmit)="onRegister()">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6">
                            <fieldset class="form-group position-relative has-icon-left">
                              <input
                                type="text"
                                formControlName="firstName"
                                class="form-control"
                                [ngClass]="{
                                  'is-invalid': submitted && f.firstName.errors
                                }"
                                placeholder="First Name"
                              />
                              <div class="form-control-position">
                                <i class="far fa-user"></i>
                              </div>
                              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                <div *ngIf="f.firstName.errors.required">First name is required</div>
                              </div>
                            </fieldset>
                          </div>
                          <div class="col-12 col-sm-6 col-md-6">
                            <fieldset class="form-group position-relative has-icon-left">
                              <input
                                type="text"
                                formControlName="lastName"
                                class="form-control"
                                [ngClass]="{
                                  'is-invalid': submitted && f.lastName.errors
                                }"
                                placeholder="Last Name"
                              />
                              <div class="form-control-position">
                                <i class="far fa-user"></i>
                              </div>
                              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                <div *ngIf="f.lastName.errors.required">Last name is required</div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                        <fieldset class="form-group position-relative has-icon-left">
                          <input
                            type="text"
                            formControlName="companyName"
                            class="form-control"
                            [ngClass]="{
                              'is-invalid': submitted && f.companyName.errors
                            }"
                            placeholder="Company Name"
                          />
                          <div class="form-control-position">
                            <i class="far fa-user"></i>
                          </div>
                          <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                            <div *ngIf="f.companyName.errors.required">Company name is required</div>
                          </div>
                          <div class="help-block font-small-3"></div>
                        </fieldset>
                        <fieldset class="form-group position-relative has-icon-left">
                          <input
                            type="email"
                            formControlName="email"
                            class="form-control"
                            email
                            [ngClass]="{
                              'is-invalid': submitted && f.email.errors
                            }"
                            placeholder="Your Email Address"
                          />
                          <div class="form-control-position">
                            <i class="far fa-envelope"></i>
                          </div>
                          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email is invalid</div>
                          </div>
                          <div class="help-block font-small-3"></div>
                        </fieldset>
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6">
                            <fieldset class="form-group position-relative has-icon-left">
                              <input
                                type="password"
                                formControlName="password"
                                class="form-control"
                                [ngClass]="{
                                  'is-invalid': submitted && (f.password.errors || f.passwordStrength.errors)
                                }"
                                placeholder="Enter Password"
                                ngbPopover="Password must be 8-20 characters long, and must be difficult to guess."
                                triggers="mouseenter:mouseleave"
                              />
                              <div class="form-control-position">
                                <i class="fal fa-key"></i>
                              </div>
                              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                              </div>
                              <div *ngIf="submitted && f.passwordStrength.errors" class="invalid-feedback">
                                <div *ngIf="f.passwordStrength.errors.min">Password is not strong enough</div>
                              </div>
                              <div>
                                <app-password-strength
                                  [password]="f.password.value"
                                  (passwordScoreChanged)="onScoreChanged($event)"
                                ></app-password-strength>
                              </div>
                              <div class="help-block font-small-3"></div>
                            </fieldset>
                          </div>
                          <div class="col-12 col-sm-6 col-md-6">
                            <fieldset class="form-group position-relative has-icon-left">
                              <input
                                type="password"
                                formControlName="confirmPassword"
                                class="form-control"
                                [ngClass]="{
                                  'is-invalid': submitted && f.confirmPassword.errors
                                }"
                                placeholder="Confirm Password"
                              />
                              <div class="form-control-position">
                                <i class="fal fa-key"></i>
                              </div>
                              <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmPassword.errors.required">Confirm password is required</div>
                                <div *ngIf="f.confirmPassword.errors.mustMatch">
                                  Confirm password did not match with password
                                </div>
                              </div>
                              <div class="help-block font-small-3"></div>
                            </fieldset>
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-12">
                            <div class="custom-control custom-checkbox mb-1">
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                name="terms"
                                id="terms"
                                formControlName="terms"
                              />
                              <label class="custom-control-label" for="terms">
                                I Agree. By clicking Register, you agree to the
                                <a [routerLink]="" data-toggle="modal" data-target="#t_and_c_m">Terms and Conditions</a>
                                set out by this site, including our Cookie Use.
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 my-1">
                            <button type="submit" class="btn btn-primary btn-lg btn-block">
                              <i class="fas fa-user-plus"></i> Register
                            </button>
                          </div>
                          <div class="col-12 col-sm-6 col-md-6 my-1">
                            <a [routerLink]="['/access/login']" class="btn btn-danger btn-lg btn-block"
                              ><i class="fas fa-lock-open"></i> Login</a
                            >
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div *ngIf="registered" class="card-content pt-5 pb-5 ml-4">
                  <h2>Thank you for registering</h2>
                  <br />
                  Welcome!
                  <br />
                  <br />
                  An email has been sent to you with instructions on how to complete your registration.
                  <br />
                  <br />
                  You may <a routerLink="/access/login">login</a> once registration is complete.
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
