import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PictureUpdatedNotificationService {
  public profileLogoUpdate$: Subject<string>;
  public securityLogoUpdate$: Subject<string>;
  constructor() {
    this.profileLogoUpdate$ = new Subject<string>();
    this.securityLogoUpdate$ = new Subject<string>();
  }
}
