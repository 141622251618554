import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { persistState } from '@datorama/akita';
import { registerLicense } from '@syncfusion/ej2-base';

import { AppModule } from './app/app.module';
import { ENV_CONFIG } from './env-config';
import { environment } from './environments/environment';

fetch('./assets/configs/env.config.json')
  .then((response) => response.json())
  .then((config) => {
    const fullConfig = {
      ...config,
      ...environment,
    };
    Object.assign(ENV_CONFIG, fullConfig);
    registerLicense(ENV_CONFIG.syncFusionLicenseKey);

    if (environment.production) {
      enableProdMode();
    }

    persistState({
      include: ['authentication'],
      key: 'Simpology',
    });

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
