import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { OrganisationEntityStore } from './organisation-entity.store';
import { OrganisationEntity } from './organisation-entity.model';
import { OrganisationEntityQuery } from './organisation-entity.query';
import { EntityApiService } from '../../api/entity-api.service';
import { UploadFileDetail } from 'src/app/shared/model/upload-file-detail.model';

@Injectable({ providedIn: 'root' })
export class OrganisationEntityService {
  public entityListRefreshed$: Observable<void>;
  private entityListRefreshedSource = new BehaviorSubject<void>(null);

  constructor(
    private store: OrganisationEntityStore,
    private entityApiService: EntityApiService,
    private organisationEntityQuery: OrganisationEntityQuery
  ) {
    this.entityListRefreshed$ = this.entityListRefreshedSource.asObservable();
  }

  public getAllFromCache(): Observable<OrganisationEntity[]> {
    const cachedData = this.organisationEntityQuery.getActive();
    if (!cachedData) {
      return this.getAll().pipe(
        mergeMap(() => {
          const organisations = this.organisationEntityQuery.getAll();
          return of(organisations);
        })
      );
    } else {
      const organisations = this.organisationEntityQuery.getAll();
      return of(organisations);
    }
  }

  public getAll(): Observable<OrganisationEntity[]> {
    return this.entityApiService.getAll().pipe(
      tap((result) => {
        this.store.set(result);
        if (result.length > 0) {
          this.setActive(result[0].id); // TODO: Should be changed to set the logged in entity
          this.entityListRefreshedSource.next();
        }
      })
    );
  }

  public notifyEntityListRefreshed(): void {
    this.entityListRefreshedSource.next();
  }

  public setActive(id: number): void {
    this.store.setActive(id);
  }

  public setPreviousActive(): void {
    this.store.setActive({ prev: true });
  }

  public setNextActive(): void {
    this.store.setActive({ next: true });
  }

  public save(entity: OrganisationEntity, logo: UploadFileDetail | null): Observable<OrganisationEntity> {
    return this.entityApiService.save(entity, logo).pipe(
      tap((result: OrganisationEntity) => {
        this.store.upsert(result.id, result);
        this.setActive(result.id);
      })
    );
  }

  public delete(entityId: number): Observable<void> {
    return this.entityApiService.delete(entityId.toString()).pipe(
      tap((_) => {
        this.setNextActive();
        this.store.remove(entityId);
        this.entityListRefreshedSource.next();
      })
    );
  }
}
