<!-- fixed-top-->
<nav
  class="
    top-header
    header-navbar
    navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center
    p-0
  "
>
  <div class="navbar-wrapper">
    <div class="navbar-header px-4 py-4 py-md-0">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item d-md-none mr-auto">
          <a class="nav-link" (click)="toggleVirticalNavigation()"><i class="fal fa-bars"></i></a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/home/dashboard']" class="navbar-brand">
            <img alt="Simpology" height="36" [src]="profileLogo" *ngIf="profileLogo" />
          </a>
        </li>

        <li class="nav-item d-md-none">
          <a class="nav-link" data-toggle="collapse" data-target="#navbar-mobile" (click)="toggleMoreOption()"
            ><i class="fal fa-ellipsis-v-alt"></i
          ></a>
        </li>
      </ul>
    </div>
    <div class="navbar-container content">
      <div class="collapse navbar-collapse" [ngClass]="{ show: displayHeaderExtra }" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block" ngbDropdown>
            <button type="button" ngbDropdownToggle id="hamburgerMenu" class="btn simp-button custom-dropdown-toggle">
              <i class="fa fa-bars"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="hamburgerMenu">
              <button
                *ngFor="let option of hamburgerOptions"
                class="simp-text"
                type="button"
                (click)="option.onClick()"
                ngbDropdownItem
              >
                {{ option.label }}
              </button>
            </div>
          </li>
        </ul>
        <app-header-extra></app-header-extra>
      </div>
    </div>
  </div>
</nav>
