import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CurrentUser } from '@shared/model/current-user.model';
import { PostLoginService } from '@shared/service/post-login.service';
import { AuthService } from '@simpology/authentication';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuItem } from '../navigation.model';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-horizontal-navigation',
  templateUrl: './horizontal-navigation.component.html',
  styleUrls: ['./horizontal-navigation.component.scss'],
})
export class HorizontalNavigationComponent implements OnInit, OnDestroy {
  @Input() public menuItems: MenuItem[];
  private routerSubscription: Subscription;
  private resetSelectedMenuSubscription: Subscription;
  public channelName: string;
  public entityName: string;
  public hasMultiChannels: boolean;
  public hasMultiEntities: boolean;
  private postLoginSubscription: Subscription;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private postLoginService: PostLoginService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.navigationService.setSelectedMenuItemInNavbar(this.menuItems, this.router.url);
      });

    this.resetSelectedMenuSubscription = this.navigationService.resetSelectedMenuByRoute$.subscribe((_) => {
      this.navigationService.setSelectedMenuItemInNavbar(this.menuItems, this.router.url);
    });

    this.postLoginSubscription = this.postLoginService.loginCompleted$.subscribe((result: CurrentUser) => {
      if (result) {
        this.channelName = result.channelName;
        this.entityName = result.entityName;
        this.hasMultiChannels = result.hasMultiChannels;
        this.hasMultiEntities = result.hasMultiEntities;
      }
    });
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.resetSelectedMenuSubscription.unsubscribe();
    this.postLoginSubscription.unsubscribe();
  }

  get url(): string {
    return this.router.url;
  }

  public onWindowScroll(e: any): void {
    // TODO ?
  }

  public mouseEnter(e: any): void {
    const event = e;
    event.srcElement.classList.add('show');
  }

  public mouseLeave(e: any): void {
    const event = e;
    event.srcElement.classList.remove('show');
  }

  public toggleMenu(event: any, child: any, isSubmenuOfSubmenu: boolean): void {
    this.navigationService.toggleMenu(child, isSubmenuOfSubmenu, this.menuItems);
  }

  public logOutToChannelSelection(): void {
    if (this.authService.isExternalUser) {
      this.logOut();
      return;
    }

    this.authService.signoutToSwitch({
      extraQueryParams: {
        goToChannelSelection: true,
      },
    });
  }

  public logOut(): void {
    this.authService.signout();
  }

  public generateNavigationTestId(title: string): string {
  return 'nav-' + title.toLowerCase().split(' ').join('-');
}
}
