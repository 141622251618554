<div class="app-content content">
  <div class="content-wrapper">
    <div class="container body-content">
      <div class="content-body">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-lg-6 col-md-8 col-12 box-shadow-2 p-0">
            <section class="flexbox-container">
              <div class="card border-grey border-lighten-3 m-0">
                <div class="card-header border-0">
                  <div class="card-title text-center pt-3">
                    <img src="assets/images/logo/logo-pink.png" alt="branding logo" class="img-fluid" />
                  </div>
                  <div *ngIf="state === FormState.Initial">Please wait..</div>
                  <div *ngIf="state === FormState.Done">
                    <div class="row my-5 py-3">
                      <div class="col-12">
                        <fieldset class="form-group position-relative has-icon-left">
                          All done, your account is ready!
                        </fieldset>
                      </div>
                    </div>
                    <div class="my-4">
                      <button class="btn btn-primary btn-block" (click)="login()">
                        <i class="fas fa-lock-open"></i> Proceed to Login
                      </button>
                    </div>
                  </div>
                  <div *ngIf="state === FormState.Finalising">
                    <div class="card-content">
                      <div class="card-body">
                        <form class="form-horizontal" [formGroup]="finaliseForm" (ngSubmit)="onFinalise()">
                          <div class="row">
                            <div class="col-12">
                              <fieldset class="form-group position-relative has-icon-left">
                                Hi {{ person?.firstName }}, set your password to secure your account
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <fieldset class="form-group position-relative has-icon-left">
                                Your username: {{ person?.email }}
                              </fieldset>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <fieldset class="form-group position-relative has-icon-left mb-0">
                                <input
                                  type="password"
                                  formControlName="password"
                                  class="form-control"
                                  [ngClass]="{
                                    'is-invalid': submitted && (f.password.errors || f.passwordStrength.errors)
                                  }"
                                  placeholder="Enter Password"
                                  ngbPopover="Password must be 8-20 characters long, and must be difficult to guess."
                                  triggers="mouseenter:mouseleave"
                                />
                                <div class="form-control-position">
                                  <i class="fal fa-key"></i>
                                </div>
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                  <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                                <div *ngIf="submitted && f.passwordStrength.errors" class="invalid-feedback">
                                  <div *ngIf="f.passwordStrength.errors.min">Password is not strong enough</div>
                                </div>
                                <div>
                                  <app-password-strength
                                    [password]="f.password.value"
                                    (passwordScoreChanged)="onScoreChanged($event)"
                                  ></app-password-strength>
                                </div>
                                <div class="help-block font-small-3"></div>
                              </fieldset>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12">
                              <fieldset class="form-group position-relative has-icon-left">
                                <input
                                  type="password"
                                  formControlName="confirmPassword"
                                  class="form-control"
                                  [ngClass]="{
                                    'is-invalid': submitted && f.confirmPassword.errors
                                  }"
                                  placeholder="Confirm Password"
                                />
                                <div class="form-control-position">
                                  <i class="fal fa-key"></i>
                                </div>
                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                  <div *ngIf="f.confirmPassword.errors.required">Confirm password is required</div>
                                  <div *ngIf="f.confirmPassword.errors.mustMatch">
                                    Confirm password did not match with password
                                  </div>
                                </div>
                                <div class="help-block font-small-3"></div>
                              </fieldset>
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-12">
                              <button type="submit" class="btn btn-primary btn-block">
                                <i class="fas fa-user"></i> Set password
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
