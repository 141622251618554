import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@simpology/authentication';
import { Subscription } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';
import { AccountProfile } from '../account/store/profile/account-profile.model';
import { AccountProfileService } from '../account/store/profile/account-profile.service';
import { MenuItem } from './navigation.model';
import { NavigationService } from './navigation.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  public menuItems: MenuItem[];
  public collapseHorizontalMenu = false;
  public showVerticalMenu = false;

  private verticalMenuToggleSubscription: Subscription;
  private horizontalMenuToggleSubscription: Subscription;

  constructor(
    private navigationService: NavigationService,
    private profileService: AccountProfileService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.menuItems = this.navigationService.getMenuItems();
    this.profileService.getFromCache().subscribe((result: AccountProfile) => {
      const subMenu = this.menuItems.find((m) => m.title === 'Solutions');
      if (result && result.prequalificationUrl) {
        //TODO: Instead of check by title, we can introduce unique keys for menu items and access using it. Future enhancement!!!
        const prequalifyMenu = subMenu?.submenuItems.find((m) => m.title === 'Prequalification');
        if (prequalifyMenu) {
          prequalifyMenu.page = `${result.prequalificationUrl}/home/${this.authService.id}`;
        }
      }
      const omniMenu = subMenu?.submenuItems.find((m) => m.title === 'Omni');
      if (omniMenu) {
        omniMenu.page = `${ENV_CONFIG.omniUrl}?returnUrl=/solutions/omni-communication`;
      }
      const loanappMenu = subMenu?.submenuItems.find((m) => m.title === 'Loanapp');
      if (loanappMenu) {
        const loannappUrl = result.loanappUrl ?? ENV_CONFIG.loanappUrl;
        loanappMenu.page = `${loannappUrl}`;
      }
      const lmiCalculatorMenu = subMenu?.submenuItems.find((m) => m.title === 'LMI/LDP calculator');
      if (lmiCalculatorMenu) {
        lmiCalculatorMenu.page = `${ENV_CONFIG.lmiCalculatorUrl}?returnUrl=/lmi`;
      }

      if (result && result.serviceabilityJourneyUrl) {
        const servicingMenu = subMenu?.submenuItems.find((m) => m.title === 'Servicing');
        if (servicingMenu) {
          servicingMenu.page = `${result.serviceabilityJourneyUrl}`;
        }
      }

      if (result && result?.clientSupportUrl) {
        const helpMenu = this.menuItems.find((m) => m.title === 'Help');
        const supportMenu = helpMenu?.submenuItems.find((m) => m.title === 'Support');
        if (supportMenu) {
          supportMenu.page = result.clientSupportUrl;
        }
      }

      const helpMenu = this.menuItems.find((m) => m.title === 'Help');
      const knowledgeBaseMenuIndex = helpMenu?.submenuItems.findIndex((m) => m.title === 'Knowledge Base');
      if (result && helpMenu && knowledgeBaseMenuIndex != -1) {
        if (result?.clientKnowledgebaseUrl) {
          helpMenu.submenuItems[knowledgeBaseMenuIndex].page = result.clientKnowledgebaseUrl;
        } else {
          helpMenu.submenuItems.splice(knowledgeBaseMenuIndex, 1);
        }
      }
    });
    this.subscribeVerticalMenuToggleEvent();
    this.subscribeHorizontalMenuToggleEvent();
  }

  public ngOnDestroy(): void {
    this.verticalMenuToggleSubscription.unsubscribe();
    this.horizontalMenuToggleSubscription.unsubscribe();
  }

  private subscribeVerticalMenuToggleEvent(): void {
    this.verticalMenuToggleSubscription = this.navigationService.verticalMenuToggler$.subscribe((value: boolean) => {
      this.showVerticalMenu = value;
    });
  }

  private subscribeHorizontalMenuToggleEvent(): void {
    this.horizontalMenuToggleSubscription = this.navigationService.horizontalMenuToggler$.subscribe(
      (value: boolean) => {
        this.collapseHorizontalMenu = value;
      }
    );
  }
}
