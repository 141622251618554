import { Component, OnInit } from '@angular/core';
import { AuthService } from '@simpology/authentication';
import { VersionApiService } from '../version/api/version.api.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(private authService: AuthService, private versionApiService: VersionApiService) {}

  public serverVersion: string;
  public libVersion: string;
  public copyright = `2021-${new Date().getFullYear()}`;

  public ngOnInit(): void {
    this.versionApiService.getVersion().subscribe((ver) => {
      this.serverVersion = `${ver[0].major}.${ver[0].minor}.${ver[0].patch}.${ver[0].build}`;
      this.libVersion = `${ver[1].major}.${ver[1].minor}.${ver[1].patch}.${ver[1].build}`;
    });
  }

  public authenticated(): boolean {
    return this.authService.isAuthenticated();
  }
}
