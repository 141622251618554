// ------------------------------------------------------------------------------
// This file is autogenerated
// ------------------------------------------------------------------------------

// tslint:disable:max-line-length

export const permissionId = {
  AllowedToManageSecurePermissions: 1054,
  // Global / YesNo
  // Elevated security level for management of security functions, only set for system admin person.

  PermissionGroups: 1055,
  // Global / YesNo
  // Ability to add or remove permission groups, only set for system admin person. User can then be allocated to the required security groups

  ManagePermissions: 1002,
  // Global / YesNo
  // Enables user to modify permission settings.

  ManagePermissionGroupAPICredentials: 1003,
  // Global / YesNo
  // Allow access to API credentials and enable client secret reset, use with caution.

  AuditLogSecureItemDetails: 1112,
  // Global / YesNo
  // Allow access to logs flagged as secure

  ManageMenuAccess: 1005,
  // Global / YesNo
  // Allow user to manage menu access by security group

  ChannelMenu: 1006,
  // Global / YesNo
  // Show or hide channel menu

  EntitiesMenu: 1007,
  // Global / YesNo
  // Show or hide entities menu

  PeopleMenu: 1008,
  // Global / YesNo
  // Show or hide People menu

  DirectoryMenu: 1009,
  // Global / YesNo
  // Show or hide Directory menu

  BrokerMenu: 1010,
  // Global / YesNo
  // Show or hide Broker menu

  InfoRequestMenu: 1011,
  // Global / YesNo
  // Show or hide Info request menu

  MyInfoRequestMenu: 1099,
  // Global / YesNo
  // Show or hide My info request menu

  SupportingDocsMenu: 1012,
  // Global / YesNo
  // Show or hide Supporting docs menu

  DocumentVerificationMenu: 1013,
  // Global / YesNo
  // Show or hide Document verification menu

  AccountProfileMenu: 1014,
  // Global / YesNo
  // Show or hide Account profile menu

  PermissionsMenu: 1015,
  // Global / YesNo
  // Show or hide Permissions menu

  SubscriptionMenu: 1016,
  // Global / YesNo
  // Show or hide Subscription menu

  DataSubscriptionMenu: 1017,
  // Global / YesNo
  // Show or hide Data subscription menu

  ActionItemMenu: 1018,
  // Global / YesNo
  // Show or hide Action item menu

  DashboardMenu: 1019,
  // Global / YesNo
  // Always available - landing page, dashboard widgets will each have their own security setting.

  CommunicationsMenu: 1020,
  // Global / YesNo
  // Show or hide Communications menu

  LibraryMenu: 1021,
  // Global / YesNo
  // Show or hide Library menu

  ContactUsMenu: 1022,
  // Global / YesNo
  // Show or hide Contact us menu

  SupportMenu: 1023,
  // Global / YesNo
  // Show or hide Support menu

  ListMaintenanceMenu: 1024,
  // Global / YesNo
  // Show or hide List maintenance menu

  AutoNotifications: 1025,
  // Global / YesNo
  // Show or hide Auto notifications menu

  LoanApplicationsMenu: 1056,
  // Global / YesNo
  // Show or hide Loan applications menu

  ApplicationTemplateMenu: 1067,
  // Global / YesNo
  // Show or hide Application template setup menu

  DemoMenu: 1079,
  // Global / YesNo
  // Show or hide demo mode menu

  SpecialOfferSetupMenu: 1088,
  // Global / YesNo
  // Show or hide Special offer setup menu

  NewsAndAnnouncementsMenu: 1089,
  // Global / YesNo
  // Show or hide News and announcements menu

  DiscountPackagesMenu: 1090,
  // Global / YesNo
  // Show or hide Discount packages menu

  ComparisonCalculatorMenu: 1091,
  // Global / YesNo
  // Show or hide Comparison calculator menu

  ServiceabilityMetricsMenu: 1092,
  // Global / YesNo
  // Show or hide Serviceability metrics menu

  ProductFeeSetupMenu: 1093,
  // Global / YesNo
  // Show or hide Product fee setup menu

  ServiceabilitySetupMenu: 1094,
  // Global / YesNo
  // Show or hide Serviceability setup menu

  ApplicationStatusSetupMenu: 1095,
  // Global / YesNo
  // Show or hide Application status setup menu

  ProductMenu: 1096,
  // Global / YesNo
  // Show or hide Product menu

  ServiceabilityMenu: 1097,
  // Global / YesNo
  // Show or hide Serviceability menu

  AlertSetupMenu: 1101,
  // Global / YesNo
  // Show or hide Alert setup menu

  PrequalificationMenu: 1105,
  // Global / YesNo
  // Show or hide prequalification menu

  ActionProcessTemplateSetupMenu: 1106,
  // Global / YesNo
  // Show or hide Action process template setup menu

  OmniMenu: 1108,
  // Global / YesNo
  // Show or hide Omni menu

  AuditLogMenu: 1111,
  // Global / YesNo
  // Show or hide audit log menu

  TextControlDocumentTestMenu: 1115,
  // Global / YesNo
  // Show or hide TextControl Document test menu

  DocumentManagerMenu: 1116,
  // Global / YesNo
  // Show or hide Document manager menu

  PolicyAndDecisioningMenu: 1119,
  // Global / YesNo
  // Show or hide Policy and decisioning menu

  JourneySetupMenu: 1122,
  // Global / YesNo
  // Show or hide Journey menu

  CalculatorSetupMenu: 1124,
  // Global / YesNo
  // Show or hide Calculator menu

  LoanappMenu: 1126,
  // Global / YesNo
  // Show or hide Loanapp V2 menu

  LMICalculatorMenu: 1139,
  // Global / YesNo
  // Show or hide LMI/LDP calculator menu

  ServiceabilityJourneyMenu: 1144,
  // Global / YesNo
  // Show or hide Serviceability journey menu

  UserProfileContextMenu: 1147,
  // Global / YesNo
  // Allow user to edit user profile.

  ChangePasswordUserContextMenu: 1148,
  // Global / YesNo
  // Allow user to change the password.

  BCMManagementSetupMenu: 1152,
  // Global / YesNo
  // Show or hide the BCM management setup menu

  KnowledgeBaseMenu: 1159,
  // Global / YesNo
  // Show or hide Knowledge base menu

  ChannelManagement: 1027,
  // Global / ReadWrite
  // Allow channel management, which associates entities which will drive visibility of users and loan applications based on hierarchy.

  EntityManagement: 1028,
  // Tiered / ReadWrite
  // Manage participating entity / company data.

  PeopleManagement: 1029,
  // Tiered / ReadWrite
  // Manage user information.

  BrokerManagement: 1030,
  // Tiered / ReadWrite
  // Manage broker information.

  DirectoryAccess: 1031,
  // Tiered / YesNo
  // Allow access to the User directory.

  BasePeopleManagement: 1080,
  // Tiered / ReadWrite
  // Manage base user information only, cannot change role/permissions or delete user

  AuditLogManagement: 1113,
  // Global / YesNo
  // Allow user to view audit log page

  OmniGroupManagement: 1114,
  // Global / YesNo
  // Manage visibility levels for omni groups

  EntityListAccess: 1123,
  // Global / YesNo
  // Allow access to user channel entities

  AccessChildEntities: 1162,
  // Global / YesNo
  // Can access child entities

  ConsumerAccess: 1033,
  // Global / YesNo
  // Allow all consumer or applicant access, e.g. for info requests, or loan submission.

  DocumentVerification: 1035,
  // Tiered / ReadWrite
  // Use the document verification functionality.

  DownloadDocsOnDocumentVerification: 1137,
  // Global / YesNo
  // Download a document to the user''s local machine.

  PrintDocsOnDocumentVerification: 1138,
  // Global / YesNo
  // Print a document on Document verification docs page.

  ViewSupportingDocs: 1038,
  // Tiered / YesNo
  // Enable access to view supporting docs.

  ManageDocs: 1044,
  // Global / YesNo
  // Upload and view supporting docs

  SplitDocs: 1045,
  // Global / YesNo
  // Split combined documents into separate docs for categorisation.

  CategoriseDocs: 1046,
  // Global / YesNo
  // Categorise support docs by loading into templates

  ViewDocumentFraudWarnings: 1127,
  // Global / YesNo
  // Allow user to view fraud warnings for documents.

  DownloadSupportingDocs: 1135,
  // Global / YesNo
  // Download a document to the user''s local machine.

  PrintSupportingDocs: 1136,
  // Global / YesNo
  // Print a document on Supporting docs page.

  EnableDocumentFraudCheck: 1140,
  // Global / YesNo
  // Enable manual fraud check button for documents.

  SubmitDocumentsToLender: 1142,
  // Global / YesNo
  // Enable submit documents to lender in supporting docs

  DeleteSystemGeneratedDocuments: 1146,
  // Global / YesNo
  // Allow user to delete system generated documents.

  ManageDocumentSet: 1149,
  // Global / YesNo
  // Allow user to add or delete a document set

  RegulatorAdminAccess: 1049,
  // Global / YesNo
  // ASIC or APRA administrator access

  CombinedIndustryForumAdmin: 1050,
  // Global / YesNo
  // Combined Industry Forum administrator access.

  SecureBrokerInformation: 1051,
  // Tiered / ReadWrite
  // Access and manage the secure elements of broker information.

  BrokerInformation: 1052,
  // Tiered / ReadWrite
  // Access and manage broker information.

  SendBrokerProfile: 1053,
  // Tiered / ReadWrite
  // Share broker profile information.

  ApplicationUserAuthorisationLevel: 1057,
  // Tiered / YesNo
  // Application user authorisation level.

  ApplicationAuthorisationLevel: 1058,
  // Tiered / YesNo
  // Set authorisation level for the application.

  ApplicationManagement: 1063,
  // Tiered / ReadWrite
  // View or Edit the application.

  DeleteApplication: 1064,
  // Tiered / YesNo
  // Delete the application.

  InfoRequestsManagement: 1065,
  // Tiered / ReadWrite
  // Access and manage the application info requests.

  MyInfoRequestsManagement: 1098,
  // Global / YesNo
  // Access and manage info requests sent to the login user.

  ApplicationTemplateManagement: 1066,
  // Global / ReadWrite
  // Access and manage the application template.

  CoordinateApplication: 1068,
  // Global / YesNo
  // Allow to coordinate application.

  ApplicationStatusSetup: 1086,
  // Global / ReadWrite
  // Access and manage application status setup.

  SetAllApplicationActivityStatuses: 1100,
  // Global / YesNo
  // Allow setting of all activity statuses that the current user role does not have (Show all).

  AIPApplicationManagement: 1103,
  // Global / ReadWrite
  // Access and manage the Approval in Principal application.

  EnableLoanappProcessing: 1104,
  // Global / YesNo
  // Allow to process AIP application by Loanapp.

  ActionProcessTemplateManagement: 1107,
  // Global / ReadWrite
  // Access and manage the action process template.

  OverwriteApplicationActivityStatuses: 1109,
  // Global / YesNo
  // Allow to overwrite existing status in any circumstance (Even with Finalised application).

  EnableApplicationExport: 1141,
  // Tiered / YesNo
  // Allow to export application.

  PromoteToFullApplication: 1143,
  // Global / YesNo
  // Allow to promote to full application from initial sources (AIP).

  ViewRefiApplication: 1145,
  // Tiered / YesNo
  // Allow viewing of refinance application

  BCMManagement: 1153,
  // Global / ReadWrite
  // Access and manage back channel messages

  EnableApplicationCopy: 1154,
  // Global / YesNo
  // Allow copying applications

  CanAcceptResponseAndCloseRequest: 1155,
  // Global / YesNo
  // Allow to accept response and close request

  CanNominateOrRedirectToAnotherPerson: 1156,
  // Global / YesNo
  // Allow to nominate or redirect to another person

  CanSuspendRequest: 1157,
  // Global / YesNo
  // Allow to suspend request with reason

  CanRaiseFraudConcern: 1158,
  // Global / YesNo
  // Allow to raise fraud concern on an info request response

  ManageProductData: 1069,
  // Global / ReadWrite
  // Access and manage loan product data.

  ManageProductVersions: 1070,
  // Global / ReadWrite
  // Access and manage loan product versions.

  ManagePolicyData: 1071,
  // Global / ReadWrite
  // Access and manage policy data.

  ManagePolicyVersions: 1072,
  // Global / ReadWrite
  // Access and manage policy versions.

  ManageServiceabilityData: 1073,
  // Global / ReadWrite
  // Access and manage serviceability data.

  ManageServiceabilityVersions: 1074,
  // Global / ReadWrite
  // Access and manage serviceability versions.

  ManageLoanDataPublication: 1075,
  // Global / ReadWrite
  // Provides access to the Publish on the Data Subscription page, and enables publish actions in that section. Also, allows access to view and edit the Data Access and Security section on the Account/Profile page

  ManageLoanDataSubscription: 1076,
  // Global / ReadWrite
  // Provides access to the Subscription section on the Data Subscription page, and enables subscription actions in that section

  ManageDiscountPackages: 1077,
  // Global / ReadWrite
  // Access and manage discount packages.

  ManageComparisonCalc: 1078,
  // Global / ReadWrite
  // Access and manage comparison rate calculator.

  ManageFeeTypeSetup: 1081,
  // Global / ReadWrite
  // Access and manage fee type setup.

  ManageServiceabilitySetup: 1085,
  // Global / ReadWrite
  // Access and manage serviceability setup.

  ManageSpecialOfferSetup: 1087,
  // Global / ReadWrite
  // Access and manage special offer setup.

  ManageStandardFeeSetup: 1110,
  // Global / ReadWrite
  // Access and manage standard fees setup.

  SetOfficialLender: 1118,
  // Global / YesNo
  // Permission to set the official lender for a lender type entity

  ManageJourneySetup: 1121,
  // Global / ReadWrite
  // Access and manage journey setup.

  ManageCalculatorSetup: 1125,
  // Global / ReadWrite
  // Access and manage calculator setup.

  EnableCreateDraftVersion: 1129,
  // Global / YesNo
  // Allow to create draft version for product.

  EnableReleaseProductVersion: 1130,
  // Global / YesNo
  // Allow to release product version.

  EnableProductExport: 1131,
  // Global / YesNo
  // Allow to export product.

  SubscriberCustomisation: 1151,
  // Global / ReadWrite
  // Allow customisation of subscribed products

  TaxCoefficientManagement: 1160,
  // Global / ReadWrite
  // Access and manage tax coefficient calculations

  EnableReleaseTaxCoefficientVersion: 1161,
  // Global / YesNo
  // Allow to release tax coefficient version.

  PostOnNewsFeed: 1082,
  // Global / YesNo
  // Access to create posts on channel the news feed.

  CommentOnNewsFeedPosts: 1083,
  // Global / YesNo
  // Access to comment on posts on the channel news feed.

  DeleteNewsFeedContent: 1084,
  // Global / YesNo
  // Access to delete posts and comments from the channel news feed.

  AccessDashboard: 1128,
  // Global / YesNo
  // Access to view dashboard information.

  ManageAlertAndNotificationSetup: 1102,
  // Global / ReadWrite
  // Access and manage alert and notification setup.

  ManageTemplateDocumentData: 1117,
  // Global / ReadWrite
  // Manage template document data.

  ManagePolicyAndDecisioningRules: 1120,
  // Global / ReadWrite
  // Manage policy and decisioning rules

  ImportRulesToReleasedRuleSet: 1150,
  // Global / YesNo
  // Allow to import rules to the released version of the decision rule set

  ManageLoanInsuranceCalculatorSetup: 1132,
  // Global / ReadWrite
  // Access and manage LMI/LDP calculator setup

  EnableCreateDraftVersionForLoanInsurance: 1133,
  // Global / YesNo
  // Allow to create draft version for LMI/LDP calculator.

  EnableReleaseLoanInsuranceCalculatorVersion: 1134,
  // Global / YesNo
  // Allow to release LMI/LDP calculator version.

  ServiceabilityEngineDecision: 1164,
  // Global / YesNo
  // Serviceability engine decision
  ServiceabilityEngineRemind: 1166,
  // Global / YesNo
  // Serviceability engine remind
  ServiceabilityEngineView: 1165,
  // Global / YesNo
  // Serviceability engine view
  ServiceabilityMetricsDecision: 1167,
  // Global / YesNo
  // Serviceability metrics decision
  ServiceabilityMetricsRemind: 1169,
  // Global / YesNo
  // Serviceability metrics remind
  ServiceabilityMetricsView: 1168,
  // Global / YesNo
  // Serviceability metrics view
};
