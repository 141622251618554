import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimpAuthenticationModule } from '@simpology/authentication';
import { ToastrModule } from 'ngx-toastr';
import { ENV_CONFIG } from 'src/env-config';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { ExternalLoginComponent } from './authentication/external-login/external-login.component';
import { ConfirmEmailChangeComponent } from './confirm-email-change/confirm-email-change.component';
import { FinalisePersonComponent } from './finalise/finalise-person.component';
import { FinaliseComponent } from './finalise/finalise.component';
import { LayoutComponent } from './layout/layout.component';
import { NavigationModule } from './navigation/navigation.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    FinaliseComponent,
    FinalisePersonComponent,
    ExternalLoginComponent,
    ConfirmEmailChangeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    NavigationModule,
    AuthenticationModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    SimpAuthenticationModule.forRoot({
      environment: ENV_CONFIG,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
