import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidatorFn } from '@helper/custom.validator';
import { PersonStatus } from '@shared/model/client-enum.model';
import { People } from '../organisation/store/people/people.model';
import { PeopleApiService } from '../shared/service/api/people-api.service';

export enum State {
  Initial = 1,
  Finalising = 2,
  Done = 3,
}

@Component({
  selector: 'app-finalise-person',
  templateUrl: './finalise-person.component.html',
  styleUrls: ['./finalise-person.component.scss'],
})
export class FinalisePersonComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private peopleApiService: PeopleApiService
  ) {}

  public finaliseForm: UntypedFormGroup;
  public state: State = State.Initial;
  public FormState = State;
  private key: string;
  private score = 0;
  public person: People;
  public submitted = false;

  public ngOnInit(): void {
    this.key = this.route.snapshot.queryParams['key'];

    this.finaliseForm = this.formBuilder.group(
      {
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
        passwordStrength: [0, [Validators.min(2)]],
      },
      {
        validator: [CustomValidatorFn.MustMatch('password', 'confirmPassword')],
      }
    );
    this.getPerson();
  }

  get f(): any {
    return this.finaliseForm.controls;
  }

  public onScoreChanged(score: number): void {
    this.score = score;
  }

  private getPerson(): void {
    this.peopleApiService.getPersonByRegistrationKey(this.key).subscribe(
      (person: People) => {
        if (person?.statusEnum === PersonStatus.PreRegistered) {
          this.state = State.Finalising;
          this.person = person;
        } else {
          this.login();
        }
      },
      (error) => {
        this.login();
      }
    );
  }

  public login(): void {
    this.router.navigateByUrl('/access/login');
  }

  public onFinalise(): void {
    this.finaliseForm.controls.passwordStrength.setValue(this.score);
    this.submitted = true;
    if (this.finaliseForm.invalid) {
      return;
    }
    this.peopleApiService
      .finalise({
        registrationKey: this.key,
        password: this.f.password.value,
      })
      .subscribe(() => {
        this.state = State.Done;
      });
  }
}
