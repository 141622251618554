import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserAccessService } from '@shared/service/user-access-service';
import { AuthService } from '@simpology/authentication';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public submitted = false;

  private channelId: string;
  private authority: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userAccessService: UserAccessService
  ) { }

  public ngOnInit(): void {
    this.userAccessService.resetLogInUser();
    this.authService.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home/default';

    this.channelId = this.route.snapshot.queryParams['channelId'];
    this.authority = this.route.snapshot.queryParams['iss'];

    if (this.channelId && this.authority) {
      this.authService.login({
        extraQueryParams: {
          channelId: this.channelId,
          authority: this.authority
        }
      });
    } else {
      this.authService.login();
    }
  }
}
