import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PeopleApiService } from '@shared/service/api/people-api.service';
enum State {
  Initial = 1,
  Finalising = 2,
  Complete = 3,
  PendingOther = 4,
  Failure = 5,
}
@Component({
  selector: 'app-confirm-email-change',
  templateUrl: './confirm-email-change.component.html',
  styleUrls: ['./confirm-email-change.component.scss'],
})
export class ConfirmEmailChangeComponent implements OnInit {
  constructor(private route: ActivatedRoute, private peopleApiService: PeopleApiService, private router: Router) {}

  public state: State = State.Initial;
  private channelId: number;
  private personId: number;
  private key: string;
  private which: string;
  public State = State;

  public ngOnInit(): void {
    this.channelId = +this.route.snapshot.queryParams['channelId'];
    this.personId = +this.route.snapshot.queryParams['personId'];
    this.key = this.route.snapshot.queryParams['key'];
    this.which = this.route.snapshot.queryParams['which'];

    this.finaliseConfirmation();
  }

  public login(): void {
    this.router.navigateByUrl('/access/login');
  }

  private finaliseConfirmation(): void {
    this.state = State.Finalising;
    this.peopleApiService
      .confirmEmailChange({
        channelId: this.channelId,
        personId: this.personId,
        confirmationKey: this.key,
        which: this.which,
      })
      .subscribe(
        (isComplete) => {
          this.state = isComplete ? State.Complete : State.PendingOther;
        },
        (error) => {
          this.state = State.Failure;
        }
      );
  }
}
