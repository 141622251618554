import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccessService } from '@shared/service/user-access-service';
import { AuthService } from '@simpology/authentication';
import { UserAccessApiService } from '../api/user-access-api.service';

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss'],
})
export class ExternalLoginComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userAccessService: UserAccessService,
    private userAccessApiService: UserAccessApiService
  ) {}

  private channelId: string;
  private otac: string;
  private applicationId: number;
  private goToChannelSelection: boolean;

  public ngOnInit(): void {
    this.channelId = this.route.snapshot.queryParams['channelId'];
    this.otac = this.route.snapshot.queryParams['otac'];
    this.applicationId = this.route.snapshot.queryParams['applicationId'];
    this.goToChannelSelection = this.route.snapshot.queryParams['goToChannelSelection'];
    const page = this.route.snapshot.queryParams['page'];
    const returnUrl = this.getReturnUrl(page);

    this.login(returnUrl);
  }

  public login(returnUrl: string): void {
    const currentUrl = this.router.url.substring(1);
    this.authService.returnUrl = returnUrl;

    if (this.goToChannelSelection) {
      this.signIn();
      return;
    }

    this.userAccessApiService.getUserIdByAccessCode(+this.channelId, this.otac).subscribe((userId) => {
      if (userId <= 0) {
        this.authService.signout();
        return;
      }

      this.userAccessService.setLogInUser(+this.channelId, userId, currentUrl);
      this.signIn();
    });
  }

  private signIn(): void {
    void this.authService.login({
      extraQueryParams: {
        oneTimeAccessCode: this.otac,
        channelId: this.channelId,
        goToChannelSelection: this.goToChannelSelection,
      },
    });
  }

  private getReturnUrl(page: string): string {
    if (!page) {
      return '';
    }

    switch (page.toUpperCase()) {
      case 'APPLICATION': {
        return `solutions/applications/${this.applicationId}`;
      }
      case 'INFOREQUEST': {
        return `solutions/inforequest/${this.applicationId}`;
      }
      case 'USERPROFILE': {
        return `organisation/people`;
      }
      default: {
        return '';
      }
    }
  }
}
