import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AccountProfile } from './account-profile.model';
import { AccountProfileStore } from './account-profile.store';

@Injectable({ providedIn: 'root' })
export class AccountProfileQuery extends Query<AccountProfile> {
  constructor(protected store: AccountProfileStore) {
    super(store);
  }
}
