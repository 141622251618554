import { permissionId } from '../shared/access/permission-ids';

export class NavigationMenu {
  public title: string;
  public route: string;
  public isExternalLink?: boolean;
  public icon: string;
  public access?: number[];
  public submenus?: NavigationMenu[];
}

export class MenuItem {
  public title: string;
  public icon: string;
  public page: string;
  public submenuItems: MenuItem[];
  public isSelected: boolean;
  public isExternalLink: boolean;
  public isOpen: boolean;
}

// Can be moved to another file
export class NavigationSetup {
  public static Navigations: NavigationMenu[] = [
    {
      title: 'Home',
      icon: 'fal fa-home',
      route: '',
      access: [],
      submenus: [
        {
          title: 'Dashboard',
          icon: 'fal fa-tachometer-alt-fastest',
          route: '/home/dashboard',
          access: [permissionId.DashboardMenu],
        },
        {
          title: 'News and announcements',
          icon: 'fal fa-newspaper',
          route: '/home/news',
          access: [permissionId.NewsAndAnnouncementsMenu],
        },
      ],
    },
    {
      title: 'Organisation',
      icon: 'fal fa-users-class',
      route: '',
      access: [],
      submenus: [
        {
          title: 'Channel',
          icon: 'fal fa-chart-network',
          route: '/organisation/channel',
          access: [permissionId.ChannelMenu],
        },
        {
          title: 'Entities',
          icon: 'fal fa-university',
          route: '/organisation/entity',
          access: [permissionId.EntitiesMenu],
        },
        {
          title: 'Broker',
          icon: 'fal fa-user-shield',
          route: '/organisation/broker',
          access: [permissionId.BrokerMenu],
        },
        {
          title: 'People',
          icon: 'fal fa-portrait',
          route: '/organisation/people',
          access: [permissionId.PeopleMenu],
          submenus: [
            {
              title: 'Broker',
              icon: '',
              route: '/organisation/broker',
              access: [-1], // Always set to False
            },
          ],
        },
        {
          title: 'Directory',
          icon: 'fal fa-address-card',
          route: '/organisation/directory',
          access: [permissionId.DirectoryMenu],
        },
      ],
    },
    {
      title: 'Solutions',
      icon: 'fal fa-box-full',
      route: '',
      access: [],
      submenus: [
        {
          title: 'Application processing',
          icon: 'fal fa-file-invoice',
          route: '/solutions/applications',
          access: [],
          submenus: [
            {
              title: 'Loan applications',
              icon: '',
              route: '/solutions/applications',
              access: [permissionId.LoanApplicationsMenu],
            },
            {
              title: 'Information request',
              icon: '',
              route: '/solutions/inforequest',
              access: [permissionId.InfoRequestMenu],
            },
            {
              title: 'My information requests',
              icon: '',
              route: '/solutions/my-info-request',
              access: [permissionId.MyInfoRequestMenu],
            },
            {
              title: 'Supporting docs',
              icon: '',
              route: '/solutions/suppdocs',
              access: [permissionId.SupportingDocsMenu],
            },
            {
              title: 'Document verification',
              icon: '',
              route: '/solutions/decision',
              access: [permissionId.DocumentVerificationMenu],
            },
          ],
        },
        {
          title: 'Loan data',
          icon: 'fal fa-house-day',
          route: '',
          access: [],
          submenus: [
            {
              title: 'Product',
              icon: 'fal fa-box',
              route: '/subscriptions/product',
              access: [permissionId.ProductMenu],
            },
            {
              title: 'Serviceability',
              icon: 'fal fa-box',
              route: '/solutions/serviceability',
              access: [permissionId.ServiceabilityMenu],
            },
            {
              title: 'Calculators',
              icon: 'fal fa-calculator-alt',
              route: '/solutions/calculator',
              access: [permissionId.CalculatorSetupMenu],
            },
            {
              title: 'Policy & decisioning',
              icon: 'fal fa-box',
              route: '/solutions/policy-decision',
              access: [permissionId.PolicyAndDecisioningMenu],
            },
            {
              title: 'Discount packages',
              icon: 'fal fa-tag',
              route: '/setup/package',
              access: [permissionId.DiscountPackagesMenu],
            },
            {
              title: 'Special offers',
              icon: '',
              route: '/setup/special-offers',
              access: [permissionId.SpecialOfferSetupMenu],
            },
            {
              title: 'Standard fees',
              icon: '',
              route: '/setup/standard-fees',
              access: [permissionId.SpecialOfferSetupMenu],
            },
          ],
        },
        {
          title: 'Prequalification',
          icon: 'fal fa-money-check-edit',
          route: 'https://www.simpology.com.au',
          access: [permissionId.PrequalificationMenu],
          isExternalLink: true,
        },
        {
          title: 'LMI/LDP calculator',
          icon: 'fal fa-calculator',
          route: '',
          access: [permissionId.LMICalculatorMenu],
          isExternalLink: true,
        },
        {
          title: 'Servicing',
          icon: 'fal fa-calculator',
          route: '',
          access: [permissionId.ServiceabilityJourneyMenu],
          isExternalLink: true,
        },
        {
          title: 'Omni',
          icon: 'fal fa-tasks',
          route: '',
          access: [permissionId.OmniMenu],
          isExternalLink: true,
        },
        {
          title: 'Loanapp',
          icon: 'fal fa-file-alt',
          route: '',
          access: [permissionId.LoanappMenu],
          isExternalLink: true,
        },
      ],
    },
    {
      title: 'Account',
      icon: 'fal fa-id-card',
      route: '',
      access: [],
      submenus: [
        {
          title: 'Profile',
          icon: 'fal fa-users-cog',
          route: '/account/profile',
          access: [permissionId.AccountProfileMenu],
        },
        {
          title: 'Security',
          icon: 'fal fa-shield-check',
          route: '',
          access: [],
          submenus: [
            {
              title: 'Permissions',
              icon: 'fal fa-user-lock',
              route: '/security/permissions',
              access: [permissionId.PermissionsMenu],
            },
            {
              title: 'Audit Log',
              icon: 'fal fa-user-lock',
              route: '/security/audit-log',
              access: [permissionId.AuditLogMenu],
            },
          ],
        },

        {
          title: 'Subscriptions',
          icon: 'fal fa-handshake-alt',
          route: '',
          access: [],
          submenus: [
            {
              title: 'Subscription',
              icon: 'fal fa-shopping-cart',
              route: '/subscriptions/channelsubs',
              access: [permissionId.SubscriptionMenu],
            },
            {
              title: 'Data subscription',
              icon: 'fal fa-database',
              route: '/subscriptions/datasubs',
              access: [permissionId.DataSubscriptionMenu],
            },
          ],
        },
        {
          title: 'Setup',
          icon: 'fal fa-cog',
          route: '',
          access: [],
          submenus: [
            {
              title: 'Comparison calculator',
              icon: 'fal fa-calculator',
              route: '/setup/comparison',
              access: [permissionId.ComparisonCalculatorMenu],
            },
            {
              title: 'Serviceability metrics',
              icon: '',
              route: '/setup/serviceability-metric',
              access: [permissionId.ServiceabilityMetricsMenu],
            },
            {
              title: 'Product fee setup',
              icon: '',
              route: '/setup/fee-type',
              access: [permissionId.ProductFeeSetupMenu],
            },
            {
              title: 'Serviceability setup',
              icon: '',
              route: '/setup/serviceability',
              access: [permissionId.ServiceabilitySetupMenu],
            },
            {
              title: 'Journey setup',
              icon: 'fal fa-box',
              route: '/setup/journey-setup',
              access: [permissionId.JourneySetupMenu],
            },
            {
              title: 'Application status setup',
              icon: '',
              route: '/setup/application-sub-status',
              access: [permissionId.ApplicationStatusSetupMenu],
            },
            {
              title: 'Action process template setup',
              icon: '',
              route: '/setup/action-process-setup',
              access: [permissionId.ActionProcessTemplateSetupMenu],
            },
            {
              title: 'Alert setup',
              icon: '',
              route: '/setup/alert-setup',
              access: [permissionId.AlertSetupMenu],
            },
            {
              title: 'LMI/LDP setup',
              icon: 'fal fa-calculator',
              route: '/setup/calculator-setup',
              access: [permissionId.CalculatorSetupMenu],
            },
            {
              title: 'BCM management setup',
              icon: 'fal fa-envelope',
              route: '/setup/bcm-management-setup',
              access: [permissionId.BCMManagementSetupMenu],
            },
            // {
            //   title: 'Auto track',
            //   icon: 'fal fa-user-lock',
            //   route: '/security/permissions',
            //   access: [],
            // },
          ],
        },
        {
          title: 'Application templates',
          icon: 'fal fa-books',
          route: '',
          access: [permissionId.ApplicationTemplateMenu],
          submenus: [
            {
              title: 'Document checklists',
              icon: 'fal fa-user-lock',
              route: '/setup/document-checklists',
              access: [permissionId.ApplicationTemplateMenu],
            },
            {
              title: 'Information request templates',
              icon: 'fal fa-user-lock',
              route: '/setup/info-request-templates',
              access: [permissionId.ApplicationTemplateMenu],
            },
            {
              title: 'Type templates',
              icon: 'fal fa-user-lock',
              route: '/setup/type-templates',
              access: [permissionId.ApplicationTemplateMenu],
            },
          ],
        },
        {
          title: 'Document Manager',
          icon: 'fal fa-book',
          route: '/setup/document-manager',
          access: [permissionId.DocumentManagerMenu],
        },
      ],
    },
    {
      title: 'Help',
      icon: 'fal fa-hands-helping',
      route: '',
      access: [],
      submenus: [
        {
          title: 'Contact us',
          icon: 'fal fa-map-marker-alt',
          route: 'https://simpology.com.au/home#contactUs',
          access: [permissionId.ContactUsMenu],
          isExternalLink: true,
        },
        {
          title: 'Support',
          icon: 'fal fa-info-square',
          route: 'https://simpology.com.au/home#contactUs',
          access: [permissionId.SupportMenu],
          isExternalLink: true,
        },
        {
          title: 'Knowledge Base',
          icon: 'fal fa-books',
          route: '',
          access: [permissionId.KnowledgeBaseMenu],
          isExternalLink: true,
        },
      ],
    },
  ];
}
