import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from 'src/env-config';

import { BaseApiService } from '@simpology/authentication';

import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UserAccessApiService extends BaseApiService<number> {
  constructor(http: HttpClient, toastr: ToastrService, router: Router) {
    super(http, toastr, router, ENV_CONFIG);
    this.setRoute('UserAccess');
  }

  public getUserIdByAccessCode(channelId: number, accessCode: string): Observable<number> {
    return this.get(`GetUserIdByOneTimeAccessCode?channelId=${channelId}&accessCode=${accessCode}`);
  }
}
