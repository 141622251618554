<ul class="nav navbar-nav float-right">
  <li class="dropdown-notification nav-item" ngbDropdown #notificationDropdown="ngbDropdown">
    <a class="nav-link nav-link-label" ngbDropdownToggle (click)="onClickNotificationBell()" data-testid="nav-notification">
      <i class="fal fa-bell"></i>
      <span class="badge badge-pill badge-danger badge-up badge-glow" *ngIf="notificationCount > 0">{{
        notificationCount
      }}</span>
    </a>
    <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
      <li class="dropdown-menu-header">
        <h6 class="dropdown-header m-0">
          <span class="grey darken-2">Notifications</span>
        </h6>
        <span class="notification-tag badge badge-default badge-danger float-right m-0" *ngIf="notificationCount > 0"
          >{{ notificationCount }} New</span
        >
      </li>
      <div *ngIf="notificationCount > 0">
        <li
          class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y"
          fxFlex="auto"
          *ngFor="let notification of infoRequestNotifications"
        >
          <a (click)="goToInfoRequest(notification)">
            <div class="media">
              <div class="media-left align-self-center">
                <i
                  class="fas fa-circle"
                  [ngClass]="{
                    'other-request-icon': !notification.isMyInfoRequest,
                    'my-request-icon': notification.isMyInfoRequest
                  }"
                ></i>
              </div>
              <div class="media-body">
                <h6 class="media-heading">
                  {{ notification.isMyInfoRequest ? 'My information request' : 'Information request response' }}
                </h6>
                <p class="notification-text font-small-3 text-muted">
                  Information request for <strong>{{ notification.applicationTitle }}</strong> is waiting for your
                  attention.
                </p>
              </div>
            </div>
          </a>
        </li>
        <li class="dropdown-menu-footer">
          <div class="dropdown-item dropdown-menu-footer-item text-muted text-center">
            No other notifications for now
          </div>
        </li>
      </div>
      <div *ngIf="notificationCount === 0">
        <li>
          <div class="dropdown-item text-muted text-center">You have no messages...</div>
        </li>
      </div>
    </ul>
  </li>
  <li class="dropdown-user nav-item" ngbDropdown *ngIf="currentUser">
    <a class="nav-link dropdown-user-link" ngbDropdownToggle data-testid="nav-profile">
      <span *ngIf="currentUser.displayName" class="mr-3 user-name text-bold-700">{{ currentUser.displayName }}</span>
      <span class="avatar avatar-online">
        <img *ngIf="currentUser.photoURL" [src]="currentUser.photoURL" alt="avatar" />
        <img *ngIf="!currentUser.photoURL" src="../../../../assets/images/portrait/small/default.png" alt="avatar" />
        <i></i>
      </span>
    </a>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
      <a class="dropdown-item" (click)="editProfile()" *ngIf="hasAccessToEditProfileUserContextMenu">
        <i class="feather ft-user"></i> Edit Profile
      </a>
      <a class="dropdown-item" [href]="passwordLink" *ngIf="hasAccessToChangePasswordUserContextMenu">
        <i class="feather ft-message-square"></i> Change password
      </a>
      <div
        class="dropdown-divider"
        *ngIf="hasAccessToEditProfileUserContextMenu || hasAccessToChangePasswordUserContextMenu"
      ></div>

      <a class="dropdown-item" (click)="logout()"><i class="feather ft-power"></i> Logout</a>
    </div>
  </li>
</ul>
