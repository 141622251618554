<div
  class="
    menu-header
    header-navbar
    navbar-expand-sm navbar navbar-horizontal navbar-fixed navbar-dark navbar-without-dd-arrow navbar-shadow
  "
  role="navigation"
  data-menu="menu-wrapper"
  (scroll)="onWindowScroll($event)"
  id="menu-header"
>
  <div class="navbar-container main-menu-content" data-menu="menu-container">
    <ul class="nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
      <li
        *ngFor="let child of menuItems"
        class="nav-item"
        [ngClass]="{
          'dropdown nav-item ': child.title && child.submenuItems,
          'nav-item': true,
          open: child.isOpen,
          active: child.isSelected
        }"
        [attr.data-menu]="child.submenuItems ? 'dropdown' : ''"
        (mouseenter)="mouseEnter($event)"
        (mouseleave)="mouseLeave($event)"
      >
        <!-- Top Menu -->
        <a
          class="nav-link"
          [ngClass]="{
            'dropdown-item dropdown-toggle active': child.submenuItems
          }"
          [attr.data-toggle]="child.submenuItems ? 'dropdown' : ''"
          (click)="toggleMenu($event, child, false)"
          routerLink="{{ child.page !== '' ? child.page : url }}"
          [attr.data-testid]="generateNavigationTestId(child.title)"
        >
          <i class="mr-2" [ngClass]="child.icon"></i><span data-i18n="nav.dash.main">{{ child.title }}</span>
        </a>

        <ul class="dropdown-menu" *ngIf="child.submenuItems">
          <li
            *ngFor="let subchild of child.submenuItems"
            class=""
            [ngClass]="{
              'dropdown dropdown-submenu': subchild.submenuItems,
              'dropdown-divider': subchild.title === 'horizontal-divider',
              active: subchild.isSelected
            }"
            (mouseenter)="mouseEnter($event)"
            (mouseleave)="mouseLeave($event)"
            [attr.data-menu]="subchild.submenuItems ? 'dropdown-submenu' : ''"
          >
            <a
              class="dropdown-item"
              (click)="toggleMenu($event, subchild, false)"
              routerLink="{{ subchild.page !== '' ? subchild.page : url }}"
              data-toggle="dropdown"
              *ngIf="!subchild.isExternalLink"
              [attr.data-testid]="generateNavigationTestId(subchild.title)"
            >
              <i class="mr-2" [ngClass]="subchild.icon"></i>
              <span data-i18n="nav.dash.main">{{ subchild.title }}</span>
            </a>
            <a
              class="dropdown-item"
              (click)="toggleMenu($event, subchild, false)"
              [href]="subchild.page !== '' ? subchild.page : url"
              target="_blank"
              data-toggle="dropdown"
              *ngIf="subchild.isExternalLink"
            >
              <i class="la" [ngClass]="subchild.icon"></i>
              <span data-i18n="nav.dash.main">{{ subchild.title }}</span>
            </a>
            <ul class="dropdown-menu" *ngIf="subchild.submenuItems">
              <li
                *ngFor="let subchild of subchild.submenuItems"
                [ngClass]="{
                  'dropdown dropdown-submenu': subchild.submenuItems,
                  'dropdown-divider': subchild.title === 'horizontal-divider',
                  active: subchild.isSelected
                }"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
                [attr.data-menu]="subchild.submenuItems ? 'dropdown-submenu' : ''"
              >
                <a
                  class="dropdown-item"
                  *ngIf="!subchild.event"
                  (click)="toggleMenu($event, subchild, false)"
                  routerLink="{{ subchild.page !== '' ? subchild.page : url }}"
                  data-toggle="dropdown"
                  [attr.data-testid]="generateNavigationTestId(subchild.title)"
                  ><span data-i18n="nav.dash.main">{{ subchild.title }}</span></a
                >
                <ul class="dropdown-menu" *ngIf="subchild.submenuItems">
                  <li
                    *ngFor="let subchild of subchild.submenuItems"
                    [ngClass]="{ active: subchild.isSelected }"
                    data-menu=""
                  >
                    <a
                      class="dropdown-item"
                      *ngIf="!subchild.event"
                      (click)="toggleMenu($event, subchild, false)"
                      routerLink="{{ subchild.page !== '' ? subchild.page : url }}"
                      data-toggle="dropdown"
                      [attr.data-testid]="generateNavigationTestId(subchild.title)"
                      ><span data-i18n="nav.dash.main">{{ subchild.title }}</span></a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
  <div class="navbar-container main-menu-content ml-auto">
    <div class="nav navbar-nav">
      <div class="nav-item">
        <div class="nav-link">
          <span>{{ channelName }}</span>
        </div>
      </div>
      <div class="nav-item ml-4" *ngIf="hasMultiEntities">
        <div class="nav-link">
          <span>{{ entityName }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar-container main-menu-content">
    <div class="nav navbar-nav">
      <div class="nav-item" *ngIf="hasMultiChannels || hasMultiEntities">
        <a class="nav-link mx-3" (click)="logOutToChannelSelection()" data-testid="nav-switch-channel"
          ><i
            class="fal fa-random"
            [ngbPopover]="hasMultiChannels ? 'Switch channel' : 'Switch entity'"
            triggers="hover"
            placement="bottom"
          >
          </i
        ></a>
      </div>
      <div class="nav-item">
        <a class="nav-link mx-3" (click)="logOut()" data-testid="nav-logout"
          ><i class="fal fa-sign-out-alt" ngbPopover="Logout" triggers="hover" placement="bottom"></i
        ></a>
      </div>
    </div>
  </div>
</div>
