import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChannelApiService } from '../organisation/api/channel-api.service';
enum State {
  Initial = 1,
  Finalising = 2,
  Success = 3,
  Failure = 4,
  CheckingCode = 5,
}

@Component({
  selector: 'app-finalise',
  templateUrl: './finalise.component.html',
  styleUrls: ['./finalise.component.scss'],
})
export class FinaliseComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private channelApiService: ChannelApiService) {}

  public state: State = State.Initial;
  private channelId: string;
  private key: string;
  public code: string;
  public badCode = false;

  public ngOnInit(): void {
    this.channelId = this.route.snapshot.queryParams['channelId'];
    this.key = this.route.snapshot.queryParams['key'];
  }

  public login(): void {
    this.router.navigateByUrl('/access/login');
  }

  public checkCode(): void {
    this.state = State.CheckingCode;
    this.badCode = false;
    this.channelApiService.checkRegistrationCode(this.channelId, this.code, this.key).subscribe(
      (res: boolean) => {
        if (res) {
          this.finaliseRegistration();
        } else {
          this.badCode = true;
          this.state = State.Initial;
        }
      },
      (error) => {
        this.badCode = true;
        this.state = State.Initial;
      }
    );
  }

  private finaliseRegistration(): void {
    this.state = State.Finalising;
    this.channelApiService
      .finalise({
        channelId: this.channelId,
        registrationKey: this.key,
      })
      .subscribe(
        () => {
          this.state = State.Success;
        },
        (error) => {
          this.state = State.Failure;
        }
      );
  }
}
